import { useQuery } from '@apollo/client';
import { Box, DialogContent, Drawer, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import ProjectProvider from '../../../contexts/ProjectContext';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { GET_ALL_PROJECT_ENUMS } from '../../../graphql/projects/projectEnums';
import { SEARCH_PROJECTS } from '../../../graphql/projects/projects';
import { Project } from '../../../types/project/types';
import NumberUtils from '../../../utils/numberUtils';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import ProjectDetails from '../../common/Project/ProjectDetails';
import Status from '../../common/Status';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { ExportableGroupableTableStructure } from '../../common/Table/types';
import RoundedFlexBox from '../RoundedFlexBox';
import useShowDemoProjects from './useShowDemoProjects';
import TraderProjectDetailsHeader from './TraderProjectDetailsHeader';
import { getSDVistaDisplay } from '../../../utils/projectUtils';

const useStyles = makeStyles((theme) => ({
  groupIcon: {
    height: 16,
    marginTop: 4,
  },
  drawer: {
    maxWidth: 1338,
    [theme.breakpoints.up('lg')]: {
      width: 1338,
    },
  },
  drawerContent: {
    width: '100%',
    padding: 0,
    overflow: 'hidden',
  },
}));

const ProjectList: React.FC = () => {
  const classes = useStyles();
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const { userHasAccess } = useContext(AuthorizationContext);
  const showDemoProjects = useShowDemoProjects();

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const {
    loading: enumsLoading,
    error: enumsError,
    data: {
      projectCcbSdgAuditors = [],
      projectAuditors = [],
      projectSources = [],
    } = {},
  } = useQuery(GET_ALL_PROJECT_ENUMS);

  const {
    loading: projectsLoading,
    error: projectsError,
    data: { searchProjects: { items: projects = [] } = {} } = {},
    refetch,
  } = useQuery<{
    searchProjects: { items: Project[]; continuationToken: string };
  }>(SEARCH_PROJECTS, {
    variables: {
      pageSize: 1000,
      filter: {
        draft: ['false'],
        isDemoProject: [showDemoProjects ? 'true' : 'false'],
      },
    },
    fetchPolicy: 'network-only',
  });

  const tableStructure: ExportableGroupableTableStructure<Project>[] = [
    {
      key: 'project',
      groupIcon: <SVG.Sidenav.Leaf className={classes.groupIcon} />,
      groupName: 'Project',
      sticky: true,
      columns: [
        {
          key: 'projectId',
          display: 'ID',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by ID',
          width: 100,
          stickyLeft: 0,
          export: { preview: true },
        },
        {
          key: 'title',
          display: 'Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by Project Name',
          render: (data) => (
            <Typography
              title={data.title}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: 700,
              }}>
              {data.title}
            </Typography>
          ),
          export: { format: ({ title }) => title || '', preview: true },
          width: 350,
          stickyLeft: 100,
        },
        {
          key: 'category',
          display: 'Category',
          render: (data) => <CategoryIconSmall categoryId={data.category} />,
          export: {
            format: ({ category }) =>
              projectCategories.find((x) => x.id === category)?.label || '',
            preview: true,
          },
          resolveFilterLabel: (value) =>
            projectCategories.find((x) => x.id === value)?.label || '',
          stickyLeft: 100 + 350,
          width: 125,
          type: AlphaColumnFilter,
        },
      ],
    },
    {
      key: 'details',
      groupIcon: <SVG.Sidenav.Leaf className={classes.groupIcon} />,
      groupName: 'Details',
      columns: [
        {
          key: 'filterableIsFeatured',
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) => (value === 'true' ? 'Yes' : 'No'),
          display: 'Featured',
          render: ({ isFeatured }) => (
            <Box paddingLeft="20px">
              {isFeatured ? <SVG.StarFilled /> : <SVG.StarOutline />}
            </Box>
          ),
          export: {
            format: ({ isFeatured }) => (isFeatured ?? false).toString(),
          },
        },
        {
          key: 'individualScope',
          display: 'Available To',
          render: ({ individualScope }) => (
            <Typography variant="body2">
              {individualScope ? 'Individuals' : 'Clients'}
            </Typography>
          ),
          export: {
            format: ({ individualScope }) =>
              individualScope ? 'Individuals' : 'Clients',
          },
          resolveFilterLabel: (value) => (value ? 'Individuals' : 'Clients'),
          type: AlphaColumnFilter,
        },
        { key: 'vintage', display: 'Vintage' },
        { key: 'indicator', display: 'Qualification', type: AlphaColumnFilter },
        {
          key: 'displayCost',
          display: '$ / Unit',
          export: { format: ({ costPerUnit }) => costPerUnit ?? '' },
        },
        { key: 'country', display: 'Project Country', type: AlphaColumnFilter },
        {
          key: 'auditor',
          display: 'Verification Auditor',
          format: (auditor) =>
            projectAuditors.find((x) => x.id === auditor)?.name ||
            (auditor as string),
          resolveFilterLabel: (value) =>
            projectAuditors.find((x) => x.id === value)?.name || '',
          type: AlphaColumnFilter,
        },
        {
          key: 'source',
          display: 'Registry',
          format: (source) =>
            projectSources.find((x) => x.id === source)?.name ||
            (source as string),
          resolveFilterLabel: (value) =>
            projectSources.find((x) => x.id === value)?.name || '',
          type: AlphaColumnFilter,
        },
        { key: 'projectHQ', display: 'HQ Country', type: AlphaColumnFilter },
        {
          key: 'isActive',
          display: 'Status',
          type: AlphaColumnFilter,
          render: ({ isActive }) => <Status isActive={isActive} />,
          export: {
            format: ({ isActive }) => (isActive ? 'Active' : 'Inactive'),
          },
          resolveFilterLabel: (value) => (value ? 'Active' : 'Inactive'),
        },
      ],
    },
    {
      key: 'utilization',
      groupIcon: <SVG.Sidenav.Leaf className={classes.groupIcon} />,
      groupName: 'Utilization',
      columns: [
        {
          key: 'availableQuantity',
          display: 'Purchased Qty',
          format: (data) => NumberUtils.format(data as number, 'integer'),
          export: {
            format: ({ availableQuantity }) => availableQuantity ?? '',
            heading: 'Purchased QTY',
          },
        },
        {
          key: 'displayRemainingQuantity',
          display: 'Remaining',
          export: {
            format: ({ remainingQuantity }) => remainingQuantity ?? '',
          },
        },
        { key: 'startDate', display: 'Project Start Date' },
        { key: 'endDate', display: 'Project End Date' },
      ],
    },
    {
      key: 'audit',
      groupIcon: <SVG.Sidenav.Leaf className={classes.groupIcon} />,
      groupName: 'Audit',
      columns: [
        { key: 'verAuditReportDate', display: 'VCC Report Date' },
        {
          key: 'ccbSdgAuditor',
          display: 'CCB/SDG Auditor',
          format: (ccbSdgAuditor) =>
            projectCcbSdgAuditors.find((x) => x.id === ccbSdgAuditor)?.name ||
            (ccbSdgAuditor as string),
          resolveFilterLabel: (value) =>
            projectCcbSdgAuditors.find((x) => x.id === value)?.name || '',
          type: AlphaColumnFilter,
        },
        {
          key: 'ccbSdgAuditorCountry',
          display: 'CCB/SDG Auditor HQ',
          type: AlphaColumnFilter,
        },
        { key: 'ccbSdgReportDate', display: 'CCB/SDG Report Date' },
        {
          key: 'sdVista',
          display: 'SDVISTA',
          type: AlphaColumnFilter,
          render: (x) => getSDVistaDisplay(x.sdVista),
          resolveFilterLabel: (value) =>
            getSDVistaDisplay(value as string | boolean | undefined),
        },
      ],
    },
  ];

  const filteredProjects = projects
    .sort((a, b) => {
      const sortKeys: (keyof Project)[] = [
        'startDate',
        'category',
        'projectId',
      ];
      return sortKeys.reduce(
        (comp, key) =>
          comp ||
          ((a[key] as string) || '').localeCompare((b[key] as string) || ''),
        0,
      );
    })
    .map((p) => ({
      ...p,
      filterableIsFeatured: p.isFeatured ? 'true' : 'false',
      displayCost: NumberUtils.formatCurrencyAndUom(
        p.costPerUnit ?? 0,
        undefined,
        p.uom,
      ),
      displayRemainingQuantity: NumberUtils.formatQuantityAndUom(
        p.remainingQuantity ?? 0,
        undefined,
        p.uom,
      ),
    }));

  return (
    <DataStateHandler
      loading={categoriesLoading || projectsLoading || enumsLoading}
      error={categoriesError || projectsError || enumsError}>
      <>
        <Grid
          container
          direction="column"
          style={{ height: 'calc(100vh - 190px)', position: 'relative' }}>
          <RoundedFlexBox>
            <GroupableSelectTable<Project>
              tableData={filteredProjects}
              tableStructure={tableStructure}
              queryVariables={{ 'sort-list': projectCategories }}
              onRowClick={(r) => setSelectedProjectId(r.id)}
              exportable={userHasAccess('ZeroMe.Marketplace', 'EDIT')}
              exportOptions={{
                contentPlural: 'Projects',
                filename: 'projects',
              }}
            />
          </RoundedFlexBox>
        </Grid>
        <Drawer
          open={!!selectedProjectId}
          onClose={() => setSelectedProjectId(undefined)}
          anchor="right">
          <Box className={classes.drawer}>
            <TraderProjectDetailsHeader
              refetch={refetch}
              projectId={selectedProjectId as string}
              onClose={() => setSelectedProjectId(undefined)}
            />

            <DialogContent className={classes.drawerContent}>
              <ProjectProvider
                editable
                saveAfterSectionEdit
                projectId={selectedProjectId}>
                <ProjectDetails />
              </ProjectProvider>
            </DialogContent>
          </Box>
        </Drawer>
      </>
    </DataStateHandler>
  );
};

export default ProjectList;
