import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import moment, { Moment } from 'moment';
import AppDatePicker from './AppDatePicker';
import HeaderTabs from './HeaderTabs/HeaderTabs';
import HeaderTab from './HeaderTabs/HeaderTab';
import BudgetPanelYearlySpend from '../settings/BudgetPanelYearlySpend';
import { MutationBudgetSettings } from '../../types/company/types';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';
import { currentFiscalYear } from '../settings/BudgetPanel';

const parseMmdd = (fieldValue: string) =>
  new Date(
    new Date().getFullYear(),
    parseInt(fieldValue.substring(0, 2), 10) - 1,
    parseInt(fieldValue.substring(3, 5), 10),
  );

const useStyles = makeStyles((theme) => ({
  fiscalYearBox: {
    backgroundColor: theme.palette.backgrounds.light,
    borderRadius: 8,
    padding: '24px 16px',
    marginTop: 40,
  },
}));

type Props = {
  disabled?: boolean;
  defaultYear: number;
};

const BudgetSettingsForm: React.FC<Props> = ({ disabled, defaultYear }) => {
  const classes = useStyles();
  const { setTypedFieldValue, initialValues, values } =
    useTypedFormikContext<MutationBudgetSettings>();
  const fiscalYearDate = parseMmdd(values.fiscalYearStartDate);
  const fiscalYear = currentFiscalYear(values.fiscalYearStartDate);
  const fiscalYearEndDate = moment(fiscalYearDate)
    .subtract(1, 'day')
    .format('MMMM D');

  if (!initialValues.fiscalYears.find((x) => x.fiscalYear === defaultYear)) {
    initialValues.fiscalYears.push({
      fiscalYear: defaultYear,
      planPeriodType: '',
      periods: [],
    });
  }

  const [selectedYear, setSelectedYear] = useState(defaultYear);

  return (
    <>
      <Grid item>
        <Typography variant="body1">
          The budget information you choose to populate here will be used in
          aggregating data for your dashboards and reporting purposes. The first
          section defines your fiscal year parameters. The second section allows
          you to enter a projected spend amount for emissions reductions and
          renewable energy credits. Please note that this in no way is a
          commitment to spend these dollars, but by populating this information,
          you can track through the dashboards how much you have spent on
          emissions reductions and renewable energy credits and see a comparison
          to what you had anticipated spending.
        </Typography>
      </Grid>
      <Grid item container direction="column" className={classes.fiscalYearBox}>
        <Grid item>
          <Typography variant="h3">Fiscal Year Date</Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          style={{ marginTop: 16 }}
          spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Start Date</Typography>
            <Field
              name="fiscalYearStartDate"
              format="MMMM D"
              parseFn={parseMmdd}
              disabled={disabled}
              serializeFn={(m: Moment) => m.format('MM-DD')}
              component={AppDatePicker}
              placeholder="Start Date"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">End Date</Typography>
            <Typography variant="body1" style={{ paddingTop: 16 }}>
              {fiscalYearEndDate}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 40 }}>
        <HeaderTabs value={selectedYear}>
          {Array.from(Array(5).keys()).map((i) => (
            <HeaderTab
              key={i}
              value={fiscalYear + i}
              label={
                {
                  '0': 'This Year',
                  '1': 'Next Year',
                }[i.toString()] || fiscalYear + i
              }
              onClick={() => {
                const nextSelectedYear = fiscalYear + i;

                if (
                  !values.fiscalYears.find(
                    (x) => x.fiscalYear === nextSelectedYear,
                  )
                ) {
                  setTypedFieldValue('fiscalYears', [
                    ...values.fiscalYears,
                    {
                      fiscalYear: nextSelectedYear,
                      periods: [],
                      planPeriodType: '',
                    },
                  ]);
                }

                setSelectedYear(nextSelectedYear);
              }}
            />
          ))}
        </HeaderTabs>
      </Grid>
      <Grid item>
        <BudgetPanelYearlySpend
          selectedYear={selectedYear}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default BudgetSettingsForm;
