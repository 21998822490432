import { ButtonBase, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { Colors } from '../../theme/shared';
import { ZeroMeTM } from '../../utils/stringConstants';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: 'white',
    padding: 5,
  },
}));

const MemberFooter = () => {
  const classes = useStyles();
  const history = useHistory();

  const { logout, isAuthenticated, hasAdminAccess } =
    useContext(AuthorizationContext);
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      style={{
        backgroundColor: Colors.DarkGrey,
        padding: 40,
      }}>
      <Grid item>
        <Typography variant="body2" className={classes.whiteText}>
          &copy; {new Date().getFullYear()} {ZeroMeTM}, All Rights Reserved
        </Typography>
      </Grid>
      <Grid item>
        {hasAdminAccess && (
          <ButtonBase onClick={() => history.push('/')}>
            <Typography variant="body2" className={classes.whiteText} pl={3}>
              Admin
            </Typography>
          </ButtonBase>
        )}
        {isAuthenticated && (
          <ButtonBase
            onClick={() => {
              logout();
            }}>
            <Typography variant="body2" className={classes.whiteText} pl={3}>
              Logout
            </Typography>
          </ButtonBase>
        )}
      </Grid>
    </Grid>
  );
};

export default MemberFooter;
