import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoColor } from './logo-color.svg';
import { ReactComponent as LogoWhite } from './logo-white.svg';
import { ReactComponent as LogoIcon } from './logo-icon.svg';
import { ReactComponent as Foot } from './foot.svg';
import { ReactComponent as Leaf } from './leaf.svg';
import { ReactComponent as Team } from './team.svg';
import { ReactComponent as Tracker } from './tracker.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowDownWhite } from './arrow-down-white.svg';
import { ReactComponent as ArrowDownMedium } from './arrow-down-medium.svg';
import { ReactComponent as DownTrend } from './downtrend.svg';
import { ReactComponent as Engagement } from './engagement.svg';
import { ReactComponent as Organization } from './organization.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as SettingsOutline } from './settings-outline.svg';
import { ReactComponent as Cart } from './cart.svg';
import { ReactComponent as CartEmpty } from './cart-empty.svg';
import { ReactComponent as Target } from './target.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Bullseye } from './bullseye.svg';
import { ReactComponent as Landfill } from './landfill.svg';
import { ReactComponent as Budget } from './budget.svg';
import { ReactComponent as Company } from './company.svg';
import { ReactComponent as UnselectedCategory } from './category-unselected.svg';
import { ReactComponent as USGain } from './usgain.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as ZeroMe } from './zeroMe.svg';
import { ReactComponent as ZeroMeWhite } from './zeromewhite.svg';
import { ReactComponent as Expand } from './expand.svg';
import { ReactComponent as Collapse } from './collapse.svg';
import { ReactComponent as Food } from './food.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Car } from './car.svg';
import { ReactComponent as Money } from './money.svg';
import { ReactComponent as TableFilter } from './table-filter.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as CaretLeftSmall } from './caret-left-small.svg';
import { ReactComponent as CaretRightSmall } from './caret-right-small.svg';
import { ReactComponent as Portrait } from './portrait.svg';
import { ReactComponent as Diamond } from './diamond.svg';
import { ReactComponent as DragHandle } from './drag-handle.svg';
import { ReactComponent as ConfigureBudget } from './configure-budget.svg';
import { ReactComponent as ChartUp } from './chart-up.svg';
import { ReactComponent as ChartDown } from './chart-down.svg';
import { ReactComponent as ListChecked } from './list-checked.svg';
import { ReactComponent as ListUnchecked } from './list-unchecked.svg';
import { ReactComponent as ReturnArrow } from './return-arrow.svg';
import { ReactComponent as BoxCheck } from './box-check.svg';
import { ReactComponent as BoxEdit } from './box-edit.svg';
import { ReactComponent as BoxX } from './box-x.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as CsvIcon } from './csv.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Lightning } from './lightning.svg';
import { ReactComponent as Communications } from './communications.svg';
import { ReactComponent as ClientCommunications } from './client-communications.svg';
import { ReactComponent as StarFilled } from './star-filled.svg';
import { ReactComponent as StarOutline } from './star-outline.svg';
import { ReactComponent as MultiSelectDelete } from './multiselect-delete.svg';
import { ReactComponent as Blog } from './blog.svg';
import { ReactComponent as Hint } from './hint.svg';
import { ReactComponent as Audio } from './audio.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as LeafWithBackground } from './leaf-with-background.svg';
import { ReactComponent as InventoryWithBackground } from './inventory-with-background.svg';
import { ReactComponent as WarningTriangle } from './warning-triangle.svg';
import { ReactComponent as Eyeglasses } from './eyeglasses.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as UserSolid } from './user-solid.svg';
import { ReactComponent as Building } from './building.svg';
import { ReactComponent as BrowseMarketplace } from './browse-marketplace.svg';
import { ReactComponent as VerticalDots } from './vertical-dots.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as OffsetOpportunity } from './offset-opportunity.svg';
import { ReactComponent as OffsetDashboard } from './offset-dashboard.svg';
import { ReactComponent as ZeroMeCommunication } from './zerome-communication.svg';
import { ReactComponent as ProjectManagement } from './project-management.svg';
import { ReactComponent as FileImport } from './file-import.svg';
import { ReactComponent as FileExport } from './file-export.svg';
import { ReactComponent as Divider } from './divider.svg';
import { ReactComponent as Certificate } from './certificate.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as BetterMe } from './betterme.svg';
import { ReactComponent as EmptyState } from './empty-state.svg';
import { ReactComponent as ContestBronze } from './challenge-tier-bronze-slim.svg';
import { ReactComponent as ContestSilver } from './challenge-tier-silver-slim.svg';
import { ReactComponent as ContestGold } from './challenge-tier-gold-slim.svg';
import { ReactComponent as Export } from './export.svg';

export default {
  Export,
  Logo,
  LogoColor,
  LogoWhite,
  LogoIcon,
  USGain,
  ArrowUp,
  ArrowDown,
  ArrowDownWhite,
  ArrowDownMedium,
  ArrowRight,
  Bell,
  ChartUp,
  ChartDown,
  BoxCheck,
  BoxEdit,
  BoxX,
  ConfigureBudget,
  Calendar,
  CaretLeftSmall,
  CaretRightSmall,
  Check,
  Clock,
  Close,
  Copy,
  CsvIcon,
  Delete,
  Diamond,
  DragHandle,
  Back,
  DownTrend,
  Edit,
  ListChecked,
  ListUnchecked,
  MultiSelectDelete,
  Portrait,
  ReturnArrow,
  UnselectedCategory,
  Upload,
  ZeroMe,
  ZeroMeWhite,
  Expand,
  BrowseMarketplace,
  Collapse,
  SettingsOutline,
  TableFilter,
  Gear,
  Star,
  Lightning,
  StarFilled,
  StarOutline,
  LeafWithBackground,
  InventoryWithBackground,
  WarningTriangle,
  Eyeglasses,
  Building,
  VerticalDots,
  User,
  UserSolid,
  Company,
  Info,
  Eye,
  FileExport,
  FileImport,
  Divider,
  Certificate,
  BetterMe,
  EmptyState,
  ContestBronze,
  ContestSilver,
  ContestGold,
  Sidenav: {
    Leaf,
    Team,
    Tracker,
    Engagement,
    Organization,
    Settings,
    Communications,
    OffsetOpportunity,
    ClientCommunications,
    ZeroMeCommunication,
    OffsetDashboard,
    ProjectManagement,
  },
  Marketplace: {
    Cart,
    CartEmpty,
    Target,
    Search,
    Bullseye,
    Location,
  },
  Project: {
    Landfill,
    Metrics: {
      Team,
      Foot,
      Budget,
    },
  },
  Icons: {
    Food,
    House,
    Car,
    Money,
  },
  Communications: {
    Hint,
    Audio,
    Video,
    Blog,
  },
};
