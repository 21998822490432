import React, { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem, SelectProps, Typography } from '@mui/material';
import { ProfileQuestionInputBase } from '../../../types/question/types';
import {
  useAnswerQuestion,
  useQuestionnaire,
} from '../../../contexts/QuestionnaireContext';
import {
  PickerItem,
  createQuestionAnswerFromQuestionAndEmissionSources,
  isSkippableQuestion,
} from '../../../utils/questionnaire';
import QuestionnaireTextField from './QuestionnaireTextField';
import QuestionnaireButton from './QuestionnaireButton';
import QuestionnaireSelect from './QuestionnaireSelect';

type UnitInputPickerProps = {
  units: PickerItem[];
  value: string | undefined;
} & Pick<ProfileQuestionInputBase, 'forceDisabled'> &
  Pick<SelectProps, 'onChange'>;

export function UnitInputPicker({
  units,
  onChange,
  value,
  forceDisabled,
}: UnitInputPickerProps) {
  if (units.length === 1) {
    return <Typography variant="body1">{units[0].label}</Typography>;
  }

  return (
    <QuestionnaireSelect
      placeholder="Select an item"
      value={value || ''}
      onChange={onChange}
      disabled={forceDisabled}>
      {units.map((x) => (
        <MenuItem key={x.value} value={x.value}>
          {x.label}
        </MenuItem>
      ))}
    </QuestionnaireSelect>
  );
}

export function UnitInput({
  profileCard,
  forceDisabled,
  inputOnly,
}: ProfileQuestionInputBase): JSX.Element {
  const state = useQuestionnaire();
  const answerQuestion = useAnswerQuestion();

  const units =
    profileCard.question.userInputOptions?.parameters.map((map) => ({
      label: map.name,
      value: map.value,
    })) || [];

  const skippable = isSkippableQuestion(profileCard);

  const generateQuestionAnswer = useCallback(() => {
    const response = createQuestionAnswerFromQuestionAndEmissionSources(
      state.userEmissionSources,
      profileCard.profileId,
      profileCard.question,
    );

    return {
      ...response,
      readings: response.readings?.map((map) => ({
        ...map,
        calcInput: map.calcInput?.toString(),
      })),
    };
  }, [profileCard.profileId, profileCard.question, state.userEmissionSources]);

  const [internalValue, setInternalValue] = useState(generateQuestionAnswer());

  useEffect(() => {
    setInternalValue(generateQuestionAnswer());
  }, [generateQuestionAnswer]);

  const onTextChange = (value: string) => {
    if (profileCard.question?.userInputOptions) {
      let calcInputUnit;

      if (internalValue.readings?.length) {
        calcInputUnit = internalValue.readings[0].calcInputUnit;
      }

      if (units.length === 1) {
        setInternalValue({
          ...internalValue,
          readings: [
            {
              key: profileCard.question.userInputOptions.propertyTemplateKey,
              calcInput: value,
              calcInputUnit: units[0].value,
            },
          ],
        });
      } else {
        setInternalValue({
          ...internalValue,
          readings: [
            {
              key: profileCard.question.userInputOptions.propertyTemplateKey,
              calcInput: value,
              calcInputUnit,
            },
          ],
        });
      }
    }
  };

  const onPickerChange = (value: string) => {
    if (profileCard.question?.userInputOptions) {
      let calcInput: string | undefined;

      if (internalValue.readings?.length) {
        calcInput = internalValue.readings[0].calcInput;
      }

      setInternalValue({
        ...internalValue,
        readings: [
          {
            key: profileCard.question.userInputOptions.propertyTemplateKey,
            calcInput,
            calcInputUnit: value,
          },
        ],
      });
    }
  };

  const onComplete = () => {
    const completeQuestionAnswer = {
      ...internalValue,
      readings: internalValue.readings?.map((map) => ({
        ...map,
        calcInput: map.calcInput ? parseFloat(map.calcInput) : 0,
      })),
    };

    answerQuestion(profileCard, completeQuestionAnswer);
  };

  const internalOnSkip = () => {
    if (skippable) {
      setInternalValue({
        ...internalValue,
        readings: undefined,
      });

      answerQuestion(profileCard, {
        questionId: internalValue.questionId,
        readings: undefined,
        answerIds: undefined,
        estimateSchedule: undefined,
      });
    }
  };

  let textValue: string | number | undefined;
  let pickerValue: string | undefined;

  if (internalValue.readings?.length) {
    textValue = internalValue.readings[0].calcInput;
    pickerValue = internalValue.readings[0].calcInputUnit;
  }

  const readingValue = textValue?.toString() || '';
  const unitValue = units.find((find) => find.value === pickerValue)
    ? pickerValue
    : undefined;

  let disabled =
    Number.isNaN(parseFloat(readingValue)) || !unitValue || forceDisabled;

  // here we decide if 0 is a valid value
  // if the button is disabled we would never enable it so move on
  // if we have a property template we check if it's a value without "withZero"
  if (!disabled && profileCard.question?.userInputOptions?.propertyTemplate) {
    switch (profileCard.question.userInputOptions?.propertyTemplate) {
      case 'readingsFloat':
      case 'readingsInt':
      case 'readingsIntSkippable':
        disabled = parseFloat(readingValue) === 0;
        break;
      default:
        break;
    }
  }

  return (
    <Grid container flexDirection="column">
      <Grid
        item
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center">
        <Grid item style={{ width: 200 }}>
          <QuestionnaireTextField
            value={readingValue}
            onChange={(e) => onTextChange(e.target.value)}
            disabled={forceDisabled}
          />
        </Grid>
        <Grid item pl={1}>
          <UnitInputPicker
            value={unitValue}
            onChange={(e) => onPickerChange(e.target.value as string)}
            units={units}
            forceDisabled={forceDisabled}
          />
        </Grid>
      </Grid>
      {!inputOnly && (
        <Grid
          item
          container
          flexDirection="row"
          justifyContent="center"
          pt={3}
          spacing={2}>
          <Grid item>
            <QuestionnaireButton variant="contained" onClick={onComplete}>
              Next
            </QuestionnaireButton>
          </Grid>
          {skippable && (
            <Grid item>
              <QuestionnaireButton
                variant="contained"
                onClick={internalOnSkip}
                disabled={forceDisabled}>
                Skip
              </QuestionnaireButton>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
