import { Box, Grid, Switch, Typography } from '@mui/material';
import { Field } from 'formik';
import React from 'react';

import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import AppTextField from '../../common/AppTextField';
import FileUpload from '../../common/FileUpload/FileUpload';
import { CommunicationInput } from '../types';
import CategoryIdSelect from './CategoryIdSelect';
import SVG from '../../../assets/svg';
import HeadingInput from './HeadingInput';
import PublishFields from './PublishFields';
import ImageUpload from '../../common/ImageUpload';
import { Disableable } from '../../../types/common';
import AppRichTextField from '../../common/AppRichTextField';

const VideoForm: React.FC<Disableable> = ({ disabled }) => {
  const { setTypedFieldValue, values, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Featured Project</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={values.isFeatured || false}
              disabled={disabled}
              onChange={(e, checked) =>
                setTypedFieldValue('isFeatured', checked)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CategoryIdSelect disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <HeadingInput disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Author</Typography>
        <Field
          name="author"
          disabled={disabled}
          label=""
          component={AppTextField}
          placeholder="Author Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUpload
          disabled={disabled}
          value={values.headerImageUrl || ''}
          onChange={(file) => setTypedFieldValue('headerImage', file)}
        />
      </Grid>
      {!disabled && (
        <Grid item xs={12}>
          <Typography variant="body2">Video File</Typography>
          <FileUpload
            showFiles
            onFileChange={(files) => {
              if (files.length) {
                setTypedFieldValue('contentUpload', {
                  file: files[0].file,
                });
              }
            }}
            accept=".mp4"
            uploadDivStyle={{ padding: 64 }}
            uploadIcon={<SVG.Portrait />}
            uploadLabel={
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h4">Drop your video here</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ fontSize: 10 }}>
                    or click to browse for a file
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      {values.contentUploadUrl ? (
        <Grid item xs={12}>
          <Typography variant="body2">
            <a href={values.contentUploadUrl}>Current Video</a>
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="body2">Transcription</Typography>
        {disabled ? (
          <Box
            dangerouslySetInnerHTML={{
              __html: values.content,
            }}
          />
        ) : (
          <AppRichTextField
            labelText="Body"
            initialValue={initialValues.content}
            onChange={(v) => setTypedFieldValue('content', v)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <PublishFields disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default VideoForm;
