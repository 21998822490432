import { gql, TypedDocumentNode } from '@apollo/client';
import { Company } from '../../types/company/types';
import { HabitWithUsageData } from '../../types/habit/types';
import { LikedProject } from '../../types/dashboard/types.js';

export const ENGAGEMENT_METRICS = gql`
  query engagementDashboard(
    $companyId: String!
    $dateGrain: DateGrain!
    $startDate: String
    $endDate: String
    $includeChildren: Boolean
  ) {
    participationActivity(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $endDate
    ) {
      total
      onboarded
      onboardedDelta
      notOnboarded
      requestedAccountDelete
    }
    profileCompletion(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $endDate
    ) {
      total
      completedAllCategories
      completedAllCategoriesPercentOfTotal
      categories {
        id
        label
        value
        percent
        iconUri
      }
    }
    projectCategoryInterest(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $endDate
    ) {
      id
      label
      value
      color
      iconUri
      percent
    }
    monthlyUsers(
      companyId: $companyId
      parameters: {
        startDate: $startDate
        endDate: $endDate
        dateGrain: $dateGrain
        includeChildren: $includeChildren
      }
    ) {
      date
      active
      inactive
    }
  }
`;

export const HABIT_METRICS = gql`
  query habitMetrics(
    $companyId: String!
    $endDate: String
    $includeChildren: Boolean
  ) {
    habitMetrics(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $endDate
    ) {
      participation {
        total
        enrolled
        notEnrolled
      }
    }
  }
` as TypedDocumentNode<
  {
    habitMetrics: {
      participation: { total: number; enrolled: number; notEnrolled: number };
    };
  },
  { companyId: Company['id'] }
>;

export const HABIT_USAGE = gql`
  query habitsWithUsageData(
    $companyId: String!
    $includeChildren: Boolean
    $date: String
  ) {
    habitsWithUsageData(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $date
    ) {
      habit {
        id
        name
        pointAmount
        recurrenceSchedule
        category {
          id
          label
          primaryColor
          secondaryColor
          iconUrl
          fillIconUrl
        }
      }

      totalPointsEarned
      trackingPercentage
      lifestylePercentage
      totalParticipants
    }
  }
` as TypedDocumentNode<{
  habitsWithUsageData: HabitWithUsageData[];
}>;

export const LIKED_PROJECTS = gql`
  query companyLikedProjects(
    $companyId: String!
    $includeChildren: Boolean
    $date: String
  ) {
    likedProjects(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $date
    ) {
      likedProjectId
      count
      project {
        title
        category
        categoryData {
          id
          color
          highlightColor
        }
      }
    }
    projectAllotmentMetrics(companyId: $companyId) {
      projectId
    }
  }
` as TypedDocumentNode<{
  likedProjects: LikedProject[];
  projectAllotmentMetrics: { projectId: string }[];
}>;

export default {
  ENGAGEMENT_METRICS,
};
