import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, SetStateAction } from 'react';
import useMenuAnchor from '../../../hooks/useMenuAnchor';
import { CompanyListItem } from './EmailRecipientsInput';
import SVG from '../../../assets/svg';
import { Disableable } from '../../../types/common';

const commonTextProps = {
  color: '#FFFFFF',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
    background: theme.palette.backgrounds.dark,
    borderRadius: 8,
  },
  title: {
    ...commonTextProps,
    padding: 8,
  },
  close: {
    ...commonTextProps,
    fontSize: 14,
  },
  right: {
    marginLeft: 'auto',
  },
  button: {
    padding: 10,
    textOverflow: 'ellipsis',
  },
}));

type Props = Disableable & {
  parent: CompanyListItem;
  divisions: (CompanyListItem & { level: number })[];
  companyIds: string[];
  setCompanyIds: Dispatch<SetStateAction<string[]>>;
};

const EmailRecipientListItem: React.FC<Props> = ({
  parent,
  divisions,
  companyIds,
  setCompanyIds,
  disabled,
}) => {
  const { onMenuClick, isMenuOpen, anchorEl, setAnchorEl } = useMenuAnchor();

  const classes = useStyles();
  const onRemove = () => {
    // remove parent and all divisions from recipients
    const toRemove = [parent.id, ...divisions.map((d) => d.id)];
    setCompanyIds(companyIds.filter((c) => toRemove.indexOf(c) === -1));
  };

  const selectedDivisions = divisions.filter(
    (d) => companyIds.indexOf(d.id) > -1,
  );

  const divisionLabel =
    selectedDivisions.length === divisions.length
      ? 'All Divisions'
      : selectedDivisions.map((d) => d.name).join(', ');

  return (
    <Box className={classes.root}>
      <Grid container alignItems="center">
        {disabled ? null : (
          <IconButton className={classes.close} onClick={onRemove} size="large">
            X
          </IconButton>
        )}
        <Typography variant="body2" className={classes.title}>
          {parent.name}
        </Typography>
        {divisions.length ? (
          <Grid item className={classes.right}>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={onMenuClick}
              endIcon={disabled ? null : <SVG.ArrowDown />}>
              {divisionLabel}
            </Button>
            <Menu
              open={isMenuOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              keepMounted>
              {divisions.map(({ name, id, level }) => (
                <MenuItem
                  key={id}
                  style={{ marginLeft: 8 * level }}
                  onClick={() => {
                    if ((companyIds || []).indexOf(id) > -1) {
                      // deselect
                      setCompanyIds(companyIds.filter((c) => c !== id));
                    } else {
                      // select
                      setCompanyIds([...companyIds, id]);
                    }
                  }}>
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={(companyIds || []).indexOf(id) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default EmailRecipientListItem;
