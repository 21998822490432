import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import { QuestionnaireProvider } from '../../../contexts/QuestionnaireContext';
import CurrentQuestionnaireCard from '../../../components/common/Questionnaire/CurrentQuestionnaireCard';
import { GET_QUESTION_TREE_CATEGORIES } from '../../../graphql/questionnaire/questions';
import MemberProfileAvatar from '../../../components/member/profile/MemberProfileAvatar';
import useQueryStringParams from '../../../hooks/useQueryStringParams';

const AnswerQuestions: React.FC = () => {
  const { id, index } = useParams<{ id: string; index?: string }>();
  const queryParams = useQueryStringParams();
  const { data: { questionTreeCategories } = {} } = useQuery(
    GET_QUESTION_TREE_CATEGORIES,
  );

  const category = questionTreeCategories?.find((x) => x.id === id);

  let url = '/member/profile';

  switch (queryParams.get('returnTo')) {
    case 'dashboard':
      url = '/member/dashboard';
      break;

    default:
      break;
  }

  return category ? (
    <MemberLayout>
      <QuestionnaireProvider
        onboarding={false}
        questionTreeCategory={category}
        initialIndex={index ? parseInt(index, 10) : undefined}
        cleanup={{ navigationType: 'NAVIGATE', url }}>
        <Box pb={3}>
          <MemberProfileAvatar />
        </Box>
        <CurrentQuestionnaireCard />
      </QuestionnaireProvider>
    </MemberLayout>
  ) : (
    <div />
  );
};

export default AnswerQuestions;
