import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import { Grid, useMediaQuery, useTheme } from '@mui/material';

import { GET_DISCOVER_COMMUNICATIONS } from '../../../graphql/communications/memberCommunications';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationCard from './CommunicationCard';

const RelatedCommunications: React.FC = () => {
  const { company } = useContext(AuthorizationContext);

  // current requirements is to match these to what is on the browse
  // section on the right hand side so we execute the same query here.
  // Assumption is most users will come from browse so this will be cached
  const {
    data: { communications: { items = [] } = {} } = {},
    loading,
    error,
  } = useQuery(GET_DISCOVER_COMMUNICATIONS, {
    variables: {
      companyId: company?.id,
      skip: !company,
    },
  });

  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down('lg'));

  const history = useHistory();

  const articles = items.filter((i) => !i.isFeatured).slice(4, 8);

  const navigate = (id: string) => {
    history.push(`/member/discover/${id}`);
  };

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container direction="row" spacing={2}>
        {articles.map((fc) => (
          <Grid item lg={3} xs={12}>
            <CommunicationCard
              key={fc.id}
              communication={fc}
              imageHeight={lessThanMd ? 234 : 112}
              onClick={(id) => navigate(id)}
            />
          </Grid>
        ))}
      </Grid>
    </DataStateHandler>
  );
};

export default RelatedCommunications;
