import { ImageSize } from '../common';

export type UserActivity = {
  id: string;
  date: string;
  message: string;
  readAt?: string;
} & (
  | {
      activityType: UserActivityType.USER_OFFSET;
      data: {
        amount: number;
        price: number;
      };
    }
  | {
      activityType: UserActivityType.COMPANY_OFFSET;
      // this is a partial because there isn't a guarantee we will get this data back
      data: Partial<{
        allocationMonth: string;
        type: string;
        subType: string;
        purposeType: string;
        emissions: number;
        uom: UserActivityUOM;
        price: number;
        companyId: string;
        projectId: string;
        projectCategoryId: string;
        purchaseRequestId: string;
        allocationRequestId: string;
        projectCategoryIconUri: string;
        projectName: string;
        companyLogoUris: Record<ImageSize, string>;
        emissionTypeLabel: string;
        emissionTypeIconUri: string;
      }>;
    }
  | {
      activityType: UserActivityType.PUSH_NOTIFICATION;
      data: {
        content: string;
        createdBy: string;
        createdDate: string;
        id: string;
        includeChildren: boolean;
        partitionKey: UserActivityPartitionKey;
        sentDate: string;
        title: string;
        type: 'notification';
        updatedBy: string;
        updatedDate: string;
      } & (
        | {
            // this will be used to distinguish link types in the future so we know their data
            // right now we just need the linkId, later we may need more information
            linkId: string;
            linkType: UserActivityLinkType.Communication;
          }
        | {
            linkType: UserActivityLinkType.None;
          }
      );
    }
  | {
      activityType: UserActivityType.WEEKLY_ENCOURAGEMENT;
      data: {
        description: string;
        imageUrl: string;
        title: string;
        type: UserActivityWeeklyEncouragementType;
      };
    }
  | {
      activityType: UserActivityType.ALLOTMENT_CREDIT_DISBURSEMENT;
      data: {
        id: string;
        date: string;
        amount: number;
        companyId: string;
        userId: string;
        projectId: string;
        subscriptionId: string;
        certificateUrl?: string;
      };
    }
  | {
      activityType: UserActivityType.ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION;
      data: {
        amount: number;
        companyId: string;
      };
    }
);

export type UserActivityUOM = 'kgCO2e' | 'MT';

export enum UserActivityType {
  USER_OFFSET = 'USER_OFFSET',
  COMPANY_OFFSET = 'COMPANY_OFFSET',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  WEEKLY_ENCOURAGEMENT = 'WEEKLY_ENCOURAGEMENT',
  ALLOTMENT_CREDIT_DISBURSEMENT = 'ALLOTMENT_CREDIT_DISBURSEMENT',
  ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION = 'ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION',
}

export enum UserActivityLinkType {
  None = 'NONE',
  Communication = 'COMMUNICATION',
}

export enum UserActivityPartitionKey {
  Notifications = 'notifications',
}

export enum UserActivityWeeklyEncouragementType {
  CONTEST_RANK_DOWN = 'CONTEST_RANK_DOWN',
  CONTEST_RANK_NEUTRAL = 'CONTEST_RANK_NEUTRAL',
  CONTEST_RANK_UP = 'CONTEST_RANK_UP',
  TRACK_HABITS = 'TRACK_HABITS',
  MATERIAL_SAVINGS = 'MATERIAL_SAVINGS',
}
