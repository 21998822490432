import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { MTCO2E } from '../../../utils/stringConstants';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { EMISSIONS_BENCHMARKS } from '../../../graphql/dashboard/emissions/emissions';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { Colors } from '../../../theme/shared';
import { typographyToVictory } from '../../../utils/victoryUtils';
import AppInfo from '../../common/AppInfo';

export type AnnualFootprintProjectionChartTypes = 'user' | 'country' | 'global';

const generateColor = (type: AnnualFootprintProjectionChartTypes) => {
  switch (type) {
    case 'user':
      return Colors.ElectricGreen;
    case 'country':
      return Colors.CobaltBlue;
    case 'global':
      return Colors.LightBlue;
    default:
      return '';
  }
};

const generateLegend = (type: AnnualFootprintProjectionChartTypes) => (
  <Grid item style={{ padding: 12 }}>
    <Grid container flexDirection="row" alignItems="center">
      <Box
        style={{
          width: 8,
          height: 8,
          borderRadius: 2,
          marginRight: 4,
          backgroundColor: generateColor(type),
        }}
      />
      <Typography variant="subtitle2">
        {
          {
            user: 'My Footprint',
            country: 'United States',
            global: 'Global',
          }[type]
        }
      </Typography>
    </Grid>
  </Grid>
);

export default function BenchmarksChart() {
  const theme = useTheme();
  const { user, company } = useContext(AuthorizationContext);
  const skip = !user?.id || !company.id;

  const {
    loading: emissionsLoading,
    error: emissionsError,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
    },
    skip,
  });

  const {
    loading: benchmarksLoading,
    error: benchmarksError,
    data: {
      averageCarbonFootprintForCountry,
      averageCarbonFootprintForGlobe,
    } = {},
  } = useQuery(EMISSIONS_BENCHMARKS, {
    variables: {
      country: user?.country,
    },
  });

  const userFootprint =
    Math.round((projectedEmissions?.currentFootprint || 0) / 100) / 10;
  const countryFootprint = averageCarbonFootprintForCountry?.total || 0;
  const globalFootprint = averageCarbonFootprintForGlobe?.total || 0;

  const maxDomain = Math.round(
    Math.max(userFootprint, countryFootprint, globalFootprint) + 2,
  );

  const data = useMemo(
    () => [
      { x: 'user', y: userFootprint },
      { x: 'country', y: countryFootprint },
      { x: 'global', y: globalFootprint },
    ],
    [userFootprint, countryFootprint, globalFootprint],
  );

  return (
    <DataStateHandler
      loading={emissionsLoading || benchmarksLoading || skip}
      error={emissionsError || benchmarksError}>
      <Grid
        container
        flexDirection="column"
        style={{
          width: 430,
          marginTop: 50,
          marginLeft: 30,
        }}>
        <div style={{ position: 'absolute', right: 0, top: 60 }}>
          <AppInfo id="annual_footprint_projection" />
        </div>
        <Grid item>
          <Typography
            variant="subtitle2"
            style={{
              color: Colors.TertiaryText,
            }}>
            {MTCO2E}
          </Typography>
        </Grid>
        <Grid alignItems="center" justifyContent="center">
          <VictoryChart
            padding={{ left: 30, right: 0, top: 20, bottom: 30 }}
            domainPadding={{ x: [72, 72] }}
            maxDomain={{ y: maxDomain }}
            height={200}
            width={350}>
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                grid: { stroke: 'transparent' },
              }}
              tickLabelComponent={<text />}
              data={data}
              tickValues={data.map((map) => map.x)}
            />
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: {
                  ...typographyToVictory(theme.typography.h5),
                  fill: Colors.TertiaryText,
                },
                grid: { stroke: Colors.DisabledGrey },
              }}
            />
            <VictoryBar
              barWidth={64}
              cornerRadius={{
                topLeft: 8,
                topRight: 8,
              }}
              style={{
                data: {
                  fill: ({ datum }) => generateColor(datum.x),
                },
                labels: {
                  ...typographyToVictory(theme.typography.h4),
                  fill: Colors.TertiaryText,
                },
              }}
              data={data}
              labels={[userFootprint, countryFootprint, globalFootprint]}
            />
          </VictoryChart>
        </Grid>
        <Grid item container flexDirection="row" justifyContent="center">
          {generateLegend('user')}
          {generateLegend('country')}
          {generateLegend('global')}
        </Grid>
      </Grid>
    </DataStateHandler>
  );
}
