import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import moment from 'moment';

import _ from 'lodash';

import React, { useContext, useState } from 'react';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../../contexts/CompanySettingsContext';
import { CompanySubscription } from '../../../types/company/types';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import SVG from '../../../assets/svg';
import { Colors } from '../../../theme/shared';
import CompanySubscriptionModal from '../CompanySubscriptionModal';
import { subscriptionDateRangeDisplay } from '../../../pages/settings/SubscriptionSettings';

type SubscriptionListItem = CompanySubscription & {
  dates: string;
  creditBalance: number;
  status: 'Upcoming' | 'Active' | 'Inactive';
};

const SubscriptionsList: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { company, loading } = useContext(CompanySettingsContext);

  const {
    subscriptionData: { creditHistory = [], subscriptionHistory = [] } = {},
  } = company;

  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [subscription, setSubscription] = useState<
    SubscriptionListItem | undefined
  >(undefined);

  const tableStructure: GroupableTableStructure<SubscriptionListItem>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'status',
          display: 'Status',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Status',
          render: (c) => (
            <Typography
              sx={{
                padding: 1,
                textAlign: 'center',
                borderRadius: '8px',
                background: {
                  Inactive: '#EFEFEF',
                  Active: '#F1F8FD',
                  Upcoming: '#FFF6DC',
                }[c.status],
                color: {
                  Inactive: '#6B6B6B',
                  Active: '#3E7DA2',
                  Upcoming: '#FFBB00',
                }[c.status],
              }}
              variant="body2">
              {c.status}
            </Typography>
          ),
        },
        {
          key: 'dates',
          display: 'Dates',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Dates',
          render: (c) => <Typography variant="body2">{c.dates}</Typography>,
        },
        {
          key: 'licenseFee',
          display: 'ZM Fee',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Dates',
          render: (c) => (
            <Typography variant="body2">{c.licenseFee}</Typography>
          ),
        },
        {
          key: 'creditBalance',
          display: 'Credits',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search',
          render: (c) => (
            <Typography variant="body2">{c.creditBalance}</Typography>
          ),
        },
        {
          key: 'allotmentCreditsPerUser',
          display: 'Credits Per User',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search',
          render: (c) => (
            <Typography variant="body2">{c.allotmentCreditsPerUser}</Typography>
          ),
        },
        {
          key: 'action',
          display: 'Notes',
          searchable: true,
          render: (c) => (
            <IconButton
              disabled={!c.notes}
              onClick={(e) => {
                e.stopPropagation();
                setNotes(c.notes);
                return false;
              }}>
              <SVG.Edit />
            </IconButton>
          ),
        },
      ],
    },
  ];

  const getStatus = (s: CompanySubscription) => {
    const now = new Date();
    if (isBefore(endOfDay(moment(s.endDate).toDate()), now)) {
      return 'Inactive';
    }

    if (isAfter(startOfDay(moment(s.startDate).toDate()), now)) {
      return 'Upcoming';
    }

    return 'Active';
  };

  const getBalance = (s: CompanySubscription) =>
    creditHistory
      .filter((ch) => ch.type === 'CREDIT' && ch.subscriptionId === s.id)
      .reduce((acc, cur) => acc + cur.amount, 0);

  const creditBalance = subscription ? getBalance(subscription) : 0;

  const sorted = _.sortBy(subscriptionHistory, 'startDate').reverse();

  return (
    <DataStateHandler loading={loading}>
      <Grid sx={{ padding: 3 }} container direction="column">
        <Grid
          item
          borderBottom={`1px solid ${Colors.DisabledGrey}`}
          pb={2}
          mb={2}>
          <Grid container alignItems="center">
            <IconButton sx={{ mr: 1 }} onClick={onClose}>
              <SVG.Back />
            </IconButton>
            <Typography variant="h3">Subscriptions</Typography>
          </Grid>
        </Grid>
        <GroupableSelectTable<SubscriptionListItem>
          tableData={
            sorted.map((sub) => ({
              ...sub,
              dates: subscriptionDateRangeDisplay(sub),
              status: getStatus(sub),
              creditBalance: getBalance(sub),
            })) || []
          }
          onRowClick={(data) => setSubscription(data)}
          tableStructure={tableStructure}
          hideStickyCol
        />
        <Dialog
          open={!!notes}
          maxWidth="sm"
          fullWidth
          onClose={() => setNotes(undefined)}>
          <DialogContent>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  borderBottom={`1px solid ${Colors.DisabledGrey}`}
                  mb={2}>
                  <Typography variant="h3">Notes</Typography>
                  <IconButton onClick={() => setNotes(undefined)}>
                    <SVG.Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                {notes}
              </Typography>
            </Grid>
          </DialogContent>
        </Dialog>
        <CompanySubscriptionModal
          subscription={subscription}
          creditBalance={creditBalance}
          onCancel={() => setSubscription(undefined)}
          onSubmit={() => setSubscription(undefined)}
          open={!!subscription}
        />
      </Grid>
    </DataStateHandler>
  );
};

export default SubscriptionsList;
