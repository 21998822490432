import React from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  ButtonBase,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextUtils from '../../../utils/textUtils';
import {
  AllotmentMetrics,
  Project,
  ProjectAllotmentMetrics,
  ProjectCategory,
} from '../../../types/project/types';
import ImageUtils from '../../../utils/imageUtils';
import CategoryIconDiamond from '../../common/Project/CategoryIconDiamond';
import ProjectDataPill, {
  ProjectDataPillDataType,
} from '../../common/Project/ProjectDataPill';
import ProjectCardLocation from '../../common/Project/ProjectCardLocation';
import { Colors } from '../../../theme/shared';
import DefaultImage from '../../common/DefaultImage';

import SVG from '../../../assets/svg-v2';

import useToggleProjectLike from '../../../hooks/useToggleProjectLike';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: 'flex-start',
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  shape: {
    marginTop: -18,
    marginLeft: 10,
    position: 'absolute',
  },
  projectName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: 2,
    marginTop: 8,
  },
}));

type Props = {
  category?: ProjectCategory;
  project: Project;
  projectMetrics?: ProjectAllotmentMetrics;
  userMetrics?: AllotmentMetrics;
  onClick: () => void;
};

const MemberProjectCard: React.FC<Props> = ({
  category,
  project,
  projectMetrics,
  userMetrics,
  onClick,
}) => {
  const classes = useStyles();

  const { toggleLike, likesProject, toggleLikeLoading } = useToggleProjectLike({
    id: project.id,
  });

  let dataType: ProjectDataPillDataType = 'LEARN_MORE';
  const companySponsored = (projectMetrics?.allotted || 0) > 0;
  const projectAvailable = projectMetrics?.available || 0;
  const userAvailable = userMetrics?.available || 0;

  if (projectAvailable > 0) {
    dataType =
      userAvailable > 0 && userAvailable <= projectAvailable
        ? 'REDEEM'
        : 'UNAVAILABLE';
  } else if (companySponsored) {
    dataType = 'UNAVAILABLE';
  }

  return (
    <Card
      className={classes.card}
      onClick={onClick}
      style={{
        border: `1px solid ${category?.color || '#CCCCCC'}`,
        position: 'relative',
      }}>
      <CardActionArea className={classes.cardActionArea}>
        <div>
          <div>
            {companySponsored && (
              <Box
                position="absolute"
                left="-4px"
                style={{
                  backgroundColor: Colors.CobaltBlue,
                  borderBottomRightRadius: 16,
                  padding: '6px 20px',
                  fontWeight: 700,
                }}>
                <Typography variant="h4" color="white">
                  Company-sponsored
                </Typography>
              </Box>
            )}
            {project.headerImageUris?.medium ? (
              <CardMedia
                component="img"
                height="100px"
                style={ImageUtils.ObjectPositionStyle(
                  project.headerImagePosition,
                )}
                image={project.headerImageUris?.medium}
              />
            ) : (
              <DefaultImage height={100} />
            )}
          </div>
          <div className={classes.shape}>
            <CategoryIconDiamond size="small" categoryId={project.category} />
          </div>
          {category ? (
            <Box
              position="absolute"
              right="16px"
              width="100%"
              mt="-15px"
              mb="16px">
              <ProjectDataPill
                category={category}
                project={project}
                dataType={dataType}
                iconStyle={{ height: 12 }}
              />
            </Box>
          ) : null}
        </div>
        <CardContent className={classes.cardContent}>
          <Grid>
            <Grid item>
              <Typography
                gutterBottom
                variant="h3"
                className={classes.projectName}
                style={{
                  color: category?.color,
                }}>
                {project.title}
              </Typography>
            </Grid>
            <Grid item style={{ minHeight: 44 }}>
              <Typography
                component="p"
                paragraph
                variant="body2"
                color="textSecondary">
                {TextUtils.clampText(project.smallSummary || '', 85)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Grid item>
              <ProjectCardLocation project={project} />
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {companySponsored ? (
                    <Typography
                      component="p"
                      sx={{ fontWeight: 'bold' }}
                      variant="body2">
                      {projectAvailable} MT Available
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  <ButtonBase
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLike();
                    }}
                    disabled={toggleLikeLoading}>
                    <Grid container alignItems="center">
                      {likesProject ? (
                        <SVG.Dislike height={48} width={48} />
                      ) : (
                        <SVG.Like height={48} width={48} />
                      )}
                    </Grid>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MemberProjectCard;
