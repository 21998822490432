import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import MemberLayout from '../../../components/layouts/MemberLayout';
import { GET_DISCOVER_COMMUNICATIONS } from '../../../graphql/communications/memberCommunications';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import CommunicationCard from '../../../components/member/discover/CommunicationCard';
import AppCarousel from '../../../components/common/AppCarousel';

const useStyles = makeStyles((theme) => ({
  side: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 1200,
      overflowY: 'auto',
    },
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const MemberDiscover: React.FC = () => {
  const { company } = useContext(AuthorizationContext);
  const {
    data: { communications: { items = [] } = {} } = {},
    loading,
    error,
  } = useQuery(GET_DISCOVER_COMMUNICATIONS, {
    variables: {
      companyId: company?.id,
      skip: !company,
    },
  });

  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles();

  const history = useHistory();

  const featured = items.filter((i) => i.isFeatured);

  const notFeatured = items.filter((i) => !i.isFeatured);

  const navigate = (id: string) => {
    history.push(`/member/discover/${id}`);
  };

  return (
    <MemberLayout>
      <DataStateHandler loading={loading} error={error}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3">Sustainability in the News</Typography>
          </Grid>
          <Grid item lg={9} xs={12} style={{ paddingTop: 16 }}>
            <Grid item xs={12}>
              <AppCarousel
                items={featured.map((item) => (
                  <CommunicationCard
                    key={item.id}
                    imageHeight={400}
                    communication={item}
                    onClick={(id) => navigate(id)}
                  />
                ))}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {notFeatured.slice(0, 4).map((fc) => (
                  <Grid item lg={6} xs={12}>
                    <CommunicationCard
                      imageHeight={234}
                      key={fc.id}
                      communication={fc}
                      onClick={(id) => navigate(id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            style={{ top: 16, position: 'relative', paddingTop: 0 }}
            className={classes.side}>
            {notFeatured.slice(4).map((fc) => (
              <CommunicationCard
                key={fc.id}
                communication={fc}
                imageHeight={lessThanMd ? 234 : 112}
                onClick={(id) => navigate(id)}
              />
            ))}
          </Grid>
        </Grid>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberDiscover;
