import { TypedDocumentNode, gql } from '@apollo/client';
import {
  QuestionProfile,
  QuestionTreeCategory,
  QuestionTreeCategoryStatusDetail,
  Tip,
} from '../../types/question/types';
import { User } from '../../types/user/types';

export const GET_QUESTION_TREE_CATEGORIES = gql`
  query GET_QUESTION_TREE_CATEGORIES($language: String) {
    questionTreeCategories(language: $language) {
      id
      category
      initialScreen
      title
      incompleteLabel
      iconUrl
      altIconUrl
      videoSourceUrl
      videoFallbackImageSource
    }
  }
` as TypedDocumentNode<{
  questionTreeCategories: QuestionTreeCategory[];
}>;

export const GET_QUESTIONS = gql`
  query GET_QUESTIONS(
    $language: String
    $questionTreeCategory: String
    $filterOnboardingQuestions: FilterOnboardingQuestionOptions
    $filterValidQuestions: Boolean
  ) {
    questions(
      language: $language
      questionTreeCategory: $questionTreeCategory
      filterValidQuestions: $filterValidQuestions
      filterOnboardingQuestions: $filterOnboardingQuestions
    ) {
      id
      questionTreeCategory
      categoryOrder
      title
      isActiveVersion
      hasOnboardingQuestions
      categoryTipTitle
      categoryLabel
      profileTipTitle
      categoryIconUrl
      categoryIconBoldUrl
      questions {
        questionId
        questionText
        isOnboarding
        allowUnsure
        userInput
        tags
        options {
          answerId
          answerText
          profileDocumentId
          nextQuestionId
        }
        userInputOptions {
          userEmissionsPropertyName
          propertyTemplate
          propertyTemplateKey
          nextQuestionId
          parameters {
            name
            value
          }
        }
      }
    }
  }
` as TypedDocumentNode<{
  questions: QuestionProfile[];
}>;

export const GET_CATEGORY_TIPS = gql`
  query GET_CATEGORY_TIPS($options: TipOptions) {
    tips(options: $options) {
      id
      text
      entityType
      categoryId
      questionTreeCategoryId
      profileId
      questionId
      answerId
    }
  }
` as TypedDocumentNode<{
  tips: Tip[];
}>;

export const GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL = gql`
  query GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL {
    me {
      id
      questionTreeCategoryStatusDetail {
        questionTreeCategory
        isComplete
        answered
        total
      }
    }
  }
` as TypedDocumentNode<{
  me: {
    id: string;
    questionTreeCategoryStatusDetail: QuestionTreeCategoryStatusDetail[];
  };
}>;

export const UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS = gql`
  mutation (
    $hasCompletedOnboardingQuestionnaire: Boolean
    $postalCodeChanged: Boolean
    $hasConfirmedLocation: Boolean
  ) {
    updateUserProfile(
      user: {
        hasCompletedOnboardingQuestionnaire: $hasCompletedOnboardingQuestionnaire
        postalCodeChanged: $postalCodeChanged
        hasConfirmedLocation: $hasConfirmedLocation
      }
    ) {
      id
      hasCompletedOnboardingQuestionnaire
      postalCodeChanged
      hasConfirmedLocation
    }
  }
` as TypedDocumentNode<
  Pick<
    User,
    'id' | 'hasCompletedOnboardingQuestionnaire' | 'postalCodeChanged'
  >,
  {
    hasCompletedOnboardingQuestionnaire?: boolean;
    postalCodeChanged?: boolean;
    hasConfirmedLocation?: boolean;
  }
>;
