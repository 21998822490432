import { Box, Grid, Switch, Typography } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';
import AppTextField from '../../common/AppTextField';
import { CommunicationInput } from '../types';
import CategoryIdSelect from './CategoryIdSelect';
import HeadingInput from './HeadingInput';
import ImageUpload from '../../common/ImageUpload';
import PublishFields from './PublishFields';
import AppRichTextField from '../../common/AppRichTextField';

const HintsAndTipsForm: React.FC<Disableable> = ({ disabled }) => {
  const { setTypedFieldValue, values, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Featured Project</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={values.isFeatured || false}
              onChange={(e, checked) =>
                setTypedFieldValue('isFeatured', checked)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CategoryIdSelect disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <HeadingInput disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Author</Typography>
        <Field
          name="author"
          disabled={disabled}
          label=""
          component={AppTextField}
          placeholder="Author Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUpload
          disabled={disabled}
          value={values.headerImageUrl || ''}
          onChange={(file) => setTypedFieldValue('headerImage', file)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Description</Typography>
        {disabled ? (
          <Box
            dangerouslySetInnerHTML={{
              __html: values.content,
            }}
          />
        ) : (
          <AppRichTextField
            labelText="Body"
            initialValue={initialValues.content}
            onChange={(v) => setTypedFieldValue('content', v)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <PublishFields disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default HintsAndTipsForm;
