import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  ProfileQuestionInputBase,
  QuestionAnswer,
  QuestionOption,
  QuestionTag,
} from '../../../types/question/types';
import {
  QuestionnaireActionTypes,
  useAnswerQuestion,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import { createQuestionAnswerFromQuestionAndEmissionSources } from '../../../utils/questionnaire';
import QuestionnaireButton from './QuestionnaireButton';
import { Colors } from '../../../theme/shared';

export type OptionInputItemProps = {
  item: QuestionOption;
  internalValue: QuestionAnswer;
  answerHandler: (option: QuestionOption) => void;
} & Pick<ProfileQuestionInputBase, 'forceDisabled'>;

export function OptionInputItem({
  item,
  internalValue,
  answerHandler,
  forceDisabled,
}: OptionInputItemProps) {
  const inputValue = internalValue.unsure
    ? item.unsure
    : internalValue.answerIds?.find((find) => find === item.answerId);

  return (
    <Grid item xs={6}>
      <QuestionnaireButton
        variant={inputValue ? 'contained' : 'outlined'}
        onClick={() => answerHandler(item)}
        disabled={forceDisabled}
        sx={{
          ...(inputValue
            ? {
                // Force enabled look when disabled
                '&.MuiButton-root:disabled': {
                  backgroundColor: Colors.CobaltBlue,
                  color: 'white',
                },
              }
            : {}),
        }}
        fullWidth>
        {item.answerText}
      </QuestionnaireButton>
    </Grid>
  );
}

function OptionInput({
  profileCard,
  inputOnly,
  forceDisabled,
}: ProfileQuestionInputBase): JSX.Element {
  const state = useQuestionnaire();

  const dispatch = useQuestionnaireDispatch();
  const answerQuestion = useAnswerQuestion();

  const [internalValue, setInternalValue] = useState(
    createQuestionAnswerFromQuestionAndEmissionSources(
      state.userEmissionSources,
      profileCard.profileId,
      profileCard.question,
    ),
  );

  useEffect(() => {
    setInternalValue(
      createQuestionAnswerFromQuestionAndEmissionSources(
        state.userEmissionSources,
        profileCard.profileId,
        profileCard.question,
      ),
    );
  }, [profileCard, state.userEmissionSources]);

  const multiSelect = profileCard.question?.tags?.includes(
    QuestionTag.ModesOfTransportation,
  );

  const answerHandler = (option: QuestionOption) => {
    if (multiSelect) {
      if (option.answerId) {
        const tempValue: QuestionAnswer = {
          ...internalValue,
        };
        let tempAnswerIds = [...(internalValue.answerIds || [])];

        if (tempAnswerIds.includes(option.answerId)) {
          // remove the answer
          tempAnswerIds = tempAnswerIds.filter(
            (filter) => filter !== option.answerId,
          );
        } else {
          // add it
          tempAnswerIds.push(option.answerId);
        }
        const nextValue: QuestionAnswer = {
          ...tempValue,
          answerIds: tempAnswerIds,
        };
        setInternalValue(nextValue);

        answerQuestion(
          profileCard,
          nextValue,
          option.profileDocumentId
            ? {
                answerId: option.answerId,
                profileDocumentId: option.profileDocumentId,
              }
            : undefined,
        );
      }
    } else {
      const nextValue: QuestionAnswer = {
        ...internalValue,
        answerIds: option.answerId ? [option.answerId] : [],
        unsure: !!option.unsure,
      };

      setInternalValue(nextValue);

      answerQuestion(
        profileCard,
        nextValue,
        option.profileDocumentId && option.answerId
          ? {
              answerId: option.answerId,
              profileDocumentId: option.profileDocumentId,
            }
          : undefined,
      );
    }
  };

  const disabled =
    !internalValue.answerIds ||
    internalValue.answerIds.length === 0 ||
    forceDisabled;

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item container flexDirection="row" spacing={1}>
        {profileCard.question?.options?.map((item) => (
          <OptionInputItem
            key={item.answerId}
            item={item}
            internalValue={internalValue}
            answerHandler={answerHandler}
            forceDisabled={forceDisabled}
          />
        ))}
        {profileCard.question?.allowUnsure && (
          <OptionInputItem
            item={{
              answerText: 'Unsure',
              unsure: true,
            }}
            internalValue={internalValue}
            answerHandler={answerHandler}
            forceDisabled={forceDisabled}
          />
        )}
      </Grid>
      {!inputOnly && multiSelect && (
        <Grid item pt={3}>
          <QuestionnaireButton
            variant="contained"
            onClick={() => {
              dispatch({ type: QuestionnaireActionTypes.NEXT });
            }}
            disabled={disabled}>
            Next
          </QuestionnaireButton>
        </Grid>
      )}
    </Grid>
  );
}

export default OptionInput;
