import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { Company } from '../../../types/company/types';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconDiamond from '../../common/Project/CategoryIconDiamond';
import SVG from '../../../assets/svg-v2';
import { Colors } from '../../../theme/shared';
import { ProjectCategory } from '../../../types/project/types.js';

export type Props = {
  categoryIds?: Company['categoryIds'];
  categoryIdsMode?: 'SELECT' | 'REMOVE';
  noCategoriesText?: string;
  onClick?: (categoryId: string) => void;
  clickIcon?: 'plus' | 'trash';
};

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '16px',
    height: 80,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
  },
  label: {
    color: theme.palette.primary.dark,
    marginLeft: -12,
  },
}));

/**
 * Renders a list of project category cards.
 */
const MemberCategoryCards: React.FC<Props> = ({
  categoryIds,
  categoryIdsMode = 'IDS_ONLY',
  noCategoriesText,
  onClick,
  clickIcon = 'plus',
}) => {
  const classes = useStyles();
  const { loading: categoriesLoading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const [selectedCategory, setSelectedCategory] = useState<
    ProjectCategory | undefined
  >(undefined);

  let filteredCategories = projectCategories || [];

  if (categoryIds) {
    filteredCategories = projectCategories.filter(
      (pc) => categoryIds.includes(pc.id) === (categoryIdsMode === 'SELECT'),
    );
  }

  return (
    <DataStateHandler loading={categoriesLoading}>
      {(filteredCategories?.length && (
        <>
          <Grid container spacing={3} rowSpacing={1} alignItems="stretch">
            {filteredCategories.map(
              (category) =>
                category && (
                  <Grid key={category.id} item md={4} xs={12}>
                    <Box
                      onClick={() => setSelectedCategory(category)}
                      role="button"
                      style={{ cursor: 'pointer' }}>
                      <Paper
                        className={classes.card}
                        style={{
                          backgroundImage: `url(${category.backgroundImageUri})`,
                        }}
                      />

                      <div style={{ position: 'relative', top: -24, left: 12 }}>
                        <CategoryIconDiamond
                          categoryId={category.id}
                          size="medium"
                        />
                        <Grid
                          container
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{
                            marginTop: 8,
                          }}>
                          <Typography variant="h3" className={classes.label}>
                            {category.label}
                          </Typography>
                          {!!onClick && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick(category.id);
                              }}
                              style={{ marginRight: 8 }}>
                              {clickIcon === 'plus' ? (
                                <SVG.Plus />
                              ) : (
                                <SVG.Trash />
                              )}
                            </IconButton>
                          )}
                        </Grid>
                      </div>
                    </Box>
                  </Grid>
                ),
            )}
          </Grid>
          <Dialog
            open={!!selectedCategory}
            maxWidth="md"
            onClose={() => setSelectedCategory(undefined)}>
            <Grid container direction="column" sx={{ minHeight: 640 }}>
              <Box position="relative">
                <IconButton
                  sx={{
                    position: 'absolute',
                    bgcolor: Colors.DisabledGrey,
                    top: 8,
                    right: 8,
                  }}
                  onClick={() => setSelectedCategory(undefined)}>
                  <SVG.Close />
                </IconButton>
              </Box>
              <img
                src={selectedCategory?.backgroundImageUri}
                alt={selectedCategory?.label}
                style={{ width: '100%', objectFit: 'cover', height: 240 }}
              />
              <Typography variant="h1" sx={{ padding: 2 }}>
                {selectedCategory?.label}
              </Typography>
              <Typography variant="body1" sx={{ padding: 2 }}>
                {selectedCategory?.description}
              </Typography>
              {onClick ? (
                <Button
                  sx={{ mt: 'auto', mb: 2, mx: 2 }}
                  variant="contained"
                  onClick={() => {
                    onClick(selectedCategory?.id || '');
                    setSelectedCategory(undefined);
                  }}>
                  {clickIcon === 'plus'
                    ? '+ Add Sustainability Interest'
                    : 'Remove Sustainability Interest'}
                </Button>
              ) : null}
            </Grid>
          </Dialog>
        </>
      )) || (
        <Grid item style={{ width: '100%' }}>
          <Typography
            variant="h2"
            style={{
              color: Colors.TertiaryText,
              fontWeight: 500,
              textAlign: 'center',
            }}>
            {noCategoriesText || `No sustainability interests available.`}
          </Typography>
        </Grid>
      )}
    </DataStateHandler>
  );
};

export default MemberCategoryCards;
