import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Carousel } from 'react-responsive-carousel';

type Props = {
  items: JSX.Element[];
};

const AppCarousel: React.FC<Props> = ({ items }) => (
  <Carousel
    autoPlay
    showArrows={false}
    showStatus={false}
    showThumbs={false}
    interval={4000}
    infiniteLoop>
    {items}
  </Carousel>
);

export default AppCarousel;
