import React from 'react';
import { Prompt } from 'react-router';
import { Box, Snackbar } from '@mui/material';
import {
  QuestionnaireActionTypes,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import { ProfileCardType } from '../../../types/question/types';
import ProfileQuestion from './ProfileQuestion';
import { Colors } from '../../../theme/shared';
import { ProfileTips } from './ProfileTips';
import Loading from '../Loading';
import MemberAlert from '../../member/myhabits/MemberAlert';
import useRemoveQuestionRelatedData from '../../../hooks/cacheModifiers/useRemoveQuestionRelatedData';

const CurrentQuestionnaireCard: React.FC = () => {
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();
  const { remove: removeQuestionRelatedData } = useRemoveQuestionRelatedData();

  const item = state.profileCards[state.carouselIndex];

  const renderCard = () => {
    switch (item?.cardType) {
      case ProfileCardType.Question:
        return <ProfileQuestion profileCard={item} />;
      case ProfileCardType.Tip:
      case ProfileCardType.CategoryTip:
        return <ProfileTips profileCardTip={item} />;
      default:
        return <Loading />;
    }
  };

  return (
    <>
      <Box
        style={{
          maxWidth: 600,
          borderRadius: 16,
          margin: 'auto',
          border: `1px solid ${Colors.DisabledGrey}`,
        }}>
        {renderCard()}
      </Box>
      {item?.cardType === ProfileCardType.Question && (
        <Prompt
          message={() => {
            removeQuestionRelatedData();
            return true;
          }}
        />
      )}
      <Snackbar
        open={!!state.toast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => {
          dispatch({
            type: QuestionnaireActionTypes.CLEAR_TOAST,
          });
        }}>
        <MemberAlert
          iconUrl=""
          title={state.toast || ''}
          description="This can be updated in your profile at any time."
          onClose={() =>
            dispatch({
              type: QuestionnaireActionTypes.CLEAR_TOAST,
            })
          }
        />
      </Snackbar>
    </>
  );
};

export default CurrentQuestionnaireCard;
