import React from 'react';
import { Grid } from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberProfileAvatar from '../../../components/member/profile/MemberProfileAvatar';
import MemberSettingsList from '../../../components/member/profile/MemberSettingsList';
import AboutMe from '../../../components/member/profile/AboutMe';
import MemberProjectHistory from '../../../components/member/profile/MemberProjectHistory';

const MemberProfile: React.FC = () => (
  <MemberLayout>
    <Grid container spacing={6} justifyContent="center">
      <Grid item xs={12}>
        <MemberProfileAvatar />
      </Grid>
      <Grid item md={6} xs={12}>
        <AboutMe />
      </Grid>
      <Grid item md={6} xs={12}>
        <MemberProjectHistory />
        <MemberSettingsList />
      </Grid>
    </Grid>
  </MemberLayout>
);

export default MemberProfile;
