import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ResponsivePieCanvas } from '@nivo/pie';
import {
  areIntervalsOverlapping,
  endOfMonth,
  format,
  isBefore,
  isSameMonth,
  min,
  parse,
  startOfMonth,
  subMonths,
} from 'date-fns';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { OffsetContext } from '../../contexts/OffsetContext';
import {
  EmissionsCalcResult,
  GET_EMISSIONS_CALCULATIONS,
} from '../../graphql/offsetProject/offsetProject';
import { chartColors } from '../../theme/theme';
import { ActionMap } from '../../types/common';
import { BaseCompany } from '../../types/company/types';
import {
  EmisisonsSetting,
  ProjectOffsetAllocation,
  ProjectPurchaseAllocationStatus,
  ProjectPurchaseStatus,
} from '../../types/offsetProject/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import DatePickerButton from '../common/DatePickerButton';
import DividerWithText from '../common/DividerWithText';
import PieChart, { PieChartData } from '../common/PieChart/PieChart';
import SelectButton from '../common/SelectButton/SelectButton';
import TooltipError from '../common/Tooltip/TooltipError';

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flex: 1,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  offsetCalculator: {
    backgroundColor: theme.palette.backgrounds.light,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    height: '100%',
  },
  grey: {
    color: '#DADADA',
  },
  centered: {
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
}));

// #region State
type State = {
  projectOffset: Partial<ProjectOffsetAllocation> &
    Required<Pick<ProjectOffsetAllocation, 'startDate' | 'endDate'>>;
  company?: BaseCompany;
  maxQuantityMT: number;
  quantityPct: number;
  quantityMT: number;
  valid: boolean;
};

const lastMonth = endOfMonth(subMonths(new Date(), 1));
const initialState: State = {
  projectOffset: {
    startDate: lastMonth,
    endDate: lastMonth,
  },
  maxQuantityMT: 0,
  quantityPct: 0,
  quantityMT: 0,
  valid: false,
};

const enum StateActionType {
  setProjectOffset = 'SET_PROJECT_OFFSET',
  setType = 'SET_TYPE',
  setSubType = 'SET_SUBTYPE',
  setStartDate = 'SET_START_DATE',
  setEndDate = 'SET_END_DATE',
  setMaxQuantityMT = 'SET_MAX_QUANTITY_MT',
  setQuantityPercent = 'SET_QUANTITY_PERCENT',
  setQuantityMT = 'SET_QUANTITY_MT',
  reset = 'RESET',
}

type StatePayload = {
  [StateActionType.setProjectOffset]: State['projectOffset'];
  [StateActionType.setType]: State['projectOffset']['allocType'];
  [StateActionType.setSubType]: State['projectOffset']['allocSubType'];
  [StateActionType.setStartDate]: State['projectOffset']['startDate'];
  [StateActionType.setEndDate]: State['projectOffset']['endDate'];
  [StateActionType.setMaxQuantityMT]: State['maxQuantityMT'];
  [StateActionType.setQuantityPercent]: State['quantityPct'];
  [StateActionType.setQuantityMT]: State['quantityMT'];
  [StateActionType.reset]: never;
};

type StateActions = ActionMap<StatePayload>[keyof ActionMap<StatePayload>];

const validateState = (state: State): State => {
  const { projectOffset } = state;

  const isValid = yup
    .object()
    .shape(
      {
        allocType: yup.string().required(),
        allocSubType: yup.string().required(),
        startDate: yup.date().required(),
        endDate: yup.date().required(),
        quantity: yup.number().when('id', {
          is: (id: string) => !id,
          then: yup.number().positive().required(),
          otherwise: yup.number().nullable(),
        }),
      } as Record<
        keyof Omit<typeof state['projectOffset'], 'id'>,
        yup.AnySchema
      >,
      [['quantity', 'id']],
    )
    .isValidSync(projectOffset);

  const datesValid =
    isValid &&
    !!state.projectOffset.startDate &&
    !!state.projectOffset.endDate &&
    (isSameMonth(state.projectOffset.startDate, state.projectOffset.endDate) ||
      isBefore(state.projectOffset.startDate, state.projectOffset.endDate));

  return { ...state, valid: isValid && datesValid };
};

const stateReducer = (state: State, action: StateActions): State => {
  switch (action.type) {
    case StateActionType.setProjectOffset: {
      return validateState({
        ...state,
        projectOffset: { ...action.payload },
        quantityMT: action.payload.quantity || 0,
        quantityPct: action.payload.quantity
          ? Math.round(
              (action.payload.quantity / state.maxQuantityMT) * 100 * 100,
            ) / 100
          : 0,
      });
    }
    case StateActionType.setType: {
      return validateState({
        ...state,
        projectOffset: {
          ...state.projectOffset,
          allocType: action.payload,
          allocSubType: undefined,
        },
      });
    }
    case StateActionType.setSubType: {
      return validateState({
        ...state,
        projectOffset: {
          ...state.projectOffset,
          allocSubType: action.payload,
        },
      });
    }
    case StateActionType.setStartDate: {
      return validateState({
        ...state,
        projectOffset: { ...state.projectOffset, startDate: action.payload },
      });
    }
    case StateActionType.setEndDate: {
      return validateState({
        ...state,
        projectOffset: { ...state.projectOffset, endDate: action.payload },
      });
    }
    case StateActionType.setMaxQuantityMT: {
      return validateState({
        ...state,
        maxQuantityMT: action.payload || 0,
        quantityMT: 0,
        quantityPct: 0,
        projectOffset: {
          ...state.projectOffset,
          quantity: 0,
        },
      });
    }
    case StateActionType.setQuantityMT: {
      if (action.payload > state.maxQuantityMT || action.payload < 0) {
        return state;
      }
      return validateState({
        ...state,
        projectOffset: {
          ...state.projectOffset,
          quantity: action.payload,
        },
        quantityMT: action.payload,
        quantityPct:
          Math.round((action.payload / state.maxQuantityMT) * 100 * 100) / 100,
      });
    }
    case StateActionType.setQuantityPercent: {
      const pct =
        action.payload < 0
          ? 0
          : (action.payload > 100 && 100) || action.payload;
      const quantityMT = Math.round(state.maxQuantityMT * (pct / 100));
      return validateState({
        ...state,
        projectOffset: {
          ...state.projectOffset,
          quantity: quantityMT,
        },
        quantityPct: pct,
        quantityMT,
      });
    }
    case StateActionType.reset:
      return { ...initialState };
    default:
      return state;
  }
};

// #endregion

type AllocTypeMapping = Record<
  EmisisonsSetting['allocType'],
  {
    allocTypeLabel: EmisisonsSetting['allocTypeLabel'];
    subTypes: Record<
      EmisisonsSetting['allocSubType'],
      EmisisonsSetting['allocSubTypeLabel']
    >;
  }
>;

// #region Empty State

const EmptyChart = ({
  title,
  subTitle,
  button,
}: {
  title: string;
  subTitle: string;
  button?: JSX.Element;
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item style={{ position: 'relative', width: 200, height: 200 }}>
        <ResponsivePieCanvas
          data={[
            { id: 'small', value: 15 },
            { id: 'large', value: 75 },
          ]}
          colors={{ scheme: 'greys' }}
          margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
          padAngle={0}
          cornerRadius={0}
          borderWidth={1}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          isInteractive={false}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.grey}>
          000 MT C0₂E
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.centered}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.centered}>
          {subTitle}
        </Typography>
      </Grid>
      {button && <Grid item>{button}</Grid>}
    </>
  );
};

// #endregion

type Props = {
  offset?: ProjectOffsetAllocation;
  onReset: () => void;
};

const EmployeeCarbonEmissionsChart = ({
  offset: offsetProp,
  onReset,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const {
    addOffset,
    removeOffset,
    divisions,
    projectPurchases,
    emissionsSettings,
    userHasEditAccess,
    company,
    setCompany,
    offsets,
  } = useContext(OffsetContext);

  const hasProjects =
    !!projectPurchases?.filter(
      ({ status, remaining }) =>
        status === ProjectPurchaseStatus.COMPLETE && remaining > 0,
    )?.length || false;

  const types = emissionsSettings?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.allocType]: {
        allocTypeLabel: curr.allocTypeLabel,
        subTypes: {
          ...prev[curr.allocType]?.subTypes,
          [curr.allocSubType]: curr.allocSubTypeLabel,
        },
      },
    }),
    {} as AllocTypeMapping,
  );

  const [state, stateDispatch] = useReducer(stateReducer, initialState);

  const { quantityPct, quantityMT, projectOffset } = state;

  const existingOffsets = offsets.filter(
    (o) =>
      o.allocType === projectOffset.allocType &&
      o.allocSubType === projectOffset.allocSubType &&
      o.startDate &&
      o.endDate,
  );
  const hasExistingOffset = existingOffsets.reduce(
    (prev, { id, startDate: start, endDate: end }) => {
      if (id !== projectOffset.id) {
        return (
          prev ||
          areIntervalsOverlapping(
            {
              start,
              end,
            },
            {
              start: projectOffset.startDate,
              end: projectOffset.endDate,
            },
            { inclusive: true },
          )
        );
      }
      return prev;
    },
    false,
  );

  // #region Emissions Calculations

  const [
    loadEmissions,
    {
      loading: loadingEmissions,
      data: { emissionsCalculations } = {
        emissionsCalculations: {} as EmissionsCalcResult,
      },
    },
  ] = useLazyQuery(GET_EMISSIONS_CALCULATIONS);

  const [currentAllocation, setCurrentAllocation] = useState(0);
  useEffect(() => {
    if (
      !loadingEmissions &&
      !projectOffset.id &&
      company?.id &&
      projectOffset.startDate &&
      projectOffset.endDate &&
      projectOffset.allocType &&
      projectOffset.allocSubType
    ) {
      setCurrentAllocation(
        projectPurchases
          ?.flatMap(({ allocations }) => allocations || [])
          .filter((o) => o.periodEnd && o.periodStart)
          .reduce(
            (
              allocTotal,
              {
                status,
                periodStart,
                periodEnd,
                allocType,
                allocSubType,
                quantity,
              },
            ) => {
              const allocStart = startOfMonth(
                parse(periodStart, 'yyyy-MM', new Date()),
              );
              const allocEnd = endOfMonth(
                parse(periodEnd, 'yyyy-MM', new Date()),
              );
              const rangeOverlaps = areIntervalsOverlapping(
                {
                  start: startOfMonth(projectOffset.startDate),
                  end: endOfMonth(projectOffset.endDate),
                },
                {
                  start: allocStart,
                  end: allocEnd,
                },
                { inclusive: true },
              );
              if (
                status !== ProjectPurchaseAllocationStatus.COMPLETED &&
                allocType === projectOffset.allocType &&
                allocSubType === projectOffset.allocSubType &&
                rangeOverlaps
              ) {
                return allocTotal + quantity;
              }
              return allocTotal;
            },
            0,
          ) || 0,
      );
      loadEmissions({
        variables: {
          companyId: company.id,
          startYYYYMM: parseInt(format(projectOffset.startDate, 'yyyyMM'), 10),
          endYYYYMM: parseInt(format(projectOffset.endDate, 'yyyyMM'), 10),
        },
      });
    }
  }, [
    loadingEmissions,
    projectOffset.id,
    company?.id,
    projectOffset.startDate,
    projectOffset.endDate,
    projectOffset.allocType,
    projectOffset.allocSubType,
  ]);

  const projectPurchaseOffset =
    (projectOffset.allocType &&
      projectOffset.allocSubType &&
      Math.floor(
        (emissionsCalculations?.offsetTotals
          ?.filter(
            ({ allocType, allocSubType, offsetType }) =>
              projectOffset.allocType === allocType &&
              projectOffset.allocSubType === allocSubType &&
              offsetType === 'company',
          )
          .reduce((curr, { emissions }) => curr + emissions, 0) || 0) / 1000,
      )) ||
    0;
  const totalEmissions =
    (projectOffset.allocType &&
      projectOffset.allocSubType &&
      Math.floor(
        (emissionsCalculations?.emissionsTotals
          ?.filter(
            ({ allocType, allocSubType }) =>
              projectOffset.allocType === allocType &&
              projectOffset.allocSubType === allocSubType,
          )
          .reduce((curr, { emissions }) => curr + emissions, 0) || 0) / 1000,
      )) ||
    0;

  const data: PieChartData[] = [
    {
      id: 'Offset',
      color: chartColors.darkBlue,
      label: 'Offset',
      value: projectPurchaseOffset || 0,
    },
    {
      id: 'Remaining',
      color: chartColors.blue,
      label: 'Opportunity',
      value: totalEmissions,
    },
  ];

  useEffect(() => {
    if (totalEmissions !== state.maxQuantityMT) {
      stateDispatch({
        type: StateActionType.setMaxQuantityMT,
        payload: totalEmissions,
      });
    }
  }, [totalEmissions, state.maxQuantityMT]);
  // #endregion

  const reset = () => {
    stateDispatch({ type: StateActionType.reset });
    onReset();
  };

  useEffect(() => {
    if (
      offsetProp &&
      offsets.findIndex(({ id }) => offsetProp.id === id) === -1
    ) {
      reset();
    }
  }, [offsets]);

  const onAddOffset = () => {
    if (projectOffset.id && !projectOffset.quantity) {
      removeOffset(projectOffset.id);
    } else {
      addOffset(projectOffset as ProjectOffsetAllocation);
    }

    reset();
  };

  const [loadingOffset, setLoadingOffset] = useState(false);
  useEffect(() => {
    if (offsetProp && projectOffset.id !== offsetProp.id) {
      if (company) {
        setLoadingOffset(true);
        stateDispatch({
          type: StateActionType.setProjectOffset,
          payload: offsetProp,
        });
        loadEmissions({
          variables: {
            companyId: company?.id,
            startYYYYMM: parseInt(format(offsetProp.startDate, 'yyyyMM'), 10),
            endYYYYMM: parseInt(format(offsetProp.endDate, 'yyyyMM'), 10),
          },
        })
          .then(() => {
            stateDispatch({
              type: StateActionType.setQuantityMT,
              payload: offsetProp.quantity,
            });
          })
          .finally(() => setLoadingOffset(false));
      }
    }
  }, [offsetProp?.id]);

  const error = hasExistingOffset || !!currentAllocation;
  const errorMessage =
    (error &&
      (hasExistingOffset
        ? 'These emissions are already part of a previous selection. You must change your selection to be different to what has already been added to the total.'
        : 'These emissions are already part of a pending Offset Opportunity. Additional offsets cannot be allocated until the pending offsets are applied.')) ||
    '';

  const filters = (
    <ButtonGroup
      variant="outlined"
      color={error ? 'inherit' : 'primary'}
      disabled={!hasProjects}
      classes={{
        root: error ? classes.error : undefined,
      }}>
      <SelectButton
        value={company?.id || ''}
        disabled={
          offsets.length > 1 || (offsets.length === 1 && !projectOffset.id)
        }
        onChange={({ target: { value } }) => {
          stateDispatch({
            type: StateActionType.reset,
          });
          setCompany(divisions?.find(({ id }) => id === value));
        }}>
        <MenuItem value="">Organization</MenuItem>
        {divisions?.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        )) || null}
      </SelectButton>
      <SelectButton
        value={
          (projectOffset.allocType !== undefined && projectOffset.allocType) ||
          ''
        }
        onChange={({ target: { value } }) =>
          stateDispatch({
            type: StateActionType.setType,
            payload: value as State['projectOffset']['allocType'],
          })
        }>
        <MenuItem value="">Category</MenuItem>
        {types &&
          Object.keys(types).map((type) => (
            <MenuItem key={type} value={type}>
              {types[type].allocTypeLabel}
            </MenuItem>
          ))}
      </SelectButton>
      <SelectButton
        value={
          (projectOffset.allocSubType !== undefined &&
            projectOffset.allocSubType) ||
          ''
        }
        onChange={({ target: { value } }) =>
          stateDispatch({
            type: StateActionType.setSubType,
            payload: value as State['projectOffset']['allocSubType'],
          })
        }
        disabled={!projectOffset.allocType || !types}>
        <MenuItem key="" value="">
          Sub-Category
        </MenuItem>
        {projectOffset.allocType &&
          types?.[projectOffset.allocType]?.subTypes &&
          Object.keys(types[projectOffset.allocType].subTypes).map(
            (allocSubType) => (
              <MenuItem key={allocSubType} value={allocSubType}>
                {(projectOffset.allocType &&
                  types[projectOffset.allocType].subTypes[allocSubType]) ||
                  ''}
              </MenuItem>
            ),
          )}
      </SelectButton>
    </ButtonGroup>
  );

  const dateRangeFilters = (
    <ButtonGroup
      variant="outlined"
      color={error ? 'inherit' : 'primary'}
      disabled={!hasProjects || !company}
      classes={{
        root: error ? classes.error : undefined,
      }}>
      <DatePickerButton
        value={projectOffset.startDate}
        onChange={(date) =>
          stateDispatch({
            type: StateActionType.setStartDate,
            payload: startOfMonth(date),
          })
        }
        format="MMM, yyyy"
        datePickerProps={{
          views: ['year', 'month'],
          maxDate: moment(min([projectOffset.endDate, lastMonth])),
          minDate: moment(
            company?.createdDate
              ? new Date(company.createdDate)
              : new Date('01/01/2020'),
          ),
        }}
      />
      <DatePickerButton
        value={projectOffset.endDate || new Date()}
        onChange={(date) =>
          stateDispatch({
            type: StateActionType.setEndDate,
            payload: endOfMonth(date),
          })
        }
        format="MMM, yyyy"
        datePickerProps={{
          views: ['year', 'month'],
          minDate: moment(projectOffset.startDate),
          maxDate: moment(lastMonth),
        }}
      />
    </ButtonGroup>
  );

  const chart = (
    <DataStateHandler loading={loadingEmissions || loadingOffset}>
      {((projectPurchaseOffset || totalEmissions) && (
        <>
          <Grid item>
            <PieChart data={data} tooltipFormat="integer" />
          </Grid>
          <Grid item>
            <Typography variant="h3">
              {(projectPurchaseOffset || 0) + (totalEmissions || 0)} MT C0₂E
            </Typography>
          </Grid>
          <Grid
            item
            direction="row"
            container
            style={
              data.length <= 2
                ? { marginTop: theme.spacing(4), height: 100 }
                : { margin: '24px 0px' }
            }>
            {data.map((d) => (
              <Grid
                container
                key={d.id}
                item
                xs={12}
                spacing={2}
                alignItems="center"
                justifyContent="space-between">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box
                        borderRadius="50%"
                        width={8}
                        height={8}
                        bgcolor={d.color}
                        mr={1}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" color="textPrimary">
                        {d.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textPrimary">
                    {d.value} MT C0₂E
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      )) || (
        <EmptyChart
          title="No data is available for your current filter selection"
          subTitle="Please adjust your filter selections to continue"
        />
      )}
    </DataStateHandler>
  );

  return (
    <Paper className={classes.fullHeight} style={{ padding: theme.spacing(2) }}>
      <DataStateHandler loading={!projectPurchases}>
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fullHeight}
          wrap="nowrap"
          style={{ position: 'relative' }}>
          <TooltipError open={error} placement="top" title={errorMessage}>
            <Grid item container justifyContent="space-between" wrap="nowrap">
              <Grid item>{filters}</Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item>{dateRangeFilters}</Grid>
            </Grid>
          </TooltipError>
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            spacing={2}
            className={classes.flexGrow}>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              xs={userHasEditAccess ? 8 : undefined}
              spacing={1}>
              {(company?.id &&
                projectOffset.startDate &&
                projectOffset.endDate &&
                projectOffset.allocType &&
                projectOffset.allocSubType &&
                chart) ||
                (!hasProjects && (
                  <EmptyChart
                    title="You cannot allocate member carbon emissions until you have an active project"
                    subTitle="Browse the Marketplace to purchase a project!"
                    button={
                      <SmallPaddedButton
                        onClick={() => history.push('/marketplace')}>
                        Browse Marketplace &gt;
                      </SmallPaddedButton>
                    }
                  />
                )) || (
                  <EmptyChart
                    title="Select the organization, category, and sub-category you would like to offset"
                    subTitle="Multiple filter combinations may be added for the same organization. However if you would like to offset more than one organization, you will have to do that in a separate flow."
                  />
                )}
            </Grid>
            {(userHasEditAccess && (
              <Grid item xs={6} className={classes.flexGrow}>
                <Box className={classes.offsetCalculator}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.fullHeight}>
                    <Grid item container justifyContent="center" spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          Offset Calculator
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Grid item>
                          <Typography variant="body2">
                            Calculate by percentage
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            value={quantityPct === 0 ? '' : quantityPct}
                            size="small"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            disabled={!totalEmissions}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                              inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              },
                            }}
                            onChange={({ target: { value } }) => {
                              stateDispatch({
                                type: StateActionType.setQuantityPercent,
                                payload: value as unknown as number,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <DividerWithText>
                          <Typography variant="body2">or</Typography>
                        </DividerWithText>
                      </Grid>
                      <Grid item container>
                        <Grid item>
                          <Typography variant="body2">
                            Calculate by Metric Ton
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            value={quantityMT === 0 ? '' : quantityMT}
                            size="small"
                            variant="outlined"
                            type="number"
                            disabled={!totalEmissions}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  MT
                                </InputAdornment>
                              ),
                            }}
                            onChange={({ target: { value } }) => {
                              stateDispatch({
                                type: StateActionType.setQuantityMT,
                                payload: parseInt(value, 10),
                              });
                            }}
                          />
                        </Grid>
                        {quantityMT < 1 ? (
                          <Grid item>
                            <Box mt={2}>
                              <Typography variant="body2">
                                A minimum of 1 MT is required to proceed.
                              </Typography>
                            </Box>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      wrap="nowrap"
                      spacing={1}>
                      <Grid item>
                        {(projectOffset.quantity && (
                          <Typography variant="h2" color="primary">
                            {projectOffset.quantity} MT C0₂E
                          </Typography>
                        )) ||
                          null}
                      </Grid>
                      <Grid item xs={12} className={classes.fullWidth}>
                        <SmallPaddedButton
                          onClick={onAddOffset}
                          disabled={
                            !state.valid || !company || loadingOffset || error
                          }
                          fullWidth>
                          {(!!projectOffset.id &&
                            ((!projectOffset?.quantity &&
                              'Remove Opportunity') ||
                              'Update Opportunity')) ||
                            'Add Opportunity'}
                        </SmallPaddedButton>
                      </Grid>
                      {projectOffset.id && (
                        <Grid item>
                          <Button variant="text" onClick={reset}>
                            Cancel
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )) ||
              null}
          </Grid>
        </Grid>
      </DataStateHandler>
    </Paper>
  );
};
export default EmployeeCarbonEmissionsChart;
