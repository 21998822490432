import { gql, TypedDocumentNode } from '@apollo/client';
import { UserHasAccessFn } from '../../contexts/AuthorizationContext';
import {
  Company,
  CompanyActionItem,
  CompanyContactType,
  CompanyGoal,
  CompanyType,
} from '../../types/company/types';
import { AbilityType, User } from '../../types/user/types';
import {
  COMPANY_IMAGE_URIS,
  COMPANY_LOGO_URIS,
  USER_PROFILE_IMAGE_URIS,
} from '../shared';
import { USER_FIELDS } from '../user/user';

export const COMPANY_BUDGET_SETTINGS_FIELDS = `
  fiscalYearStartDate
  fiscalYears {
    fiscalYear
    startDate
    endDate
    planPeriodType
    periods {
      label
      amount
    }
  }
`;

export const COMPANY_GOALS_FIELDS = `
  id
  createdByCompanyId
  title
  categoryIds
  companyIds
  quarter
  completeByDate
  description
  actions {
    complete
    title
  }
  habitIds
`;

export const COMPANY_SUBSCRIPTION_FIELDS = `
id
startDate
endDate
licenseFee
allotmentCreditsPerUser
notes
isParentSubscription
additionalAllotments {
  id
  effectiveDate
  amount
  isPublished
}
`;

export type CompanyFieldOptions = {
  includePlatformConfig: boolean;
  includeScore: boolean;
  includeEmployeeMetrics: boolean;
  includeSubscriptionConfig: boolean;
};

export const generateCompanyFields = (options: CompanyFieldOptions) => `
  id
  createdDate
  name
  address
  latitude
  longitude
  place
  region
  postalCode
  country
  phoneNumber
  primaryEmail
  policyId
  parentCompanyId
  parentCompanyName
  companyTypeId 
  redeemedCredits
  parentCompanyLogoUris {
    thumb
  }
  ${COMPANY_LOGO_URIS}
  logoBackgroundColor
  ${COMPANY_IMAGE_URIS}
  targetMt
  targetYear
  categoryIds
  technicalContactName
  technicalContactEmail
  technicalContactPhoneNumber
  clientSecretExpirationDate
  welcomeEmailAdditionalText
  features {
    id
    name
    description
    isEnabled
  }
  isDemoCompany
  currentFiscalYearDateRange {
    startDate
    endDate
  }
  fiscalYearDateRanges {
    fiscalYear
    startDate
    endDate
  }
  ${
    options.includeEmployeeMetrics
      ? `currentEmployeeMetrics {
    totalEmployeeCount
    activeEmployeeCount
    enabledEmployeeCount
  }`
      : ''
  }
  ${
    options.includeScore
      ? `score
  scoreUpdatedDate`
      : ''
  }
  ${
    options.includePlatformConfig
      ? `budgetSettings {
    ${COMPANY_BUDGET_SETTINGS_FIELDS}
  }
  contacts {
    type
    users {
      id
      name
      email
      ${USER_PROFILE_IMAGE_URIS}
      companyId
    }
  }
  `
      : ''
  }
  ${
    options.includeSubscriptionConfig
      ? `
  subscriptionData {
    id
    isParentSubscriptionData
    creditBalance
    hasActiveSubscription
    subscriptionHistory {
      ${COMPANY_SUBSCRIPTION_FIELDS}
    }
    currentSubscription {
      ${COMPANY_SUBSCRIPTION_FIELDS}
    }
    creditHistory {
      type
      amount
      invoicedAmount
      date
      id
      subscriptionId
      notes
    }
  }
  `
      : ''
  }
`;

const userHasAnyViewAccess = (
  abilities: AbilityType[],
  userHasAccessFn?: UserHasAccessFn,
) => {
  if (!userHasAccessFn) {
    return true;
  }

  return abilities.some((x) => userHasAccessFn(x, 'VIEW'));
};

export const generateCompanyQuery = (userHasAccessFn?: UserHasAccessFn) => gql`
  query company($id: String!) {
    company(id: $id) {
      ${generateCompanyFields({
        includePlatformConfig:
          userHasAccessFn?.('Configuration.Platform', 'VIEW') ?? true,
        includeSubscriptionConfig:
          userHasAccessFn?.('ZeroMe.SubscriptionManagement', 'VIEW') ?? true,
        includeScore: userHasAnyViewAccess(
          ['Client.Impact', 'ZeroMe.TraderDashboard'],
          userHasAccessFn,
        ),
        includeEmployeeMetrics:
          userHasAccessFn?.('ZeroMe.Clients', 'VIEW') ?? true,
      })}
    }
  }
`;

export type AllCompaniesQueryOption = {
  includePurchaseStats: boolean;
  includeCurrentBudget: boolean;
  includeEmployeeMetrics: boolean;
};

export const generateAllCompaniesQuery = (
  userHasAccessFn?: UserHasAccessFn,
  {
    includePurchaseStats,
    includeCurrentBudget,
    includeEmployeeMetrics,
  }: Partial<AllCompaniesQueryOption> = {
    includeCurrentBudget: false,
    includeEmployeeMetrics: false,
    includePurchaseStats: false,
  },
) =>
  gql`
    query {
      allCompanies {
        id
        name
        parentCompanyId
        ${COMPANY_LOGO_URIS}
        address
        place
        region
        postalCode
        country
        phoneNumber
        categoryIds
        isDemoCompany
        ${
          (includeCurrentBudget &&
            (userHasAccessFn?.('ZeroMe.Clients', 'VIEW') ?? true) &&
            `currentBudget {
          total
        }`) ||
          ''
        }
        ${COMPANY_IMAGE_URIS}
        createdDate
        ${
          (includeEmployeeMetrics &&
            (userHasAccessFn?.('ZeroMe.Clients', 'VIEW') ?? true) &&
            `currentEmployeeMetrics {
          enabledEmployeeCount
        }`) ||
          ''
        }
        ${
          (includePurchaseStats &&
            (userHasAccessFn?.('ZeroMe.Marketplace', 'VIEW') ?? true) &&
            `purchaseStats {
          activeProjects
        }`) ||
          ''
        }
      }
    }
  ` as TypedDocumentNode<{ allCompanies: Company[] }>;

export const COMPANY_DISPLAY_INFO_QUERY = gql`
  query companyDisplayInfo($id: String!) {
    company(id: $id) {
      id
      name
      ${COMPANY_LOGO_URIS}
      logoBackgroundColor
      policyId
      parentCompanyId
    }
  }
`;

export const GET_COMPANY_STOCK_IMAGES = gql`
  query {
    companyStockImages
  }
`;

export const GET_COMPANY_GOALS = gql`
  query GetCompanyGoals($id: String!, $ignoreCache: Boolean) {
    company(id: $id) {
      id
      goals(ignoreCache: $ignoreCache) {
        ${COMPANY_GOALS_FIELDS}
      }
    }
  }
` as TypedDocumentNode<
  { company: Pick<Company, 'id'> & { goals: CompanyGoal[] } },
  Pick<Company, 'id'>
>;

export const GET_COMPANY_TYPE = gql`
  query companyType($companyId: String!) {
    companyType(companyId: $companyId) {
      id
      isDefault
      label
      userTypes {
        id
        isDefault
        label
      }
    }
  }
` as TypedDocumentNode<{ companyType: CompanyType }, { companyId: string }>;

export const GENERATE_DEFAULT_FISCAL_PERIODS = gql`
  query (
    $fiscalYearStartDate: ISO8601MonthDate!
    $fiscalYear: Int!
    $planPeriodType: PlanPeriodType!
  ) {
    generateDefaultFiscalPeriods(
      fiscalYearStartDate: $fiscalYearStartDate
      fiscalYear: $fiscalYear
      planPeriodType: $planPeriodType
    ) {
      label
      amount
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query companyContacts($id: String!) {
    company(id: $id) {
      id
      contacts {
        type
        users {
          id
          name
          email
          ${USER_PROFILE_IMAGE_URIS}
          companyId
        }
      }
    }
  }
` as TypedDocumentNode<
  {
    company: Pick<Company, 'id'> & {
      contacts?: {
        type: CompanyContactType;
        users: User[];
      }[];
    };
  },
  Pick<Company, 'id'>
>;

export const GET_COMPANY_ACTIONS_COUNT = gql`
  query companyActionsCount {
    companyActionsCount
  }
`;

export const GET_COMPANY_ACTIONS = gql`
query companyActions {
  companyActions {
    type
    date
    data {
      company {
        id
        name
        ${COMPANY_LOGO_URIS}
      }
      user {
        ${USER_FIELDS}
      }
      dataRequest {
        requestedDate
        type
        fulfilledDate
      }
    }
  }
}
` as TypedDocumentNode<{
  companyActions: CompanyActionItem[];
}>;

export const GET_COMPANY_GOAL_CATEGORIES = gql`
  query companyGoalCategories {
    companyGoalCategories {
      id
      name
    }
  }
` as TypedDocumentNode<{
  companyGoalCategories: { id: string; name: string }[];
}>;

export const GET_PLATFORM_FEATURES = gql`
  query getPlatformFeatures {
    platformFeatures {
      id
      description
      name
    }
  }
` as TypedDocumentNode<{
  platformFeatures: { id: string; description?: string; name: string }[];
}>;

export const GET_COMPANY_TYPES = gql`
  query {
    companyTypes {
      id
      label
    }
  }
`;

export const GET_SUBSCRIPTION_DATA = gql`
  query getSubscriptionData($id: String!) {
    company(id: $id) {
      id
      subscriptionData {
        id
        hasActiveSubscription
        creditBalance
      }
    }
  }
` as TypedDocumentNode<
  {
    company: {
      subscriptionData: {
        id: string;
        hasActiveSubscription: boolean;
        creditBalance: number;
      };
    };
  },
  { id: string }
>;
