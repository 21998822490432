export default class TextUtils {
  static clampText(
    string: string,
    maxLength: number,
    trailingChars = '...',
  ): string {
    if (string.length > maxLength) {
      // https://stackoverflow.com/questions/5454235/shorten-string-without-cutting-words-in-javascript
      const regex = new RegExp(`^(.{${maxLength}}[^\\s]*).*`);
      return `${string.replace(regex, '$1')}${trailingChars ? '...' : ''}`;
    }

    return string;
  }
}
