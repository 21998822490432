import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ProfileQuestionCard } from '../../../types/question/types';
import ProfileQuestionInput from './ProfileQuestionInput';
import { ProcessingIndicator } from './ProcessingIndicator';
import { Colors } from '../../../theme/shared';
import {
  QuestionnaireActionTypes,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';

export type ProfileQuestionProps = {
  profileCard: ProfileQuestionCard;
};

const ProfileQuestion = ({ profileCard }: ProfileQuestionProps) => {
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      style={{ position: 'relative' }}>
      <Grid item container flexDirection="row" justifyContent="center">
        <Grid item>
          <img
            src={profileCard.questionTreeCategory.altIconUrl}
            style={{ height: 240, width: 240, objectFit: 'contain' }}
            alt={profileCard.questionTreeCategory.title}
          />
        </Grid>
        {!!state.carouselIndex && (
          <Box style={{ position: 'absolute', top: 12, left: 12 }}>
            <Button
              style={{ color: Colors.TertiaryText }}
              onClick={() =>
                dispatch({ type: QuestionnaireActionTypes.GO_BACK })
              }>
              Back
            </Button>
          </Box>
        )}
        <Box style={{ position: 'absolute', top: 24, right: 24 }}>
          <ProcessingIndicator questionId={profileCard.question.questionId} />
        </Box>
      </Grid>
      <Grid item>
        <Typography
          variant="h2"
          style={{
            textAlign: 'center',
            fontWeight: 500,
            paddingLeft: 24,
            paddingRight: 24,
          }}>
          {profileCard.question?.questionText}
        </Typography>
      </Grid>
      <Grid item p={3} style={{ maxWidth: 500, width: '100%' }}>
        <ProfileQuestionInput profileCard={profileCard} />
      </Grid>
    </Grid>
  );
};

export default ProfileQuestion;
