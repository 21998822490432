import i18next from 'i18next';

export type NumberFormat =
  | 'currency'
  | 'currency2'
  | 'percent'
  | 'wholePercent'
  | 'number'
  | /** Use only when displaying extremely small values */ 'precisionNumber'
  | 'integer';
export default class NumberUtils {
  static phoneNumberFormat(value: string) {
    const x =
      value && value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!x) {
      return '';
    }
    return !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  }

  static format(v: number | undefined | null, format: NumberFormat) {
    let formatted = '';
    const value = v === undefined || v === null ? 0 : v;
    switch (format) {
      case 'currency':
      case 'currency2':
        formatted = i18next.t('currency', {
          value,
          maximumFractionDigits: format === 'currency' ? 0 : 2,
          minimumFractionDigits: format === 'currency' ? 0 : 2,
        });
        break;
      case 'percent':
        formatted = i18next.t('number', {
          value,
          maximumFractionDigits: 2,
          style: 'percent',
        });
        break;
      case 'wholePercent':
        formatted = i18next.t('number', {
          value,
          maximumFractionDigits: 0,
          style: 'percent',
        });
        break;
      case 'number':
        formatted = i18next.t('number', {
          value,
          maximumFractionDigits: 2,
        });
        break;
      case 'precisionNumber':
        formatted = i18next.t('number', {
          value,
          maximumFractionDigits: 4,
        });
        break;
      case 'integer':
        formatted = i18next.t('number', {
          value,
          maximumFractionDigits: 0,
        });
        break;
      default:
        formatted = (value || 0).toString();
        break;
    }
    return formatted;
  }

  /**
   * Format a quantity to use either the ProjectPurchaseHistory uom (preferred) or the project's fallback uom.
   * @param quantity - Quantity to format.
   * @param uom - ProjectPurchaseHistory uom (preferred).
   * @param projectUom - Project's fallback uom.
   * @returns - Quantity formatted for display.
   */
  static formatQuantityAndUom = (
    quantity: number,
    uom?: string,
    projectUom?: string,
    separator = ' / ',
  ): string => {
    let display = `${NumberUtils.format(quantity || 0, 'integer')}`;
    if (uom) {
      display += `${separator || ''}${uom}`;
    } else if (projectUom) {
      display += `${separator || ''}${projectUom}`;
    }

    return display;
  };

  /**
   * Format a currency to use either the ProjectPurchaseHistory uom (preferred) or the project's fallback uom.
   * @param quantity - Currency to format.
   * @param uom - ProjectPurchaseHistory uom (preferred).
   * @param projectUom - Project's fallback uom.
   * @returns - Currency formatted for display.
   */
  static formatCurrencyAndUom = (
    quantity: number,
    uom?: string,
    projectUom?: string,
    separator = ' / ',
  ): string => {
    let display = `${NumberUtils.format(quantity || 0, 'currency2')}`;
    if (uom) {
      display += `${separator || ''}${uom}`;
    } else if (projectUom) {
      display += `${separator || ''}${projectUom}`;
    }

    return display;
  };
}
