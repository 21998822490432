/* eslint-disable jsx-a11y/media-has-caption */
import { useQuery } from '@apollo/client';
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { GET_DISCOVER_COMMUNICATION_DETAILS } from '../../../graphql/communications/memberCommunications';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationHeaderImage from './CommunicationHeaderImage';
import { MemberCommunicationListItem } from '../../communications/types';
import RelatedCommunications from './RelatedCommunications';

const useStyles = makeStyles(() => ({
  caption: {
    color: '#6B6B6B',
    margin: '24px 0px',
  },
  content: {
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  id: string;
};

const MemberCommunicationDetails: React.FC<Props> = ({ id }) => {
  const {
    data: { communication } = {},
    loading,
    error,
  } = useQuery(GET_DISCOVER_COMMUNICATION_DETAILS, {
    variables: {
      id,
    },
  });

  const classes = useStyles();
  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container spacing={2} direction="column">
        <CommunicationHeaderImage
          communication={communication as MemberCommunicationListItem}
          imageHeight={400}
        />
        <Typography
          variant="caption"
          className={classes.caption}
          style={{ margin: 0, marginBottom: '8px' }}>
          {communication?.author}
        </Typography>
        <Typography variant="h1">{communication?.title}</Typography>
        {communication?.readingTime ? (
          <Typography variant="caption" className={classes.caption}>
            {communication?.readingTime} MIN READ
          </Typography>
        ) : null}

        <Typography variant="body2" className={classes.content}>
          <Box
            component="span"
            dangerouslySetInnerHTML={{
              __html: communication?.content || '',
            }}
          />
        </Typography>

        {communication?.communicationType === 'VIDEO' &&
        communication.contentUploadUrl ? (
          <video controls width="100%">
            <source src={communication.contentUploadUrl} />
            This video format is not supported by your browswer.
          </video>
        ) : null}
        {communication?.communicationType === 'AUDIO' &&
        communication?.contentUploadUrl ? (
          <audio controls>
            <source src={communication.contentUploadUrl} />
            This audio format is not supported by your browser.
          </audio>
        ) : null}
        <Grid item xs={12}>
          <RelatedCommunications />
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default MemberCommunicationDetails;
