import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../assets/svg';
import SVG2 from '../../../assets/svg-v2';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexWrap: 'nowrap',
  },
  crust: {
    background: theme.palette.primary.main,
    padding: 16,
    borderRadius: '8px 0 0 8px',
    textAlign: 'center',
  },
  bang: {
    position: 'relative',
    top: '30%',
  },
  main: {
    background: theme.palette.primary.light,
    padding: 16,
    borderRadius: '0 8px 8px 0',
  },
  content: {
    marginRight: 16,
  },
}));

type Props = {
  iconUrl?: string;
  title: string;
  description?: string;
  onClose?: () => void;
};
const MemberAlert: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ iconUrl, title, description, onClose }, ref) => {
    const classes = useStyles();

    return (
      <div ref={ref}>
        <Grid container className={classes.root}>
          <Grid item xs={1} className={classes.crust}>
            <SVG2.Bang className={classes.bang} />
          </Grid>
          <Grid item xs={11} className={classes.main}>
            <Grid
              container
              justifyContent="space-between"
              style={{ flexWrap: 'nowrap' }}>
              <Grid item>
                <Grid
                  container
                  style={{ flexWrap: 'nowrap' }}
                  spacing={2}
                  alignItems="center"
                  className={classes.content}>
                  {!!iconUrl && (
                    <Grid item>
                      <img src={iconUrl} alt={title} />
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container direction="column">
                      <Typography variant="h3">{title}</Typography>
                      <Typography variant="body2">{description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {onClose && (
                <Grid item>
                  <IconButton onClick={onClose}>
                    <SVG.Close />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  },
);

export default MemberAlert;
