import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../../contexts/CompanySettingsContext';
import { CompanyCreditHistory } from '../../../types/company/types';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import SelectMenu from '../../common/SelectMenu/SelectMenu';
import NumberUtils from '../../../utils/numberUtils';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import AppTextField from '../../common/AppTextField';
import { UPSERT_CREDIT_HISTORY } from '../../../graphql/company/companyMutations';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

type CompanyCreditHistoryWithTotals = CompanyCreditHistory & {
  creditRemaining?: number;
  invoiceBalance?: number;
};

const schema = yup.object().shape({
  amount: yup.number().moreThan(0),
  notes: yup.string(),
});

const SubscriptionCreditHistory: React.FC = () => {
  const { company, loading, refetch, isInheritingSubscriptionData } =
    useContext(CompanySettingsContext);
  const { userHasAccess } = useContext(AuthorizationContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(
    company.subscriptionData?.currentSubscription?.id,
  );

  const [addCredits] = useMutation(UPSERT_CREDIT_HISTORY);

  const creditHistory: CompanyCreditHistoryWithTotals[] =
    company.subscriptionData?.creditHistory || [];

  const selectedHistory = creditHistory.filter(
    (x) => x.subscriptionId === subscriptionId,
  );

  for (let i = 0; i < selectedHistory.length; i += 1) {
    selectedHistory[i].creditRemaining =
      (selectedHistory[i - 1]?.creditRemaining || 0) +
      selectedHistory[i].amount;
    selectedHistory[i].invoiceBalance =
      (selectedHistory[i - 1]?.invoiceBalance || 0) +
      (selectedHistory[i].invoicedAmount || 0);
  }

  const style = { zIndex: 0 };

  const tableStructure: GroupableTableStructure<CompanyCreditHistoryWithTotals>[] =
    [
      {
        key: 'main',
        columns: [
          {
            key: 'date',
            display: 'Date',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Dates',
            style,
            render: (c) => (
              <Typography variant="body2">
                {formatDateStringUtc(c.date, 'MM/DD/YYYY')}
              </Typography>
            ),
          },
          {
            key: 'amount',
            display: 'Amount Credited',
            style,
            render: (c) => (
              <Typography variant="body2">
                {NumberUtils.format(c.amount, 'currency2')}
              </Typography>
            ),
          },
          {
            key: 'creditRemaining',
            display: 'Credit Remaining',
            style,
            render: (c) => (
              <Typography variant="body2">
                {NumberUtils.format(c.creditRemaining, 'currency2')}
              </Typography>
            ),
          },
          {
            key: 'invoicedAmount',
            display: 'Amount Invoiced',
            style,
            render: (c) => (
              <Typography variant="body2">
                {NumberUtils.format(c.invoicedAmount, 'currency2')}
              </Typography>
            ),
          },
          {
            key: 'invoiceBalance',
            display: 'Outstanding Invoice',
            style,
            render: (c) => (
              <Typography variant="body2">
                {NumberUtils.format(c.invoiceBalance, 'currency2')}
              </Typography>
            ),
          },
          {
            key: 'type',
            display: 'Type',
            style,
            render: (c) => (
              <Typography variant="body2">
                {
                  {
                    CREDIT: 'Credit',
                    PROJECT_PURCHASE: 'Purchase',
                    PROJECT_PURCHASE_ADJUSTMENT: 'Adjustment',
                    INVOICE_PAYMENT: 'Invoice Payment',
                  }[c.type]
                }
              </Typography>
            ),
          },
          ...(selectedHistory.some((x) => x.notes)
            ? [
                {
                  key: 'notes',
                  display: 'Notes',
                  style,
                  render: (c: CompanyCreditHistoryWithTotals) => (
                    <Typography variant="body2">{c.notes}</Typography>
                  ),
                },
              ]
            : []),
        ],
      },
    ];

  const { invoiceBalance } = selectedHistory?.[selectedHistory.length - 1] || 0;

  const initialValues = {
    amount: invoiceBalance,
    notes: '',
  };

  return (
    <DataStateHandler loading={loading}>
      <Card style={{ margin: 8 }}>
        <CardContent>
          <Grid container flexDirection="column">
            <Grid item style={{ textAlign: 'right' }} mb={1}>
              <SelectMenu
                id="subscriptionId"
                onChange={(evt) => {
                  setSubscriptionId(evt.target.value);
                }}
                inputStyle={{
                  height: 42,
                }}
                value={subscriptionId || ''}>
                {company?.subscriptionData?.subscriptionHistory.map((sub) => (
                  <MenuItem value={sub.id} key={sub.id}>
                    {`${
                      sub.id ===
                      company.subscriptionData?.currentSubscription?.id
                        ? 'Active Subscription: '
                        : ''
                    }${format(new Date(sub.startDate), 'M/d/yyyy')} - ${format(
                      new Date(sub.endDate),
                      'M/d/yyyy',
                    )}`}
                  </MenuItem>
                ))}
              </SelectMenu>
            </Grid>
            <Grid item sx={{ maxHeight: 400, overflowY: 'auto' }}>
              {!!selectedHistory.length && (
                <GroupableSelectTable<CompanyCreditHistory>
                  tableData={selectedHistory}
                  tableStructure={tableStructure}
                  hideStickyCol
                />
              )}
              {!selectedHistory.length && (
                <Typography variant="h1" style={{ textAlign: 'center' }}>
                  No data for subscription.
                </Typography>
              )}
            </Grid>
            {!!selectedHistory.length && (
              <Grid
                item
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mt={3}>
                <Grid item>
                  <Typography variant="body1">
                    Credit Remaining:{' '}
                    <strong>
                      {NumberUtils.format(
                        selectedHistory[selectedHistory.length - 1]
                          .creditRemaining,
                        'currency2',
                      )}
                    </strong>
                  </Typography>
                  {!!invoiceBalance && (
                    <Typography variant="body1">
                      Outstanding Invoice Balance:{' '}
                      <strong>
                        {NumberUtils.format(invoiceBalance, 'currency2')}
                      </strong>
                    </Typography>
                  )}
                </Grid>
                {!!invoiceBalance &&
                  userHasAccess('ZeroMe.SubscriptionManagement', 'EDIT') &&
                  !isInheritingSubscriptionData && (
                    <Grid item>
                      <SmallPaddedButton
                        variant="contained"
                        onClick={() => setModalOpen(true)}>
                        Record Invoice Payment
                      </SmallPaddedButton>
                    </Grid>
                  )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="md">
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
              if (values.amount) {
                await addCredits({
                  variables: {
                    companyId: company.id,
                    input: {
                      date: new Date().toISOString(),
                      amount: 0,
                      invoicedAmount: values.amount * -1,
                      type: 'INVOICE_PAYMENT',
                      subscriptionId,
                      notes: values.notes,
                    },
                  },
                });

                await refetch();

                setModalOpen(false);
              }
            }}>
            {(props) => (
              <fieldset style={{ border: 0 }}>
                <Grid container direction="column" width={600}>
                  <Typography variant="h3" mb={2}>
                    Add Invoice Payment
                  </Typography>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Amount</Typography>
                        <Field
                          name="amount"
                          label=""
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          component={AppTextField}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography variant="body1">Notes</Typography>
                    <Field
                      name="notes"
                      label=""
                      component={AppTextField}
                      disabled={props.isSubmitting}
                      placeholder=""
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          variant="contained"
                          disabled={props.isSubmitting}
                          onClick={props.submitForm}>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DataStateHandler>
  );
};

export default SubscriptionCreditHistory;
