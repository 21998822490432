import { gql, TypedDocumentNode } from '@apollo/client';
import { Reminder, ValidatedCart } from '../../types/user/types';
import { PROJECT_HEADER_IMAGE_URIS, USER_PROFILE_IMAGE_URIS } from '../shared';
import { QuestionTreeCategoryStatusDetail } from '../../types/question/types';

export const USER_FIELDS = `
  id
  companyId
  companyPolicyId
  name
  firstName
  lastName
  email
  postalCode
  country
  roles
  ${USER_PROFILE_IMAGE_URIS}
  isActive
  isEnrolling
  welcomeEmailSent
  dataRequestStatus
  dataRequests {
    requestedDate
    type
    fulfilledDate
  }
  userTypeId
  userTypeSetByUserEmissionSources
`;

export const USER_CART_FIELDS = `
  items {
    project {
      id
      title
      sellingPrice
      uom
      remainingQuantity
      ${PROJECT_HEADER_IMAGE_URIS}
    }
    quantity
  }
  validationData {
    project {
      id
      title
    }
    action
    from
    to
    uom
  }
`;

export const USERS_BY_COMPANY_ID_QUERY = gql`
  query (
    $companyId: String!
    $includeChildCompanyUsers: Boolean
    $ignoreMembers: Boolean
    $filter: UsersByCompanySearchFilterProps
    $continuationToken: String
    $ignoreCache: Boolean
  ) {
    usersByCompanyId(
      companyId: $companyId
      includeChildCompanyUsers: $includeChildCompanyUsers
      ignoreMembers: $ignoreMembers
      filter: $filter
      pageSize: 100
      continuationToken: $continuationToken
      ignoreCache: $ignoreCache
    ) {
      continuationToken
      items {
        ${USER_FIELDS}
      }
    }
  }
`;

export const USERS_BY_COMPANY_ID_PROPERTIES = gql`
  query (
    $companyId: String!
    $includeChildCompanyUsers: Boolean
    $ignoreMembers: Boolean
    $filter: UsersByCompanySearchFilterProps
    $property: String
    $searchText: String
  ) {
    properties: usersByCompanyIdProperties(
      companyId: $companyId
      includeChildCompanyUsers: $includeChildCompanyUsers
      ignoreMembers: $ignoreMembers
      filter: $filter
      property: $property
      searchText: $searchText
    )
  }
`;

export const GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL = gql`
  query GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL {
    me {
      id
      questionTreeCategoryStatusDetail {
        questionTreeCategory
        isComplete
        answered
        total
      }
    }
  }
` as TypedDocumentNode<{
  me: {
    questionTreeCategoryStatusDetail: QuestionTreeCategoryStatusDetail[];
  };
}>;

export const USER_DETAIL = gql`
  query (
    $userId: String!
    $companyId: String!
  ) {
    userDetail: userById(
      userId: $userId
      companyId: $companyId
    ) {
      ${USER_FIELDS}
      categoryIds
      createdDate
      lastActiveDate
      questionTreeCategoryStatusDetail {
        questionTreeCategory
        isComplete
      }
      habitCount
      totalHabitPoints
    }
    questionTreeCategories {
      id
      category
      title
    }
  }
`;

export const USER_BETTER_ME = gql`
  query (
    $userId: String!
    $companyId: String!
    $startDate: String!
    $endDate: String!
  ) {
    betterMe: userById(userId: $userId, companyId: $companyId) {
      id
      habitPointsByDatePeriod(
        dateGrain: MONTHLY
        startDate: $startDate
        endDate: $endDate
      ) {
        value
        date
        habits {
          totalDays
          totalPointsEarned
          habit {
            id
            name
          }
        }
      }
    }
  }
`;

export const USER_CURRENT_FOOTPRINT = gql`
  query (
    $userId: String!
    $companyId: String!
    $startYYYYMM: Int!
    $endYYYYMM: Int!
  ) {
    userEmissions(
      userId: $userId
      startYYYYMM: $startYYYYMM
      endYYYYMM: $endYYYYMM
    ) {
      totals {
        year
        month
        totalEmissions
        totalOffsets
        totalBehaviorCredits
      }
    }
    projectedEmissions(
      userId: $userId
      companyId: $companyId
      dateGrain: MONTHLY
    ) {
      currentFootprint
      categories {
        type
        emissions
        percentOfTotal
        hadErrors
        label
        color
        sortOrder
      }
    }
  }
`;

export const GET_PROJECTED_EMISSIONS = gql`
  query GET_PROJECTED_EMISSIONS(
    $userId: String!
    $companyId: String!
    $type: String
    $purposeType: String
  ) {
    projectedEmissions(
      userId: $userId
      companyId: $companyId
      dateGrain: YEARLY
      type: $type
      purposeType: $purposeType
    ) {
      currentFootprint
      habitReductions
      availableCredits
      offsetReductions
      categories {
        type
        subType
        emissions
        percentOfTotal
        label
        color
        sortOrder
      }
      purposeTypes {
        id
        label
      }
    }
  }
` as TypedDocumentNode<{
  projectedEmissions: {
    currentFootprint: number;
    habitReductions: number;
    availableCredits: number;
    offsetReductions: number;
    categories: {
      type: string;
      subType: string;
      emissions: number;
      percentOfTotal: number;
      label: string;
      color: string;
      sortOrder: number;
    }[];
    purposeTypes: {
      id: string;
      label: string;
    }[];
  };
}>;

export const USER_CART = gql`
  query {
    me {
      id
      cart {
        ${USER_CART_FIELDS}
      }
    }
  }
` as TypedDocumentNode<{ me: { cart: ValidatedCart } }>;

export const ACTIVE_USER_COUNT = gql`
  query ($companyId: String!) {
    activeUserCount(companyId: $companyId)
  }
` as TypedDocumentNode<{
  activeUserCount: number;
}>;

export const USER_HOMEPAGE_REMINDERS = gql`
  query {
    homepageReminders {
      type
      subType
      data
    }
  }
` as TypedDocumentNode<{
  homepageReminders: Reminder[];
}>;

export const USER_ABOUT_ME_REMINDERS = gql`
  query {
    aboutMeReminders {
      type
      subType
      data
    }
  }
` as TypedDocumentNode<{
  aboutMeReminders: Reminder[];
}>;
