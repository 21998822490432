import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { UPSERT_COMPANY_BUDGET_SETTINGS_MUTATION } from '../../graphql/company/companyMutations';
import {
  Company,
  BudgetSettings,
  MutationBudgetSettings,
} from '../../types/company/types';
import { generateCompanyQuery } from '../../graphql/company/company';
import SettingsPanel from './SettingsPanel';
import LoadingBackdrop from '../common/LoadingBackdrop';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import BudgetSettingsForm from '../common/BudgetSettingsForm';

const parseMmdd = (fieldValue: string) =>
  new Date(
    new Date().getFullYear(),
    parseInt(fieldValue.substring(0, 2), 10) - 1,
    parseInt(fieldValue.substring(3, 5), 10),
  );

export const currentFiscalYear = (fiscalYearStartDate: string) => {
  const fiscalYearDate = parseMmdd(fiscalYearStartDate);
  return fiscalYearDate > new Date()
    ? fiscalYearDate.getFullYear() - 1
    : fiscalYearDate.getFullYear();
};

const BudgetPanel: React.FC = () => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const { company } = useContext(CompanySettingsContext);

  const defaultStartDate =
    company.budgetSettings?.fiscalYearStartDate || '01-01';
  const defaultYear = currentFiscalYear(defaultStartDate);

  const initialValues: MutationBudgetSettings = company.budgetSettings || {
    fiscalYearStartDate: defaultStartDate,
    fiscalYears: [],
  };

  if (!initialValues.fiscalYears.find((x) => x.fiscalYear === defaultYear)) {
    initialValues.fiscalYears.push({
      fiscalYear: defaultYear,
      planPeriodType: '',
      periods: [],
    });
  }

  const [upsertBudgetSettingsMutation, { loading: saving }] = useMutation<
    { upsertBudgetSettings: BudgetSettings },
    {
      settings: MutationBudgetSettings;
      companyId: string;
    }
  >(UPSERT_COMPANY_BUDGET_SETTINGS_MUTATION);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedYear] = useState(defaultYear);

  const disabled = !userHasAccess('Configuration.Platform.Budget', 'EDIT');

  return (
    <>
      <LoadingBackdrop open={saving} />
      <SettingsPanel<MutationBudgetSettings>
        launchRow={{
          label: 'Project Spending',
          value: (
            company.budgetSettings?.fiscalYears
              .find((x) => x.fiscalYear === defaultYear)
              ?.periods.reduce((acc, cur) => acc + cur.amount, 0) || 0
          ).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        }}
        onClose={() => setSelectedYear(defaultYear)}
        disabled={disabled}
        title="Budget"
        formikProps={{
          initialValues,
          onSubmit: async (values) => {
            await upsertBudgetSettingsMutation({
              variables: {
                companyId: company.id,
                settings: {
                  fiscalYearStartDate: values.fiscalYearStartDate,
                  fiscalYears: values.fiscalYears.map((fy) => ({
                    planPeriodType: fy.planPeriodType,
                    fiscalYear: parseInt(fy.fiscalYear.toString(), 10),
                    periods: fy.periods.map((p) => ({
                      amount: parseInt(p.amount.toString(), 10),
                    })),
                  })),
                },
              },
              update: (cache, { data }) => {
                cache.updateQuery<{ company: Company }>(
                  {
                    query: generateCompanyQuery(),
                    variables: { id: company.id },
                  },
                  (cacheData) => ({
                    company: {
                      ...(cacheData?.company || {}),
                      budgetSettings: data?.upsertBudgetSettings,
                    } as Company,
                  }),
                );
              },
            });
          },
        }}>
        {() => (
          <BudgetSettingsForm defaultYear={defaultYear} disabled={disabled} />
        )}
      </SettingsPanel>
    </>
  );
};

export default BudgetPanel;
