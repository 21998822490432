import React, { useEffect } from 'react';

import Quill from 'quill';
import { useQuill } from 'react-quilljs';
import ImageCompress from 'quill-image-compress';

import 'quill/dist/quill.snow.css';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../../theme/shared';

const useStyles = makeStyles(() => ({
  root: {
    height: 350,
    borderRadius: 8,
    border: `2px solid ${Colors.CobaltBlue}`,
    marginTop: 16,
  },
  label: {
    fontSize: '0.85rem',
    position: 'relative',
    bottom: 12,
  },
  '@global': {
    '.ql-container.ql-snow, .ql-toolbar.ql-snow': {
      border: '0 !important',
    },
  },
}));

type Props = {
  initialValue?: string;
  labelText?: string;
  onChange: (newValue: string) => void;
};

const AppRichTextField: React.FC<Props> = ({
  initialValue,
  onChange,
  labelText,
}) => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],

        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
      imageCompress: {
        quality: 0.7,
        maxWidth: 1000,
        maxHeight: 1000,
        imageType: 'image/jpeg',
        // debug: true,
        // suppressErrorLogging: false,
      },
    },
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'align',
      'list',
      'indent',
      'header',
      'link',
      'color',
      'background',
      'clean',
      'image',
    ],
  });

  if (Quill && !quill) {
    Quill.register('modules/imageCompress', ImageCompress, true);
  }

  const classes = useStyles();
  useEffect(() => {
    if (quill && initialValue) {
      quill.clipboard.dangerouslyPasteHTML(initialValue);
    }
  }, [quill, initialValue]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        onChange(quill.root.innerHTML);
      });
    }
  }, [quill]);
  return (
    <Box className={classes.root}>
      <Box left="15px" bottom="13px" position="relative">
        <Typography className={classes.label} variant="caption">
          {labelText}
        </Typography>
      </Box>
      <div style={{ height: 260 }} ref={quillRef} />
    </Box>
  );
};

export default AppRichTextField;
