import { gql, TypedDocumentNode } from '@apollo/client';
import { CategoryInterest } from '../../types/dashboard/types';
import { ProjectCategory } from '../../types/project/types';
import { User } from '../../types/user/types';

export const GET_ALL_CATEGORIES = gql`
  query {
    projectCategories(language: "en") {
      id
      name
      label
      sortOrder
      iconUri
      backgroundImageUri
      description
      color
    }
  }
` as TypedDocumentNode<{ projectCategories: ProjectCategory[] }>;

export const GET_USER_PROJECT_CATEGORY_IDS = gql`
  query GET_USER_PROJECT_CATEGORY_IDS {
    me {
      id
      categoryIds
    }
  }
` as TypedDocumentNode<{
  me: Pick<User, 'id' | 'categoryIds'>;
}>;

export const UPDATE_USER_PROJECT_CATEGORIES = gql`
  mutation UPDATE_USER_PROJECT_CATEGORIES($categoryIds: [String]) {
    updateUserProfile(user: { categoryIds: $categoryIds }) {
      id
      categoryIds
    }
  }
` as TypedDocumentNode<
  {
    id: string;
    categoryIds: string[];
  },
  {
    categoryIds: string[];
  }
>;

export const GET_CATEGORY_STOCK_IMAGES = gql`
  query ($id: String!) {
    projectCategoryStockImages(id: $id)
  }
`;

export const GET_COMPANY_PROJECT_CATEGORY_INTEREST = gql`
  query ($companyId: String, $includeChildren: Boolean) {
    projectCategoryInterest(
      companyId: $companyId
      includeChildren: $includeChildren
    ) {
      id
      label
      color
      iconUri
      backgroundImageUri
      value
      percent
    }
  }
` as TypedDocumentNode<
  {
    projectCategoryInterest: CategoryInterest[];
  },
  {
    companyId: string;
  }
>;
