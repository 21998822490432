import { gql, TypedDocumentNode } from '@apollo/client';
import {
  MutationUser,
  User,
  UserMigrationCompanyAndPolicy,
  ValidatedCart,
} from '../../types/user/types';
import { USER_CART_FIELDS, USER_FIELDS } from './user';
import { AllotmentMetrics } from '../../types/project/types';

export const UPSERT_USER_MUTATION = gql`
  mutation upsertUser($user: UpsertUserInput!, $companyId: String!, $userId: String, $existingUserMigration: UserMigrationCompanyAndPolicy) {
    upsertUser(user: $user, companyId: $companyId, userId: $userId, existingUserMigration: $existingUserMigration) {
      ${USER_FIELDS}
    }
  }
` as TypedDocumentNode<
  { upsertUser: User },
  {
    user: Partial<MutationUser>;
    companyId: string;
    userId?: string;
    existingUserMigration?: UserMigrationCompanyAndPolicy;
  }
>;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($user: UpdateUserProfileInput!) {
    updateUserProfile(user: $user) {
      id
      portalAcceptedTermsDate
    }
  }
`;

export const UPLOAD_USER_CSV = gql`
  mutation uploadUserCsv(
    $upload: UploadUserCsvInput!
    $options: UploadUserCsvOptions
  ) {
    uploadUserCsv(upload: $upload, options: $options) {
      id
      result
      errorCsvUrl
    }
  }
`;

export const ACTIVATE_ENROLLING_USERS = gql`
  mutation activateEnrollingUsers($companyId: String!) {
    activateEnrollingUsers(companyId: $companyId)
  }
` as TypedDocumentNode<
  { activateEnrollingUsers: boolean },
  {
    companyId: string;
  }
>;

export const RESEND_WELCOME_EMAIL = gql`
  mutation resendWelcomeEmail($userId: String!, $companyId: String!) {
    resendWelcomeEmail(userId: $userId, companyId: $companyId)
  }
` as TypedDocumentNode<
  { resendWelcomeEmail: boolean },
  {
    userId: string;
    companyId: string;
  }
>;

export const UPDATE_CART = gql`
  mutation updateCart($input: UpdateCartInput!) {
    updateCart(input: $input) {
      ${USER_CART_FIELDS}
    }
  }
` as TypedDocumentNode<
  { updateCart: ValidatedCart },
  {
    input: {
      projectId: string;
      quantity: number;
      onExisting: 'UPDATE' | 'INCREMENT' | 'REMOVE';
    };
  }
>;

export const REQUEST_TO_PURCHASE_CART = gql`
  mutation requestToPurchaseCart {
    requestToPurchaseCart {
      ${USER_CART_FIELDS}
    }
  }
` as TypedDocumentNode<{ requestToPurchaseCart: ValidatedCart }>;

export const CLEAR_CART_VALIDATION_DATA = gql`
  mutation clearCartValidationData {
    clearCartValidationData
  }
` as TypedDocumentNode<{ clearCartValidationData: boolean }>;

export const USER_FULFILL_DATA_REQUEST = gql`
  mutation fulfillDataRequest($userId: String!, $companyId: String!, $contactType: ContactType!) {
    fulfillUserDataRequest(userId: $userId, companyId: $companyId, contactType: $contactType) {
      ${USER_FIELDS}
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation UPDATE_USER_AVATAR($avatar: AvatarInput) {
    updateUserProfile(user: { avatar: $avatar }) {
      id
      avatar {
        metadataId
        components {
          componentId
          optionId
          color
        }
      }
    }
  }
`;

export const UPDATE_USER_LIKED_PROJECTS = gql`
  mutation updateUserLikedProjects($likedProjectIds: [String]!) {
    updateUserProfile(user: { likedProjectIds: $likedProjectIds }) {
      id
      likedProjectIds
    }
  }
`;

export const SUBMIT_USER_CONTACT = gql`
  mutation SUBMIT_USER_CONTACT(
    $message: String
    $contactType: ContactType!
    $contactEmail: String
  ) {
    contact(
      contactType: $contactType
      message: $message
      contactEmail: $contactEmail
    ) {
      success
    }
  }
`;

export const REMOVE_USER_DATA_REQUEST = gql`
  mutation removeUserDataRequest(
    $contactType: ContactType!
    $userId: String!
    $companyId: String!
  ) {
    removeUserDataRequest(
      contactType: $contactType
      userId: $userId
      companyId: $companyId
    ) {
      id
      dataRequests {
        type
        fulfilledDate
        requestedDate
      }
    }
  }
`;

export const UPDATE_RESET_QUESTIONS = gql`
  mutation {
    updateUserProfile(
      user: {
        hasConfirmedLocation: false
        hasViewedIntroduction: false
        hasCompletedOnboardingInterests: false
        hasCompletedOnboardingQuestionnaire: false
        categoryIds: []
        userEmissionSources: []
      }
    ) {
      hasConfirmedLocation
      hasViewedIntroduction
      hasCompletedOnboardingQuestionnaire
      hasCompletedOnboardingInterests
      categoryIds
      userEmissionSources {
        profileId
      }
    }
  }
`;

export const REDEEM_CREDITS = gql`
  mutation ($projectId: String!, $amount: Int!) {
    redeemCredits(projectId: $projectId, amount: $amount)
  }
` as TypedDocumentNode<AllotmentMetrics, { projectId: string; amount: number }>;

export const SNOOZE_REMINDER = gql`
  mutation ($type: String!, $subType: String) {
    snoozeReminder(type: $type, subType: $subType)
  }
` as TypedDocumentNode<boolean, { type: string; subType?: string }>;
