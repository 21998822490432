import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Theme } from '@mui/material';

import Loading from './components/common/Loading';
import { AuthorizationContext } from './contexts/AuthorizationContext';
import SidenavProvider from './contexts/SidenavContext';
import Login from './pages/login/Login';
import IdleTimeout from './components/login/IdleTimeout';
import SupportChatProvider from './components/support/supportChat';
import TermsAndConditions from './components/user/TermsAndConditions';
import AdminRouter from './components/routing/AdminRouter';
import MemberRouter from './components/routing/MemberRouter';
import { LocalStorageKeys } from './types/common';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App(): JSX.Element | null {
  const history = useHistory();
  const { loading, hasAdminAccess, isAuthenticated, userHasAccess } =
    useContext(AuthorizationContext);

  if (loading) {
    return <Loading />;
  }

  const redirect = localStorage.getItem(LocalStorageKeys.redirectAfterLoginUrl);

  if (redirect) {
    setTimeout(() => {
      localStorage.removeItem(LocalStorageKeys.redirectAfterLoginUrl);
      history.push(redirect);
    }, 500);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SidenavProvider>
        {isAuthenticated && <TermsAndConditions />}
        <IdleTimeout />
        <SupportChatProvider>
          <>
            {!isAuthenticated && (
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Redirect to="/login" />
              </Switch>
            )}
            {isAuthenticated && (
              <Switch>
                {userHasAccess('Mobile', 'VIEW') && (
                  <Route path="/member">
                    <MemberRouter />
                  </Route>
                )}
                {hasAdminAccess && <AdminRouter />}
                <Redirect to="/member" />
              </Switch>
            )}
          </>
        </SupportChatProvider>
      </SidenavProvider>
    </LocalizationProvider>
  );
}

export default App;
