import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { WFHReminderCard } from './WFHReminderCard';
import { QuestionReminderCard } from './QuestionReminderCard';
import { PostalCodeChangedCard } from './PostalCodeChangedCard';
import { USER_HOMEPAGE_REMINDERS } from '../../../graphql/user/user';
import { SNOOZE_REMINDER } from '../../../graphql/user/userMutations';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';

export default function ReminderCards() {
  const {
    loading: remindersLoading,
    error: remindersError,
    data: { homepageReminders } = {},
    refetch,
  } = useQuery(USER_HOMEPAGE_REMINDERS, {
    notifyOnNetworkStatusChange: true,
  });

  const [coreSnooze, { loading: snoozeLoading, error: snoozeError }] =
    useMutation(SNOOZE_REMINDER);

  const reminder = homepageReminders?.[0];

  const snooze = async () => {
    if (reminder) {
      await coreSnooze({
        variables: {
          type: reminder.type,
          subType: reminder.subType,
        },
      });

      refetch();
    }
  };

  return (
    <DataStateHandler
      loading={remindersLoading || snoozeLoading}
      error={remindersError || snoozeError}>
      {(() => {
        if (reminder) {
          const reminderType = reminder.type;

          switch (reminderType) {
            case 'wfh':
              return (
                <WFHReminderCard
                  snooze={snooze}
                  hoursPerDay={reminder.data.hoursPerDay}
                  daysPerWeek={reminder.data.daysPerWeek}
                />
              );
            case 'questions':
              return <QuestionReminderCard snooze={snooze} />;
            case 'postalCodeChanged':
              return (
                <PostalCodeChangedCard snooze={snooze} refetch={refetch} />
              );
            default:
              throw new Error(`Unrecognized reminder type: ${reminderType}`);
          }
        }
        return null;
      })()}
    </DataStateHandler>
  );
}
