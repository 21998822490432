import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useMutation } from '@apollo/client';

import { addYears, differenceInYears, isAfter } from 'date-fns';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { CompanySubscription } from '../../types/company/types';

import AppTextField from '../common/AppTextField';
import AppDatePicker from '../common/AppDatePicker';
import {
  UPSERT_SUBSCRIPTION,
  UPSERT_CREDIT_HISTORY,
} from '../../graphql/company/companyMutations';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { formatDateStringUtc } from '../../utils/dateUtils';

const useStyles = makeStyles(() => ({
  durationButton: {
    borderRadius: 32,
    width: 130,
  },
}));

const schema = yup.object().shape({
  startDate: yup.date().required(),
  duration: yup.number().min(1).max(4),
  licenseFee: yup.number(),
  allotmentCreditsPerUser: yup.number(),
  marketplaceCredits: yup.number(),
  notes: yup.string(),
});

type Props = {
  subscription?: CompanySubscription;
  creditBalance?: number;
  open?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const CompanySubscriptionModal: React.FC<Props> = ({
  subscription,
  creditBalance,
  open,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const { company, refetch, isInheritingSubscriptionData } = useContext(
    CompanySettingsContext,
  );
  const [addSubscription, { loading, error }] =
    useMutation(UPSERT_SUBSCRIPTION);
  const [addCredits, { loading: creditsLoading, error: creditsError }] =
    useMutation(UPSERT_CREDIT_HISTORY);
  const { userHasAccess } = useContext(AuthorizationContext);
  const canEdit =
    userHasAccess('ZeroMe.SubscriptionManagement', 'EDIT') &&
    (!subscription || !isInheritingSubscriptionData);

  const disabled =
    (subscription && !isAfter(new Date(subscription.startDate), new Date())) ||
    !canEdit;

  const initialValues = subscription
    ? {
        ...subscription,
        duration: Math.min(
          differenceInYears(
            new Date(subscription.endDate),
            new Date(subscription.startDate),
          ),
          1,
        ),
        marketplaceCredits: creditBalance || 0,
      }
    : {
        startDate: new Date().toISOString(),
        endDate: addYears(new Date(), 1).toISOString(),
        duration: 1,
        allotmentCreditsPerUser: 1,
        licenseFee: 0,
        marketplaceCredits: 0,
        notes: '',
      };

  const handleSubmit = (
    values: Partial<CompanySubscription> & {
      marketplaceCredits: number;
      duration: number;
    },
  ) => {
    addSubscription({
      variables: {
        companyId: company.id,
        input: {
          id: values.id,
          startDate: values.startDate,
          endDate: values.endDate,
          licenseFee: values.licenseFee,
          notes: values.notes,
          allotmentCreditsPerUser: values.allotmentCreditsPerUser,
        },
      },
      onCompleted: async (data) => {
        if (values.marketplaceCredits > 0) {
          addCredits({
            variables: {
              companyId: company.id,
              input: {
                date: new Date().toISOString(),
                amount: values.marketplaceCredits - (creditBalance || 0),
                type: 'CREDIT',
                subscriptionId: data.upsertSubscription.id,
              },
            },
            onCompleted: async () => {
              await refetch();
              onSubmit();
            },
          });
        } else {
          await refetch();
          onSubmit();
        }
      },
    });
  };

  const code =
    error?.graphQLErrors[0]?.extensions?.code ||
    creditsError?.graphQLErrors[0]?.extensions?.code;

  const showFetchError = !code && (error || creditsError);

  return (
    <Dialog open={!!open} onClose={onCancel} maxWidth="md">
      <DialogContent>
        <DataStateHandler
          loading={loading || creditsLoading}
          error={showFetchError}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => handleSubmit(values)}>
            {(props) => (
              <fieldset disabled={disabled} style={{ border: 0 }}>
                <Grid container direction="column" width={600}>
                  <Typography variant="h3" mb={2}>
                    {subscription
                      ? `${!disabled ? 'Edit' : ''} Subscription Details`
                      : 'Create New Subscription'}
                  </Typography>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Start Date</Typography>
                        <Field
                          name="startDate"
                          label=""
                          onChange={(dt: string) => {
                            props.setFieldValue(
                              'endDate',
                              addYears(new Date(dt), props.values.duration),
                            );
                          }}
                          error={!!code}
                          helperText={code}
                          component={AppDatePicker}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">End Date</Typography>
                        <Typography variant="body1" mt={2}>
                          {formatDateStringUtc(
                            props.values.endDate,
                            'M/D/yyyy',
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Typography variant="body1">
                          Credits Per User
                        </Typography>
                        <Field
                          name="allotmentCreditsPerUser"
                          label=""
                          type="number"
                          component={AppTextField}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Typography variant="body1">
                          Subscription Duration
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          {[1, 2, 3, 4].map((n) => (
                            <Button
                              key={n}
                              className={classes.durationButton}
                              onClick={() => {
                                props.setFieldValue('duration', n);
                                props.setFieldValue(
                                  'endDate',
                                  addYears(
                                    new Date(props.values.startDate),
                                    n,
                                  ).toISOString(),
                                );
                              }}
                              variant={
                                props.values.duration === n
                                  ? 'contained'
                                  : 'text'
                              }>
                              {n} Year{n > 1 ? 's' : ''}
                            </Button>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">ZeroMe Fee</Typography>
                        <Field
                          name="licenseFee"
                          label=""
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          component={AppTextField}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          Marketplace Offset Credits
                        </Typography>
                        <Field
                          name="marketplaceCredits"
                          label=""
                          type="number"
                          component={AppTextField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography variant="body1">Notes</Typography>
                    <Field
                      name="notes"
                      label=""
                      component={AppTextField}
                      disabled={props.isSubmitting}
                      placeholder=""
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          disabled={disabled}
                          variant="contained"
                          onClick={props.submitForm}>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            )}
          </Formik>
        </DataStateHandler>
      </DialogContent>
    </Dialog>
  );
};

export default CompanySubscriptionModal;
