import React from 'react';
import { Box, ButtonBase, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { MemberHabit } from '../../../types/habit/types.js';
import HabitCategoryChip from '../../common/Habit/HabitCategoryChip';
import SVG from '../../../assets/svg-v2/index';
import { MemberHabitType } from '../../../pages/member/myhabits/MemberHabits';
import DefaultImage from '../../common/DefaultImage';

type Props = {
  habit: MemberHabit;
  imageHeight: number;
  onClick: (id: string) => void;
  onAction?: (id: string) => void;
  cardType: MemberHabitType | 'FEATURED' | 'RECENTLY_ADDED';
  disableAction?: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '16px 0px',
    borderRadiuus: 8,
  },
  image: {
    borderRadius: 8,
    width: '100%',
    objectFit: 'cover',
    marginBottom: 16,
  },
  author: {
    color: '#6B6B6B',
  },
  title: {
    marginBottom: 8,
    textAlign: 'left',
    width: '100%',
  },
}));

const MemberHabitCard: React.FC<Props> = ({
  habit,
  imageHeight,
  onClick,
  cardType,
  onAction,
  disableAction,
}) => {
  const classes = useStyles();

  let actions = null;
  switch (cardType) {
    case 'HABIT':
      actions = (
        <IconButton
          data-testid="member-habit-card-add"
          disabled={disableAction}
          onClick={onAction ? () => onAction(habit.id) : undefined}>
          <SVG.Plus />
        </IconButton>
      );
      break;
    case 'LIFESTYLE':
      actions = (
        <Grid container alignItems="center" justifyContent="right">
          <IconButton
            data-testid="member-habit-card-remove"
            disabled={disableAction}
            onClick={onAction ? () => onAction(habit.id) : undefined}>
            <SVG.Trash />
          </IconButton>
          <Box width={8} />
          <SVG.Check />
        </Grid>
      );
      break;
    case 'FEATURED':
    case 'RECENTLY_ADDED':
    default:
      break;
  }

  const img =
    imageHeight > 300 ? habit.imageUrls.large : habit.imageUrls.medium;

  return (
    <Grid container direction="column" className={classes.root}>
      <ButtonBase onClick={() => onClick(habit.id)}>
        {img ? (
          <img
            style={{ height: imageHeight }}
            className={classes.image}
            src={img}
            alt={habit.name}
          />
        ) : (
          <DefaultImage height={imageHeight} />
        )}
      </ButtonBase>

      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item xs={9}>
            <Grid container>
              <ButtonBase
                onClick={() => onClick(habit.id)}
                style={{ width: '100%' }}>
                <Typography variant="h3" className={classes.title}>
                  {habit.name}
                </Typography>
              </ButtonBase>

              <Grid item>
                <Box pr={1} component="span">
                  <HabitCategoryChip category={habit.category} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right' }}>
            <Box>{actions}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MemberHabitCard;
