import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import CheckFeature from '../../components/common/CheckFeature/CheckFeature';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import BudgetPanel from '../../components/settings/BudgetPanel';
import CompanyContacts from '../../components/settings/CompanyContacts';
import CompanyOffsetCategories from '../../components/settings/CompanyOffsetCategories';
import ReductionGoals from '../../components/settings/ReductionGoals';
import SettingsFieldRow from '../../components/settings/SettingsFieldRow';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import useSettingsStyles from './useSettingsStyles';
import { MTCO2E } from '../../utils/stringConstants';

const PlatformSettings: React.FC = () => {
  const { company, loading, divisions } = useContext(CompanySettingsContext);
  const settingsClasses = useSettingsStyles();

  const score = company?.score ? company.score / 1000 : 0;

  return (
    <DataStateHandler loading={loading}>
      <Grid container direction="column">
        <CheckFeature featureIds={['budget', 'marketplace']}>
          <Grid
            item
            container
            direction="row"
            className={settingsClasses.group}>
            <Grid item xs={3}>
              <div className={settingsClasses.leftCol}>
                <Typography variant="h3">Budget</Typography>
              </div>
            </Grid>
            <Grid item container direction="column" xs={9}>
              <BudgetPanel />
            </Grid>
          </Grid>
        </CheckFeature>

        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">User Types</Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <CompanyContacts contactType="HR" />
            <CompanyContacts contactType="ADMIN" />
            <CompanyContacts contactType="SUSTAINABILITY" />
          </Grid>
        </Grid>
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Reduction Plan</Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <SettingsFieldRow
              label="Current Score"
              value={
                score
                  ? `${new Intl.NumberFormat('en-us', {
                      maximumFractionDigits: 2,
                    }).format(score)} / ${MTCO2E}`
                  : 'N/A'
              }
            />
            {/* <ZeroMeTargetPanel score={score || 0} /> */}
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <ReductionGoals company={company} />
              </Grid>
              {divisions.map((d) => (
                <Grid item key={d.id}>
                  <ReductionGoals company={d} readonly />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Offset Categories</Typography>
              <Typography
                variant="body2"
                style={{ paddingTop: 8 }}
                color="textSecondary">
                Select the offset categories that you would like to be available
                in your organization&apos;s marketplace.
              </Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <CompanyOffsetCategories />
          </Grid>
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default PlatformSettings;
