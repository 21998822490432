import { Avatar } from '../common';
import { Project } from '../project/types';
import { UserEmissionSources } from '../question/types';

export type MutationUser = {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  postalCode: string;
  country: string;
  isActive: boolean;
  isEnrolling?: boolean;
  companyId: string;
  companyPolicyId: string;
  userTypeId: string;
  userTypeSetByUserEmissionSources?: boolean;
};

// Only populated if an existing employee is being moved to a new company
export type UserMigrationCompanyAndPolicy = {
  existingCompanyId: string;
  existingCompanyPolicyId: string;
  newCompanyId: string;
  newCompanyPolicyId: string;
};

export enum ContactType {
  DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST',
  EXPORT_DATA_REQUEST = 'EXPORT_DATA_REQUEST',
}

export type UserDataRequest = {
  type: ContactType;
  requestedDate: string;
  fulfilledDate?: string;
};

export type User = MutationUser & {
  id: string;
  name: string;
  roleConfig: Role;
  profileImageUris: Record<'medium', string>;
  userChatSettings?: UserChatSettings | null;
  welcomeEmailSent: string;
  dataRequestStatus?: string;
  dataRequests?: UserDataRequest[];
  portalAcceptedTermsDate?: string;
  categoryIds?: string[];
  lifestyleHabits?: {
    id: string;
  }[];
  avatar?: Avatar;
  place?: string;
  region?: string;
  userEmissionSources: UserEmissionSources[];
  hasCompletedOnboardingQuestionnaire?: boolean;
  hasConfirmedLocation?: boolean;
  postalCodeChanged?: boolean;
  likedProjectIds?: string[];
};

export type UserDetail = User & {
  createdDate?: string;
  lastActiveDate?: string;
  categoryIds?: string[];
  questionTreeCategoryStatusDetail: {
    questionTreeCategory: string;
    isComplete: boolean;
  }[];
  habitCount: number;
  totalHabitPoints: number;
};

/**
 * Settings for support chat (Intercom) integration.
 */
export type UserChatSettings = {
  hashIos?: string;
  hashAndroid?: string;
  hashWeb?: string;
};

export type OperationType = 'VIEW' | 'EDIT';

export type AbilityType =
  | 'Mobile'
  | 'Client.Impact'
  | 'Client.Engagement'
  | 'Client.Employees'
  | 'Client.Employees.Details'
  | 'Client.Marketplace'
  | 'Client.Marketplace.ContactTrader'
  | 'Client.Offset'
  | 'Client.Communication.Discover'
  | 'Client.Communication.Emails'
  | 'Client.Communication.PushNotifications'
  | 'Client.Communication.Settings'
  | 'Configuration.Company'
  | 'Configuration.Platform'
  | 'Configuration.Platform.Budget'
  | 'Api.AllowGlobalQueries'
  | 'Api.AllowParentQueries'
  | 'Api.AllowSetPrivateRoles'
  | 'ZeroMe.Dashboard.SelectAllCompanies'
  | 'ZeroMe.TraderDashboard'
  | 'ZeroMe.Marketplace'
  | 'ZeroMe.Clients'
  | 'ZeroMe.ClientActions'
  | 'ZeroMe.Communication.Discover'
  | 'ZeroMe.Communication.PushNotifications'
  | 'ZeroMe.Communication.Emails'
  | 'ZeroMe.Communication.Quotes'
  | 'ZeroMe.Communication.Settings'
  | 'ZeroMe.Employees.FulfillDataRequest'
  | 'ZeroMe.Habits'
  | 'ZeroMe.SubscriptionManagement'
  | 'ZeroMe.Unknown';

// Just a type guard
export function abilityType(at: AbilityType) {
  return at;
}

export type Ability = {
  name: AbilityType;
  description: string;
  private: boolean;
};

export type Role = {
  id: string;
  name: string;
  backgroundColor: string;
  fontColor: string;
  private: boolean;
  abilities: {
    name: AbilityType;
    operation: OperationType;
  }[];
};

export type RolesAndAbilities = {
  roles: Role[];
  abilities: Ability[];
};

export type ValidatedCart = {
  items: CartItem[];
  validationData: CartValidationData[];
};

export type CartValidationData = {
  project: Project;
  action: 'PRICE_CHANGED' | 'QUANTITY_CHANGED';
  from: number;
  to: number;
  uom?: string;
};

export type CartItem = {
  project: Project;
  quantity: number;
};

export type WFHReminder = BaseReminder & {
  type: 'wfh';
  data: {
    daysPerWeek: number;
    hoursPerDay: number;
  };
};

export type QuestionReminder = BaseReminder & {
  type: 'questions';
  data: {
    lastResponseDaysAgo: number;
  };
};

export type PostalCodeChangedReminder = BaseReminder & {
  type: 'postalCodeChanged';
};

export type BaseReminder = {
  subType?: string;
};

export type Reminder =
  | WFHReminder
  | QuestionReminder
  | PostalCodeChangedReminder;

export type ReminderType = Reminder['type'];
