import { gql, TypedDocumentNode } from '@apollo/client';
import { MemberHabit } from '../../types/habit/types.js';

const HABIT_FIELDS = `
id
name
description
tips {
  id
  text
}
questionText
impact
recurrenceSchedule
conversionType
conversionNagCount
conversionReanswerQuestionTreeCategory
conversionReanswerQuestionId
conversionConfirmationQuestion
conversionSuccessMessage
category {
    id
    label
    primaryColor
    secondaryColor
    iconUrl
    fillIconUrl
}
imageUrls {
  large
  medium
}
pointAmount
streakAmounts {
  pointAmount
  streakAmount
}
bannerType
bannerDescription
bannerValue
`;

export const MEMBER_HABIT_DETAILS = gql`
  query memberHabit($id: String!) {
    habit(id: $id, useTemplate: true) {
      ${HABIT_FIELDS}
    }
  }
` as TypedDocumentNode<{
  habit: MemberHabit;
}>;

export const MEMBER_HABITS = gql`
    query memberHabits($filter: HabitFilter) {
        habits(filter: $filter) {
            ${HABIT_FIELDS}
        }
    }
` as TypedDocumentNode<{
  habits: MemberHabit[];
}>;

export const MEMBER_LIFESTYLE_HABITS = gql`
query memberLifestyleHabits {
  me {
      id
      lifestyleHabits {
          ${HABIT_FIELDS}
      }
  }
}` as TypedDocumentNode<{
  me: {
    lifestyleHabits: MemberHabit[];
  };
}>;

export const ADD_HABIT = gql`
  mutation convertHabit($habitId: String!) {
    convertHabit(habitId: $habitId) {
      habit {
        id
      }
      messages {
        title
        description
        iconUrl
      }
    }
  }
`;

export const REMOVE_HABIT = gql`
  mutation removeHabit($habitId: String!) {
    removeHabit(habitId: $habitId) {
      id
    }
  }
`;

export default {
  MEMBER_HABITS,
};
