/* eslint-disable react/jsx-no-useless-fragment */
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import CommunicationsList from './CommunicationsList';
import CommunicationsNavigation from './CommunicationsNavigation';
import CommunicationsSettings from './CommunicationsSettings';
import EmailCommunicationsList from './EmailCommunicationsList';
import NotificationsList from './NotificationsList';
import QuotationsList from './QuotationsList';

export type CommunicationsParams = {
  contentType: string;
  companyId?: string;
};

const CommunicationsContainer: React.FC = () => {
  const { communicationsError, communicationsLoading } = useContext(
    CommunicationsContext,
  );
  const { contentType } = useParams<CommunicationsParams>();

  let Component = null;
  switch (contentType) {
    case 'discover':
      Component = <CommunicationsList />;
      break;
    case 'emails':
      Component = <EmailCommunicationsList />;
      break;
    case 'settings':
      Component = <CommunicationsSettings />;
      break;
    case 'quotations':
      Component = <QuotationsList />;
      break;
    case 'notifications':
      Component = <NotificationsList />;
      break;
    default:
      break;
  }
  return (
    <Box display="grid" gridTemplateColumns="150px auto" height="100%">
      <CommunicationsNavigation />
      <DataStateHandler
        loading={communicationsLoading}
        error={communicationsError}>
        {Component}
      </DataStateHandler>
    </Box>
  );
};

export default CommunicationsContainer;
