import { gql, TypedDocumentNode } from '@apollo/client';
import { CommunicationSettings } from '../../components/communications/types';
import { UserHasAccessFn } from '../../contexts/AuthorizationContext';

const COMMUNICATION_LIST_FIELDS = `
    items {
      id
      title
      createdDate
      updatedDate
      publishDate
      isFeatured
      viewsCount
      bookmarksCount
      communicationType
      companyIds
    }
`;

const COMMUNICATION_FIELDS = `
    id
    author
    title
    content
    headerImageUrl
    contentUploadUrl
    communicationType
    categoryIds
    isFeatured
    includeChildren
    publishDate
    createdDate
    updatedDate
`;

const EMAIL_FIELDS = `
    id
    title
    content
    companyIds
    includeChildren
    isGlobalCommunication
    publishDate
    createdDate
    updatedDate
`;

const QUOTATION_FIELDS = `
    id
    quote
    author
    status
`;

const NOTIFICATION_FIELDS = `
    id
    title
    content
    linkType
    linkId
    createdDate
    updatedDate
    publishDate
    sentDate
    includeChildren
    companyId
`;

export const generateCommunicationsQuery = (
  userHasAccessFn: UserHasAccessFn,
  isClient: boolean,
) => gql`
  query communications($includeGlobalCommunications: Boolean, $companyId: String, $ignoreCache: Boolean) {
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.Discover`,
        'VIEW',
      )
        ? `communications(options: { 
      types: [VIDEO, HINT, BLOG_POST, AUDIO], 
      includeGlobalCommunications: $includeGlobalCommunications, 
      companyId: $companyId,
      ignoreCache: $ignoreCache }) {
      ${COMMUNICATION_LIST_FIELDS}
    }`
        : ''
    }
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.Emails`,
        'VIEW',
      )
        ? `emailCommunications(options: {companyId: $companyId, ignoreCache: $ignoreCache}) {
      items {
        id
        title
        publishDate
        createdDate
        updatedDate
        openedPercent
        isGlobalCommunication
        companyIds
      }
    }`
        : ''
    }
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.PushNotifications`,
        'VIEW',
      )
        ? `notifications(options: {companyId: $companyId, ignoreCache: $ignoreCache}) {
      items {
        ${NOTIFICATION_FIELDS}
      }
    }`
        : ''
    }
    ${
      userHasAccessFn('ZeroMe.Communication.Quotes', 'VIEW')
        ? `
        quotations(options: {ignoreCache: $ignoreCache}) {
          ${QUOTATION_FIELDS}
        }
      `
        : ''
    }
  }
`;

export const EMAIL_COMMUNICATION_QUERY = gql`
query emailCommunication($id: String!) {
  emailCommunication(id: $id) {
    ${EMAIL_FIELDS}
  }
}
`;

export const UPSERT_EMAIL_COMMUNICATION = gql`
  mutation upsertEmailCommunication($input: EmailCommunicationInput!) {
    upsertEmailCommunication(emailCommunication: $input) {
      ${EMAIL_FIELDS}
    }
  }
`;

export const PREVIEW_EMAIL_COMMUNICATION = gql`
mutation previewEmail($id: String!) {
  previewEmailCommunication(id: $id) {
    ${EMAIL_FIELDS}
  }
}
`;

export const UPSERT_QUOTATION = gql`
  mutation upsertQuotation($input: QuotationInput!) {
    upsertQuotation(quotation: $input) {
      ${QUOTATION_FIELDS}
    }
  }
`;

export const UPSERT_NOTIFICATION = gql`
  mutation upsertNotification($input: NotificationInput!) {
    upsertNotification(notification: $input) {
      ${NOTIFICATION_FIELDS}
    }
  }
`;

export const COMMUNICATION_QUERY = gql`
  query communication($id: String!) {
    communication(id: $id) {
      ${COMMUNICATION_FIELDS}
    }
  }
`;

export const COMMUNICATION_CATEGORIES = gql`
  query communicationCategories {
    communicationCategories {
      id
      label
    }
  }
`;

export const UPSERT_COMMUNICATION = gql`
  mutation upsertCommunication($input: CommunicationInput!) {
    upsertCommunication(communication: $input) {
      ${COMMUNICATION_FIELDS}
    }
  }
`;

export const DELETE_COMMUNICATION = gql`
  mutation deleteCommunication($communicationId: String!) {
    deleteCommunication(communicationId: $communicationId)
  }
`;

export const COMPANY_LIST_QUERY = gql`
  query {
    allCompanies {
      id
      name
      parentCompanyId
    }
  }
`;

export const GET_COMMUNICATION_SETTINGS = gql`
  query getCommunicationSettings($companyId: String) {
    getCommunicationSettings(companyId: $companyId) {
      welcomeVideoId
    }
  }
` as TypedDocumentNode<{ getCommunicationSettings: CommunicationSettings }>;

export const UPSERT_COMMUNICATION_SETTINGS = gql`
  mutation upsertCommunicationSettings(
    $settings: CommunicationSettingsInput!
    $companyId: String
  ) {
    upsertCommunicationSettings(settings: $settings, companyId: $companyId) {
      welcomeVideoId
    }
  }
` as TypedDocumentNode<
  { upsertCommunicationSettings: CommunicationSettings },
  { settings: CommunicationSettings; companyId?: string }
>;
