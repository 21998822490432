import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AuthorizedRoute from '../common/AuthorizedRoute';
import Loading from '../common/Loading';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import MemberDashboard from '../../pages/member/dashboard/MemberDashboard';
import MemberOnboarding from '../../pages/member/onboarding/MemberOnboarding';
import MemberProjects from '../../pages/member/projects/MemberProjects';
import MemberHabits from '../../pages/member/myhabits/MemberHabits';
import MemberDiscover from '../../pages/member/discover/MemberDiscover';
import MemberProfile from '../../pages/member/profile/MemberProfile';
import MemberDiscoverDetails from '../../pages/member/discover/MemberDiscoverDetails';
import MemberActivities from '../../pages/member/activities/MemberActivities';
import MemberHabitDetails from '../../pages/member/myhabits/MemberHabitDetails';
import MemberFAQs from '../../pages/member/profile/MemberFAQs';
import MemberContact from '../../pages/member/profile/MemberContact';
import MemberPrivacy from '../../pages/member/profile/MemberPrivacy';
import MemberTermsAndConditions from '../../pages/member/profile/MemberTermsAndConditions';
import AnswerQuestions from '../../pages/member/questionnaire/AnswerQuestions';
import MemberDataManagement from '../../pages/member/profile/MemberManageData';
import MyResponses from '../../pages/member/profile/MyResponses';
import MemberProjectDetails from '../../pages/member/projects/MemberProjectDetails';
import MemberDevelopment from '../../pages/member/profile/MemberDevelopment';

const MemberRouter = () => {
  const { loading, isOnboarding } = useContext(AuthorizationContext);

  if (loading) {
    return <Loading />;
  }

  if (isOnboarding) {
    return (
      <Switch>
        <AuthorizedRoute path="/member/onboarding">
          <MemberOnboarding />
        </AuthorizedRoute>
        <Redirect to="/member/onboarding" />
      </Switch>
    );
  }

  return (
    <Switch>
      <AuthorizedRoute path="/member/dashboard">
        <MemberDashboard />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/onboarding">
        <MemberOnboarding />
      </AuthorizedRoute>
      <AuthorizedRoute
        featureIds={['marketplace']}
        exact
        path="/member/projects">
        <MemberProjects />
      </AuthorizedRoute>
      <AuthorizedRoute featureIds={['marketplace']} path="/member/projects/:id">
        <MemberProjectDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/myhabits">
        <MemberHabits />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/myhabits/:id">
        <MemberHabitDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/discover">
        <MemberDiscover />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/discover/:id">
        <MemberDiscoverDetails />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/profile">
        <MemberProfile />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/faqs">
        <MemberFAQs />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/contact">
        <MemberContact />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/privacy">
        <MemberPrivacy />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/terms">
        <MemberTermsAndConditions />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/manage-data">
        <MemberDataManagement />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/development">
        <MemberDevelopment />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/activities">
        <MemberActivities />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/questionnaire/:id/:index?">
        <AnswerQuestions />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/responses/:id">
        <MyResponses />
      </AuthorizedRoute>
      <Redirect to="/member/dashboard" />
    </Switch>
  );
};

export default MemberRouter;
