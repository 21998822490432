import { FileAttachment, ImageSize } from '../../types/common';

export enum CommunicationType {
  BLOG_POST = 'BLOG_POST',
  VIDEO = 'VIDEO',
  HINT = 'HINT',
  AUDIO = 'AUDIO',
}

export type MemberCommunicationListItem = {
  id: string;
  title: string;
  author: string;
  communicationType: CommunicationType;
  isFeatured: boolean;
  contentUploadUrl?: string;
  headerImageUrls: Record<ImageSize, string>;
  headerImageBlurHash?: string;
  publishDate: string;
  createdDate: string;
  updatedDate: string;
};

export type Communication = {
  id: string;
  author: string;
  title: string;
  content?: string;
  headerImageUrl?: string;
  contentUploadUrl?: string;
  communicationType: CommunicationType;
  categoryIds: {
    id: string;
  }[];
  isFeatured: boolean;
  publishDate?: string;
  createdDate: string;
  updatedDate: string;
};

export type CommunicationInput = {
  id: string;
  author: string;
  title: string;
  content: string;
  headerImage: FileAttachment;
  contentUpload: FileAttachment;
  contentUploadUrl?: string;
  communicationType: CommunicationType;
  categoryIds: string[];
  isFeatured: boolean;
  publishDate: string;
  // this doesn't get sent up to the server, it is
  // there to manage unpublished state for the UI
  hasBeenUnpublished: boolean;
  includeChildren?: boolean;
  headerImageUrl?: string;
};

export type CommunicationListItem = {
  id: string;
  title: string;
  createdDate: string;
  updatedDate: string;
  publishDate: string;
  viewsCount: number;
  bookmarksCount: number;
  isFeatured: boolean;
  communicationType: CommunicationType;
  // these are only to assist in filtering, not part of the actual nodel
  status?: 'Draft' | 'Published';
  filterableIsFeatured?: string;
};

export type Quotation = {
  id: string;
  quote: string;
  author: string;
  status: 'PUBLISHED' | 'DRAFT';
};

export type EmailCommunicationListItem = {
  id: string;
  title: string;
  createdDate: string;
  updatedDate: string;
  publishDate?: string;
  openedPercent?: number;
};

export type CommunicationCategory = {
  id: string;
  label: string;
  description?: string;
};

export type CommunicationSettings = {
  welcomeVideoId?: string;
};

export type Notification = {
  id: string;
  title: string;
  content?: string;
  linkType: 'NONE' | 'COMMUNICATION';
  linkId?: string;
  publishDate?: string;
  sentDate?: string;
  createdDate: string;
  updatedDate: string;
};
