import { Grid, InputAdornment, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import _ from 'lodash';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../../contexts/ProjectContext';
import { Project } from '../../../../types/project/types';
import CreateProjectInput from '../CreateProjectInput';
import createProjectSchema from '../createProjectSchema';
import ResetProjectFormListener from '../ResetProjectFormListener';
import ProjectContextFieldValidator from './ProjectContextFieldValidator';
import { getSDVistaDisplay } from '../../../../utils/projectUtils';

const makeInitialValues = (project: Project) => ({
  isFeatured: !!project.isFeatured,
  isDemoProject: !!project.isDemoProject,
  isIndicative: !!project.isIndicative,
  sponsor: project.sponsor || '',
  startDate: project.startDate || '',
  endDate: project.endDate || '',
  purchasePrice: project.purchasePrice || '',
  costPerUnit: project.costPerUnit || '',
  availableQuantity: project.availableQuantity || '',
  source: project.source || '',
  projectId: project.projectId || '',
  vintage: project.vintage || '',
  indicator: project.indicator || '',
  sellingPrice: project.sellingPrice || '',
  auditor: project.auditor || '',
  sdVista: project.sdVista || '',
  projectHQ: project.projectHQ || '',
  uom: project.uom || '',
});

const CreateProjectAdditionalDetails: React.FC = () => {
  const {
    allCountries,
    project,
    projectAuditors,
    projectIndicators,
    projectSources,
    projectSponsors,
    projectUoms,
    setTypedProjectField,
  } = useContext(ProjectContext);

  /**
   * Render values dropdown list items for SD Vista.
   * @returns value/label array.
   */
  const renderSdVistaOptions = (): { value: string; label: string }[] => {
    const values = ['true', 'false', 'n/a'];
    return values.map((m) => ({
      value: m,
      label: getSDVistaDisplay(m) || '',
    }));
  };

  return (
    <Formik
      initialValues={makeInitialValues(project)}
      // formik requires an onSubmit handler but we don't actually
      // do any submitting here - just using formik to handle validation
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      validationSchema={createProjectSchema.additionalDetailsSchema}>
      {({ resetForm, setFieldValue }) => (
        <>
          <ProjectContextFieldValidator step={ProjectDetailsStep.details} />
          <ResetProjectFormListener
            resetForm={resetForm}
            makeInitialValues={makeInitialValues}
          />
          <Typography variant="h3">Project Details</Typography>
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2">Featured</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={project.isFeatured || false}
                      onChange={() =>
                        setTypedProjectField('isFeatured', !project.isFeatured)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2">Demo Project</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={project.isDemoProject || false}
                      onChange={() =>
                        setTypedProjectField(
                          'isDemoProject',
                          !project.isDemoProject,
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2">Indicative Offer</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={project.isIndicative || false}
                      onChange={() => {
                        setFieldValue('isIndicative', !project.isIndicative);
                        setTypedProjectField(
                          'isIndicative',
                          !project.isIndicative,
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="Unit of Measure (UOM)"
                  name="uom"
                  fieldType="Select"
                  options={[
                    ...projectUoms.map((x) => ({
                      value: x.id,
                      label: x.name,
                    })),
                  ]}
                  placeholder="Unit of Measure"
                />
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="Sponsor"
                  name="sponsor"
                  fieldType="Select"
                  options={[
                    { value: '', label: ' -- ' },
                    ...projectSponsors.map((x) => ({
                      value: x.id,
                      label: x.name,
                    })),
                  ]}
                  placeholder="Select if applicable"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Start Date"
                  fieldType="Date"
                  name="startDate"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="End Date"
                  fieldType="Date"
                  name="endDate"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label={`Purchase Price Per Unit ${
                    project.isIndicative ? '' : '*'
                  }`}
                  disabled={!project.isDraft}
                  fieldType="Text"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  name="costPerUnit"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label={`Available Quantity ${
                    project.isIndicative ? '' : '*'
                  }`}
                  disabled={!project.isDraft}
                  fieldType="Text"
                  type="number"
                  name="availableQuantity"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Select a Registry"
                  fieldType="Select"
                  name="source"
                  options={projectSources.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Project ID"
                  fieldType="Text"
                  name="projectId"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Vintage"
                  fieldType="Text"
                  name="vintage"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Qualification"
                  fieldType="Select"
                  name="indicator"
                  options={_.orderBy(
                    projectIndicators.map((x) => ({
                      value: x.id,
                      label: x.name,
                    })),
                    ['label'],
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label={`Retail Price ${project.isIndicative ? '' : '*'}`}
                  fieldType="Text"
                  type="number"
                  name="sellingPrice"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: 40 }}>
                <Typography variant="body1">
                  {project.uom ? `per ${project.uom}` : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="Verification Auditor"
                  fieldType="Select"
                  name="auditor"
                  options={projectAuditors.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  label="SD Vista"
                  fieldType="Select"
                  name="sdVista"
                  options={renderSdVistaOptions()}
                />
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="Project Headquarters Location"
                  fieldType="Select"
                  name="projectHQ"
                  options={allCountries.map((x) => ({
                    value: x.code,
                    label: `${x.code} - ${x.name}`,
                  }))}
                  placeholder="Country"
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CreateProjectAdditionalDetails;
