import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Project, ProjectCategory } from '../../../types/project/types';
import TextUtils from '../../../utils/textUtils';
import CategoryIconDiamond from '../Project/CategoryIconDiamond';
import ProjectDataPill from '../Project/ProjectDataPill';
import SVG from '../../../assets/svg';
import DefaultImage from '../DefaultImage';

type Props = {
  categories: ProjectCategory[];
  clickHandler: (arg: string) => void;
  project: Project;
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgb(241, 248, 253, 1)',
    width: 1340,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    height: 522,
    marginRight: 16,
  },
  image: {
    width: '100%',
    // scale up images that are too small
    height: 522,
    minWidth: 500,
    objectFit: 'cover',
    borderRadius: '0 8px 8px 0',
  },
  text: {
    margin: '24px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  indicator: {
    fontSize: 14,
    lineHeight: '14px',
    padding: '8px 0',
  },
  location: {
    color: '#0A3751',
    '& span': {
      paddingLeft: 8,
      position: 'relative',
      bottom: 4,
    },
  },
  button: {
    margin: '8px 0px',
    padding: ' 8px 16px',
  },
  leftContent: {
    padding: 24,
    height: '100%',
  },
  description: { flexGrow: 1 },
}));

const FeaturedProject: React.FC<Props> = ({
  categories,
  clickHandler,
  project,
}) => {
  const classes = useStyles();
  const category = categories.find((x) => x.id === project.category);

  return (
    <Grid key={project.id} container className={classes.root}>
      <Grid item xs={6}>
        <Grid container direction="column" className={classes.leftContent}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ flexWrap: 'nowrap' }}>
              <Grid item xs>
                <CategoryIconDiamond
                  size="small"
                  categoryId={project.category}
                />
              </Grid>
              <Grid item xs style={{ position: 'relative' }}>
                <ProjectDataPill
                  category={category as ProjectCategory}
                  project={project}
                  dataType={
                    project.isIndicative
                      ? 'INDICATIVE_OFFER'
                      : 'REMAINING_QUANTITY'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.indicator}>
              {project.indicator}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              style={{
                color: category?.color || 'black',
                paddingBottom: 8,
              }}>
              {TextUtils.clampText(project.title || '', 75)}
            </Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems="center">
              <Grid item>
                <Typography
                  component="p"
                  display="inline"
                  variant="body2"
                  className={classes.location}>
                  <SVG.Marketplace.Location />
                  <span>{project.projectHQ}</span>
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  marginBottom: 6,
                  paddingLeft: 8,
                  marginLeft: 8,
                  borderLeft: '1px solid #A5B7BF',
                }}>
                {project.isIndicative ? null : (
                  <Typography variant="h4">
                    {new Intl.NumberFormat('en-us', {
                      maximumFractionDigits: 2,
                      currency: 'USD',
                      style: 'currency',
                    }).format(project.sellingPrice || 0)}{' '}
                    / <small style={{ fontWeight: 600 }}>{project.uom}</small>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.description}>
            <Typography paragraph className={classes.text}>
              {TextUtils.clampText(project.description || '', 500)}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="small"
              onClick={() => clickHandler(project.id || '')}>
              View Project
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {project.headerImageUris?.large ? (
          <img
            src={project.headerImageUris?.large}
            alt="featured project"
            className={classes.image}
          />
        ) : (
          <DefaultImage height={522} />
        )}
      </Grid>
    </Grid>
  );
};

export default FeaturedProject;
