import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import MemberAlert from './MemberAlert';

type Props = {
  open?: boolean;
  snackbarContent: {
    iconUrl?: string;
    title: string;
    description?: string;
  };
};
const MemberSnackbar: React.FC<Props> = ({
  snackbarContent,
  open: initialOpen,
}) => {
  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={4000}>
      <MemberAlert
        iconUrl={snackbarContent.iconUrl}
        title={snackbarContent.title}
        description={snackbarContent.description}
        onClose={() => setOpen(false)}
      />
    </Snackbar>
  );
};

export default MemberSnackbar;
