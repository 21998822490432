import { useMutation, useQuery } from '@apollo/client';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SVG from '../../assets/svg';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import {
  GET_COMPANY_GOALS,
  GET_COMPANY_GOAL_CATEGORIES,
} from '../../graphql/company/company';
import { UPDATE_COMPANY_GOAL_ORDER_MUTATION } from '../../graphql/company/companyMutations';
import { ALL_HABITS } from '../../graphql/habit/habits';
import { Company, CompanyGoal } from '../../types/company/types';
import CompanyLogos from '../common/Company/CompanyLogos';
import CompanyNameWithLogo from '../common/Company/CompanyNameWithLogo';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import LoadingBackdrop from '../common/LoadingBackdrop';
import ReductionGoalsPanel from './ReductionGoalsPanel';

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 16px 16px',
  },
  goalRow: {
    padding: 16,
    borderRadius: 4,
    border: '1px solid #DADADA',
    cursor: 'pointer',
  },
  titleCell: {
    minHeight: 40,
    position: 'relative',
    top: -10,
    display: 'flex',
    marginBottom: -20,
  },
  dragHandle: {
    position: 'absolute',
    left: 0,
    padding: 10,
    color: '#6B6B6B',
  },
  habitIcon: {
    padding: 2,
    borderRadius: 4,
    marginRight: 8,
    height: 24,
    width: 24,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  chip: {
    marginBottom: 4,
  },
}));

const ReductionGoals = ({
  company,
  readonly,
}: {
  company: Company;
  readonly?: boolean;
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const [goal, setGoal] = useState<CompanyGoal>();
  const [updatedGoalOrder, setUpdatedGoalOrder] = useState<CompanyGoal[]>();

  const canEdit = !readonly && userHasAccess('Configuration.Platform', 'EDIT');

  const [updateGoalOrderMutation, { loading: updatingOrder }] = useMutation(
    UPDATE_COMPANY_GOAL_ORDER_MUTATION,
  );

  const { data: { company: { goals = [] } = {} } = {}, loading: loadingGoals } =
    useQuery(GET_COMPANY_GOALS, {
      variables: { id: company.id },
    });

  const {
    loading: categoriesLoading,
    data: { companyGoalCategories = [] } = {},
  } = useQuery(GET_COMPANY_GOAL_CATEGORIES);
  const { loading: loadingHabits, data: habitsData } = useQuery(ALL_HABITS);

  if (
    updatedGoalOrder &&
    updatedGoalOrder.every((x, i) => x.id === (goals || [])[i]?.id)
  ) {
    setUpdatedGoalOrder(undefined);
  }

  const habits = habitsData?.habits || [];

  return (
    <Paper className={classes.root}>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          style={{ marginBottom: 24 }}>
          <Grid item container spacing={1} xs={6}>
            <Grid item>
              <CompanyNameWithLogo company={company} />
            </Grid>
            <Grid item>
              <Typography variant="h3">Reduction Goals</Typography>
            </Grid>
          </Grid>
          <ReductionGoalsPanel
            habits={habits}
            goalCategories={companyGoalCategories}
            goal={goal}
            disabled={!canEdit}
            onClose={() => setGoal(undefined)}
            onCopy={(g) => {
              setGoal(undefined);

              setTimeout(
                () =>
                  setGoal({
                    ...g,
                    id: '',
                    title: `Copy of ${g.title}`,
                  }),
                400,
              );
            }}
          />
        </Grid>
        <LoadingBackdrop open={updatingOrder} />
        <DataStateHandler
          loading={loadingHabits || loadingGoals || categoriesLoading}>
          <DragDropContext
            onDragEnd={async ({ source, destination }) => {
              if (destination && source.index !== destination.index) {
                const result = Array.from(updatedGoalOrder || goals || []);
                const [removed] = result.splice(source.index, 1);
                result.splice(destination.index, 0, removed);
                setUpdatedGoalOrder(result);

                await updateGoalOrderMutation({
                  variables: {
                    companyId: company.id,
                    goalIds: result.map((x) => x.id),
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [
                    {
                      query: GET_COMPANY_GOALS,
                      variables: { id: company.id, ignoreCache: true },
                    },
                  ],
                });
              }
            }}>
            <Droppable droppableId="goals">
              {(provided) => (
                <Grid
                  item
                  container
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {(updatedGoalOrder || goals || []).map((x, i) => (
                    <Draggable
                      key={x.id}
                      isDragDisabled={!canEdit}
                      draggableId={x.id}
                      index={i}>
                      {(dragProvided) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}>
                          <div
                            style={{
                              position: 'relative',
                              paddingLeft: canEdit ? 40 : 0,
                            }}>
                            {canEdit && (
                              <span className={classes.dragHandle}>
                                <div {...dragProvided.dragHandleProps}>
                                  <SVG.DragHandle />
                                </div>
                              </span>
                            )}
                            <Grid
                              container
                              direction="column"
                              className={classes.goalRow}
                              onClick={() => setGoal(x)}>
                              <Grid item container direction="row">
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  xs>
                                  <Grid item className={classes.titleCell}>
                                    <Typography
                                      variant="body1"
                                      style={{ width: 50 }}>
                                      {i + 1}.
                                    </Typography>
                                    <Typography variant="body1">
                                      {x.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ width: 100 }}>
                                    <CompanyLogos companyIds={x.companyIds} />
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  container
                                  direction="row"
                                  justifyContent="space-between">
                                  <Grid item>
                                    <Grid container direction="column">
                                      {x.categoryIds?.map((id) => {
                                        const cat = companyGoalCategories.find(
                                          (c) => c.id === id,
                                        );
                                        return (
                                          <Typography
                                            variant="body2"
                                            className={classes.chip}
                                            key={cat?.id}>
                                            {cat?.name}
                                          </Typography>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    {moment(x.completeByDate).format(
                                      'M/D/YYYY',
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {!!x.habitIds?.length && (
                                <Grid item container direction="row">
                                  <Typography variant="body2" color="primary">
                                    {x.habitIds.length} supporting habit
                                    {x.habitIds.length > 1 ? 's' : ''}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </DataStateHandler>
      </Grid>
    </Paper>
  );
};

export default ReductionGoals;
