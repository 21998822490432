export type QuestionProfile = {
  id: string;
  questionTreeCategory: string;
  categoryOrder: number;
  title: string;
  hasOnboardingQuestions: boolean;
  categoryTipTitle: string;
  categoryLabel: string;
  profileTipTitle: string;
  questions: Question[];
  categoryIconUrl: string;
  categoryIconBoldUrl: string;
};

export enum QuestionTag {
  UseOfTransportation = 'useOfTransportation',
  ModesOfTransportation = 'modesOfTransportation',
  WfhDaysPerWeek = 'wfhDaysPerWeek',
  WfhHoursPerDay = 'wfhHoursPerDay',
}

export type QuestionOption = {
  answerId?: string;
  profileDocumentId?: string;
  unsure?: boolean;
  answerText: string;
  nextQuestionId?: string;
};

export type Question = {
  questionId: string;
  questionText: string;
  firstQuestion: boolean;
  isOnboarding: boolean;
  allowUnsure?: boolean;
  userInput: boolean;
  options?: QuestionOption[];
  tags?: QuestionTag[];
  userInputOptions?: QuestionUserInputOptions;
};

type QuestionUserInputOptionsParams = {
  name: string;
  value: string;
};

type QuestionUserInputOptions = {
  userEmissionsPropertyName: string;
  propertyTemplate: PropertyTemplate;
  propertyTemplateKey: PropertyTemplateKey;
  nextQuestionId?: string;
  parameters: QuestionUserInputOptionsParams[];
};

export type UserEmissionSources = {
  profileId: string;
  answeredQuestions: QuestionAnswer[];
  lastModified?: string;
};

export type QuestionAnswer = {
  questionId: string;
  unsure?: boolean;
  answerIds?: string[];
  readings?: Reading[];
  estimateSchedule?: EstimatedSchedule;
};

export type Reading = {
  key: PropertyTemplateKey;
  calcInput?: number;
  calcInputUnit?: string;
};

export type EstimatedSchedule = {
  period: ScheduleDateGrain;
  generateOn: string[];
};

export enum ScheduleDateGrain {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type PropertyTemplate =
  | 'scheduleDaysPerWeek'
  | 'readingsFloat'
  | 'readingsFloatWithZero'
  | 'readingsInt'
  | 'readingsIntSkippable'
  | 'readingsIntWithZero'
  | 'readingsPostalCode'
  | 'readingsPct';

export enum PropertyTemplateKey {
  people = 'people',
  distance = 'distance',
  occupants = 'occupants',
}

export type Tip = {
  id: string;
  text: string;
  entityType: string;
  categoryId: string;
  profileId: string;
  questionId: string;
};

export enum ProfileCardType {
  'Question',
  'Tip',
  'CategoryTip',
}

export type QuestionTreeCategory = {
  id: string;
  category: string;
  title: string;
  incompleteLabel: string;
  iconUrl: string;
  altIconUrl: string;
};

export type ProfileCard = ProfileQuestionCard | ProfileCardTip;

export type ProfileQuestionCard = {
  cardType: ProfileCardType.Question;
  profileId: string;
  questionTreeCategory: QuestionTreeCategory;
  question: Question;
};

export type ProfileCardTip = {
  cardType: ProfileCardType.Tip | ProfileCardType.CategoryTip;
  categoryTipTitle?: string;
  profileTipTitle?: string;
  iconLabel?: string;
  questionTreeCategory: QuestionTreeCategory;
  tips: Tip[];
};

export type ProfileQuestionInputBase = {
  profileCard: ProfileQuestionCard;
  forceDisabled?: boolean;
  inputOnly?: boolean;
};

export enum FilterOnboardingQuestionOptions {
  ONBOARDING_ONLY = 'ONBOARDING_ONLY',
  NO_ONBOARDING = 'NO_ONBOARDING',
}

export type QuestionTreeCategoryStatusDetail = {
  questionTreeCategory: string;
  isComplete: boolean;
  total: number;
  answered: number;
};
