import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import QuestionnaireButton from './QuestionnaireButton';
import NetEmissionsOverview from '../../member/dashboard/NetEmissionsOverview';
import theme from '../../../theme/memberTheme';

const OnboardingComplete: React.FC = () => {
  const history = useHistory();

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item>
        <NetEmissionsOverview
          subTitleStyle={{ color: theme.palette.text.secondary }}
        />
      </Grid>
      <Grid item pt={5}>
        <Typography variant="h1">Your Carbon Footprint</Typography>
      </Grid>
      <Grid item pt={3}>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          Congrats on completing your onboarding!
        </Typography>
      </Grid>
      <Grid item pt={3}>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          Do you have a smart thermostat, Energy Star applicances, or a second
          home? Answer additional questions about your lifestyle and see how it
          impacts your carbon footprint.
        </Typography>
      </Grid>
      <Grid
        item
        container
        flexDirection="row"
        pt={3}
        spacing={2}
        justifyContent="center">
        <Grid item>
          <QuestionnaireButton
            variant="contained"
            color="secondary"
            onClick={() => history.push('/member/profile')}>
            Answer additional questions
          </QuestionnaireButton>
        </Grid>
        <Grid item>
          <QuestionnaireButton
            variant="outlined"
            color="secondary"
            onClick={() => history.push('/member/dashboard')}>
            Skip for now
          </QuestionnaireButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardingComplete;
