const common = {
  currency: '{{value, currency(USD)}}',
  number: '{{value, number}}',
};

export default {
  en: {
    translation: {
      ...common,
      sidenav: {
        impact: 'Impact',
        engagement: 'Engagement',
        marketplace: 'Marketplace',
        employees: 'Members',
        settings: 'Settings',
        zmcommunications: 'ZM Communication',
        clientcommunications: 'Client Communication',
        trader: {
          dashboard: 'Offset Dashboard',
          clients: 'Clients',
          projects: 'Project Management',
        },
        offset: 'Offset Opportunity',
        habits: 'Habits',
      },
      logout: 'Logout',
      greeting: 'Hey, {{name}}!',
      fetchError: 'Unable to fetch results.',
      marketplace: {
        header: 'Marketplace',
        subheader: 'Let us help you find the perfect investment opportunity.',
        browse: 'Browse Available Projects',
      },
      trader: {
        projects: {
          header: 'Marketplace Inventory',
          createNewProject: '+ Create New Project',
        },
      },
    },
  },
  es: {
    translation: {
      ...common,
      sidenav: {
        impact: 'Impacto',
        engagement: 'Compromiso',
        marketplace: 'Merdcado',
        employees: 'Mensajería',
        settings: 'Ajustes',
        trader: {
          dashboard: 'Trader Dashboard',
          clients: 'Clientes',
          projects: 'Proyectos',
        },
      },
      logout: 'Cerrar Sesión',
      greeting: 'Hola, {{name}}!',
      fetchError: 'Incapaz de obtener resultados.',
      marketplace: {
        header: 'Merdcado',
        subheader: 'Vamos a encontrarle la oportunidad de inversión perfecta.',
      },
    },
  },
};
