import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Grid, Typography, ListItemButton, Button } from '@mui/material';

import MemberLayout from '../../../components/layouts/MemberLayout';
import SVG from '../../../assets/svg';
import ConfirmCancelModal from '../../../components/common/ConfirmCancelModal/ConfirmCancelModal';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import {
  SUBMIT_USER_CONTACT,
  REMOVE_USER_DATA_REQUEST,
} from '../../../graphql/user/userMutations';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import { ContactType } from '../../../types/user/types';
import { ME } from '../../../graphql/auth';

type ListProps = {
  label: string;
  onClick: () => void;
  requested?: boolean;
};
const DataManagementListItem: React.FC<ListProps> = ({
  label,
  onClick,
  requested,
}) => (
  <ListItemButton sx={{ height: 72 }} onClick={onClick}>
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{label}</Typography>
      {requested ? (
        <Button variant="contained" color="error">
          Requested
        </Button>
      ) : (
        <SVG.CaretRightSmall />
      )}
    </Grid>
  </ListItemButton>
);

const MemberDataManagement: React.FC = () => {
  const { user, company } = useContext(AuthorizationContext);
  const [submit, { loading, error }] = useMutation(SUBMIT_USER_CONTACT);
  const [retract, { loading: retractLoading, error: retractError }] =
    useMutation(REMOVE_USER_DATA_REQUEST);

  const [modalState, setModalState] = useState<
    'export' | 'delete' | 'retractExport' | 'retractDelete' | undefined
  >(undefined);
  const handleExportClick = () => {
    const requested = user?.dataRequests?.some(
      (o) => o.type === ContactType.EXPORT_DATA_REQUEST && !o.fulfilledDate,
    );
    if (requested) {
      setModalState('retractExport');
    } else {
      setModalState('export');
    }
  };

  const handleDeleteClick = () => {
    const requested = user?.dataRequests?.some(
      (o) => o.type === ContactType.DELETE_ACCOUNT_REQUEST && !o.fulfilledDate,
    );
    if (requested) {
      setModalState('retractDelete');
    } else {
      setModalState('delete');
    }
  };

  const exportData = () => {
    submit({
      variables: {
        contactType: ContactType.EXPORT_DATA_REQUEST,
      },
      refetchQueries: [ME],
      awaitRefetchQueries: true,
    });
    setModalState(undefined);
  };

  const retractRequest = (contactType: ContactType) => {
    retract({
      variables: {
        contactType,
        userId: user?.id,
        companyId: company.id,
      },
      refetchQueries: [ME],
      awaitRefetchQueries: true,
    });
    setModalState(undefined);
  };

  const deleteData = () => {
    submit({
      variables: {
        contactType: ContactType.DELETE_ACCOUNT_REQUEST,
      },
      refetchQueries: [ME],
      awaitRefetchQueries: true,
    });
    setModalState(undefined);
  };

  return (
    <MemberLayout>
      <DataStateHandler
        loading={loading || retractLoading}
        error={error || retractError}>
        <Grid container direction="column">
          <Typography variant="h2" sx={{ mb: 2 }}>
            Manage My Data
          </Typography>
          <DataManagementListItem
            label="Export Data"
            onClick={handleExportClick}
            requested={user?.dataRequests?.some(
              (o) =>
                o.type === ContactType.EXPORT_DATA_REQUEST && !o.fulfilledDate,
            )}
          />
          <DataManagementListItem
            label="Delete My Account"
            onClick={handleDeleteClick}
            requested={user?.dataRequests?.some(
              (o) =>
                o.type === ContactType.DELETE_ACCOUNT_REQUEST &&
                !o.fulfilledDate,
            )}
          />
          <ConfirmCancelModal
            isOpen={modalState === 'export'}
            onClose={() => setModalState(undefined)}
            onConfirm={exportData}
            hideCancel
            message={`Do you want us to send your data to the email you have on file, ${user?.email}?`}
            title="Export My Data"
            icon={<SVG.Export />}
            confirmLabel="Yes, Send Me My Data"
          />
          <ConfirmCancelModal
            isOpen={modalState === 'retractExport'}
            onConfirm={() => setModalState(undefined)}
            onCancel={() => retractRequest(ContactType.EXPORT_DATA_REQUEST)}
            message="You will receive an email shortly"
            title="Your request to export your data has been sent"
            icon={<SVG.Export />}
            confirmLabel="Close"
            cancelLabel="Retract My Request"
          />
          <ConfirmCancelModal
            isOpen={modalState === 'delete'}
            onConfirm={deleteData}
            hideCancel
            message="A request will be made to permanently delete your account. This action cannot be undone."
            title="Are you sure you want to delete your account?"
            icon={<SVG.Delete />}
            confirmLabel="Yes, Send Request to Delete"
          />
          <ConfirmCancelModal
            isOpen={modalState === 'retractDelete'}
            onCancel={() => retractRequest(ContactType.DELETE_ACCOUNT_REQUEST)}
            onConfirm={() => setModalState(undefined)}
            title="Your request to delete your account has been sent."
            icon={<SVG.Delete />}
            confirmLabel="Close"
            cancelLabel="Retract My Request"
          />
        </Grid>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberDataManagement;
