import { useQuery } from '@apollo/client';
import {
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';

import React, { useState } from 'react';
import useMenuAnchor from '../../../hooks/useMenuAnchor';
import { COMPANY_LIST_QUERY } from '../../../graphql/communications/communications';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import EmailRecipientListItem from './EmailRecipientListItem';
import ArrayUtils from '../../../utils/arrayUtils';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
    border: '2px solid #BDD9C5',
    minHeight: 64,
    maxHeight: 325,
    overflowY: 'auto',
    padding: 8,
    width: '100%',
  },
  button: {
    fontSize: 12,
  },
  searchBar: {
    width: '100%',
  },
  menu: {
    minWidth: 350,
  },
}));

export type CompanyListItem = {
  id: string;
  name: string;
  parentCompanyId?: string;
};

type Props = {
  onChange: (currentList: string[]) => void;
  disabled?: boolean;
  initialCompanyIds: string[];
};

const EmailRecipientsInput: React.FC<Props> = ({
  onChange,
  disabled,
  initialCompanyIds,
}) => {
  const [companyIds, setCompanyIds] = useState<string[]>(initialCompanyIds);
  const [filter, setFilter] = useState<string>('');

  const { onMenuClick, isMenuOpen, anchorEl, setAnchorEl } = useMenuAnchor();

  const classes = useStyles();
  const { data = {}, loading, error } = useQuery(COMPANY_LIST_QUERY);

  const { allCompanies = [] }: { allCompanies: CompanyListItem[] } = data;

  const parentCompanies = allCompanies.filter((c) => !c.parentCompanyId);

  const selectedParentCompanies = parentCompanies.filter(
    (pc) => companyIds.indexOf(pc.id) > -1,
  );

  const filtered = filter
    ? parentCompanies.filter((company) => {
        const parts = company.name.split(' ');
        const match = parts.find((p) =>
          p.toLowerCase().includes(filter.toLowerCase()),
        );
        return match;
      })
    : parentCompanies;

  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="caption">Send To</Typography>

          {disabled ? null : (
            <div>
              <Button
                className={classes.button}
                color="primary"
                onClick={onMenuClick}>
                Add Clients
              </Button>
              <Menu
                className={classes.menu}
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                keepMounted>
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <TextField
                    className={classes.searchBar}
                    variant="outlined"
                    placeholder="Search"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </MenuItem>

                <MenuItem
                  key="selectall"
                  onClick={() => {
                    let newRecipients = companyIds;
                    if (allCompanies.length === companyIds.length) {
                      newRecipients = [];
                      setCompanyIds(newRecipients);
                    } else {
                      newRecipients = allCompanies.map((c) => c.id);

                      setCompanyIds(allCompanies.map((c) => c.id));
                    }
                    onChange(newRecipients);
                  }}>
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={allCompanies.length === companyIds.length}
                  />
                  <ListItemText primary="Add All Clients" />
                </MenuItem>
                {filtered.map(({ name, id }) => (
                  <MenuItem
                    key={id}
                    onClick={() => {
                      let newRecipients = [];
                      if ((companyIds || []).indexOf(id) > -1) {
                        // deselect
                        const toRemove = [
                          id,
                          ...ArrayUtils.getChildren(
                            allCompanies,
                            'parentCompanyId',
                            id,
                          ).map((o) => o.id),
                        ];
                        newRecipients = companyIds.filter(
                          (r) => toRemove.indexOf(r) === -1,
                        );
                        setCompanyIds(newRecipients);
                      } else {
                        // select
                        const toAdd = [
                          id,
                          ...ArrayUtils.getChildren(
                            allCompanies,
                            'parentCompanyId',
                            id,
                          ).map((o) => o.id),
                        ];
                        newRecipients = [...companyIds, ...toAdd];
                        setCompanyIds(newRecipients);
                      }
                      onChange(newRecipients);
                    }}>
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={(companyIds || []).indexOf(id) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </Grid>
        <FormControl variant="standard" className={classes.root}>
          {selectedParentCompanies.map((pc) => (
            <EmailRecipientListItem
              key={pc.id}
              parent={pc}
              disabled={disabled}
              companyIds={companyIds}
              setCompanyIds={setCompanyIds}
              divisions={ArrayUtils.groupAndSortHierarchy(
                allCompanies,
                'parentCompanyId',
                pc.id,
              )}
            />
          ))}
        </FormControl>
      </>
    </DataStateHandler>
  );
};

export default EmailRecipientsInput;
