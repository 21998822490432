import React from 'react';
import {
  UserActivity,
  UserActivityType,
} from '../../../types/user/activityTypes';
import UserActivityPushNotificationListItem from './UserActivityPushNotificationListItem';
import UserActivityCompanyAllocationListItem from './UserActivityCompanyAllocationListItem';
import UserActivityWeeklyReminderListItem from './UserActivityWeeklyReminderListItem';
import UserActivityUserOffsetListItem from './UserActivityUserOffsetListItem';
import UserActivityUserAllocationListItem from './UserActivityUserAllocationListItem';
import UserActivityAdditionalAllotmentListItem from './UserActivityAdditionalAllotmentListItem';

export type UserActivityListItemProps = {
  userActivity: UserActivity;
};

const UserActivityListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    const renderContent = () => {
      switch (userActivity.activityType) {
        case UserActivityType.COMPANY_OFFSET:
          return (
            <UserActivityCompanyAllocationListItem
              userActivity={userActivity}
            />
          );
        case UserActivityType.USER_OFFSET:
          return <UserActivityUserOffsetListItem userActivity={userActivity} />;
        case UserActivityType.PUSH_NOTIFICATION:
          return (
            <UserActivityPushNotificationListItem userActivity={userActivity} />
          );
        case UserActivityType.WEEKLY_ENCOURAGEMENT:
          return (
            <UserActivityWeeklyReminderListItem userActivity={userActivity} />
          );
        case UserActivityType.ALLOTMENT_CREDIT_DISBURSEMENT:
          return (
            <UserActivityUserAllocationListItem userActivity={userActivity} />
          );
        case UserActivityType.ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION:
          return (
            <UserActivityAdditionalAllotmentListItem
              userActivity={userActivity}
            />
          );
        default:
          return null;
      }
    };

    return renderContent();
  },
);

export default UserActivityListItem;
