import { useQuery } from '@apollo/client';

import { GET_TOOLTIPS } from '../graphql/settings';

export const useTooltipData = (id: string) => {
  const {
    loading,
    data: { tooltips = [] } = {},
    error,
  } = useQuery(GET_TOOLTIPS, {
    variables: {
      platform: 'PORTAL',
    },
  });

  if (loading) {
    return '';
  }

  if (error) {
    return 'Error fetching tooltip data';
  }

  return (
    tooltips.find((t) => t.id === id)?.value || `Missing tooltip for id ${id}`
  );
};

export default useTooltipData;
