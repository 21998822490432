import {
  Drawer,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SVG from '../../assets/svg-v2';
import theme from '../../theme/memberTheme';
import ActivityIcon from './activities/ActivityIcon';
import NetEmissionsPreview from './dashboard/NetEmissionsPreview';
import CheckFeature from '../common/CheckFeature/CheckFeature';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    marginRight: 8,
    '& h3': {
      padding: 8,
      paddingTop: 12,
    },
    '&.active h3': {
      color: theme.palette.primary.main,
    },
    '&.active div svg': {
      color: theme.palette.primary.main,
    },
    '& div svg': {
      color: theme.palette.text.primary,
    },
  },
  root: {
    marginBottom: 32,
  },
}));

const MemberNavigation = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const generateCommonLinks = () => (
    <>
      <NavLink to="/member/dashboard" className={classes.link}>
        <Typography variant="h3">Home</Typography>
      </NavLink>
      <NavLink to="/member/myhabits" className={classes.link}>
        <Typography variant="h3">My Habits</Typography>
      </NavLink>
      <CheckFeature featureIds={['marketplace']}>
        <NavLink to="/member/projects" className={classes.link}>
          <Typography variant="h3">Projects</Typography>
        </NavLink>
      </CheckFeature>
      <NavLink to="/member/discover" className={classes.link}>
        <Typography variant="h3">Discover</Typography>
      </NavLink>
      <NavLink to="/member/profile" className={classes.link}>
        <Typography variant="h3">Profile</Typography>
      </NavLink>
    </>
  );

  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}>
      <Grid item>
        <Link to="/member/dashboard" className={classes.link}>
          <SVG.ZeroMeLogo />
        </Link>
      </Grid>
      {!isSm && (
        <Grid item>
          <Grid container alignItems="center">
            {generateCommonLinks()}
            <NavLink to="/member/activities" className={classes.link}>
              <ActivityIcon />
            </NavLink>
            <Grid item pl={3}>
              <NetEmissionsPreview />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isSm && (
        <>
          <Grid item style={{ paddingTop: 12 }}>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <SVG.Hamburger />
            </IconButton>
          </Grid>
          <Drawer anchor="right" open={drawerOpen}>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              style={{ width: 200 }}>
              <Grid item container justifyContent="flex-end">
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <SVG.Close />
                </IconButton>
              </Grid>
              <NetEmissionsPreview />
              {generateCommonLinks()}
              <NavLink to="/member/activities" className={classes.link}>
                <ActivityIcon textual />
              </NavLink>
            </Grid>
          </Drawer>
        </>
      )}
    </Grid>
  );
};

export default MemberNavigation;
