import { Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.palette.buttons.primary.main,
  },
  categoryIcon: {
    height: '3rem',
  },
  dataGrid: { flex: 1 },
}));

type Props = {
  label?: string;
  value?: string | number;
  categoryId?: string;
};

const OffsetCardValue = ({ categoryId, value, label }: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      alignItems="center"
      spacing={1}
      className={classes.dataGrid}>
      <Grid item>
        <Typography variant="h1" className={classes.value}>
          {categoryId ? (
            <div className={classes.categoryIcon}>
              <CategoryIconSmall categoryId={categoryId} />
            </div>
          ) : (
            (typeof value === 'string' && value) ||
            (value !== undefined && value.toLocaleString('en-US')) ||
            value
          )}
        </Typography>
      </Grid>
      {label && (
        <Grid item>
          <Typography variant={categoryId ? 'h4' : 'body2'}>{label}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default OffsetCardValue;
