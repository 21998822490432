import { gql, TypedDocumentNode } from '@apollo/client';
import { RolesAndAbilities, User } from '../types/user/types';

export const GET_COMPANY_SETTINGS_FOR_EMAIL = gql`
  query companySettingsForEmail($email: String!) {
    companySettingsForEmail(email: $email) {
      id
      name
      policyId
    }
  }
`;

export const ME = gql`
  query me {
    me {
      id
      name
      country
      firstName
      lastName
      userChatSettings {
        hashWeb
      }
      roles
      roleConfig {
        id
        name
        backgroundColor
        fontColor
        abilities {
          name
          operation
        }
      }
      email
      companyId
      portalAcceptedTermsDate
      lifestyleHabits {
        id
      }
      avatar {
        metadataId
        components {
          componentId
          optionId
          color
        }
      }
      dataRequests {
        type
        fulfilledDate
        requestedDate
      }
      place
      region
      hasCompletedOnboardingInterests
      hasCompletedOnboardingQuestionnaire
      hasConfirmedLocation
      postalCodeChanged
      likedProjectIds
    }
  }
` as TypedDocumentNode<{ me: User }>;

export const ALL_ROLES = gql`
  query allRolesAndAbilities {
    allRolesAndAbilities {
      roles {
        id
        name
        private
        backgroundColor
        fontColor
        abilities {
          name
          operation
        }
      }
      abilities {
        name
        private
        description
      }
    }
  }
` as TypedDocumentNode<{ allRolesAndAbilities: RolesAndAbilities }>;
