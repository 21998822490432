import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import {
  QuestionTreeCategory,
  QuestionTreeCategoryStatusDetail,
} from '../../../types/question/types';
import CompleteIcon from '../../common/CompleteIcon/CompleteIcon';
import theme from '../../../theme/memberTheme';
import { Colors } from '../../../theme/shared';

import SVG from '../../../assets/svg-v2';
import { USER_ABOUT_ME_REMINDERS } from '../../../graphql/user/user';
import { SNOOZE_REMINDER } from '../../../graphql/user/userMutations';
import { QuestionReminder } from '../../../types/user/types';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';

export type LifestyleCategoryStatusProps = {
  allCategories: QuestionTreeCategory[];
  status: QuestionTreeCategoryStatusDetail;
};

export function LifestyleCategoryStatus({
  allCategories,
  status,
}: LifestyleCategoryStatusProps): JSX.Element {
  const history = useHistory();

  const {
    loading: remindersLoading,
    error: remindersError,
    data: { aboutMeReminders } = {},
    refetch,
  } = useQuery(USER_ABOUT_ME_REMINDERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [coreSnooze, { loading: snoozeLoading, error: snoozeError }] =
    useMutation(SNOOZE_REMINDER);

  const reminder = aboutMeReminders?.find(
    (x) => x.type === 'questions' && x.subType === status.questionTreeCategory,
  ) as QuestionReminder;

  const snooze = async () => {
    if (reminder) {
      await coreSnooze({
        variables: {
          type: reminder.type,
          subType: reminder.subType,
        },
      });

      refetch();
    }
  };

  const isComplete = !!status.isComplete;

  const handleNavigation = () => {
    if (isComplete) {
      history.push(`/member/responses/${status.questionTreeCategory}`);
    } else {
      history.push(`/member/questionnaire/${status.questionTreeCategory}`);
    }
  };

  const category = allCategories.find(
    (x) => x.id === status.questionTreeCategory,
  );

  return (
    <DataStateHandler
      loading={remindersLoading || snoozeLoading}
      error={remindersError || snoozeError}>
      <Grid
        item
        container
        flexDirection="row"
        alignItems="center"
        onClick={() => handleNavigation()}
        style={{ cursor: 'pointer' }}>
        <Grid item xs>
          {!!category && (
            <ReactSVG
              src={category.altIconUrl}
              beforeInjection={(svg) => {
                svg.setAttribute('height', '80');
                svg.setAttribute('width', '80');
              }}
            />
          )}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{category?.title}</Typography>
        </Grid>
        <Grid item container flexDirection="row" xs justifyContent="flex-end">
          {!isComplete && (
            <Grid item>
              <Typography variant="button" color={Colors.TertiaryText}>
                {status.answered}/{status.total}
              </Typography>
            </Grid>
          )}
          <Grid item style={{ paddingTop: 4, paddingLeft: 8 }}>
            <CompleteIcon complete={isComplete} />
          </Grid>
        </Grid>
        {!!reminder && (
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            mb={2}
            style={{
              borderStyle: 'dashed',
              borderColor: Colors.DarkGrey,
              borderRadius: 8,
              borderWidth: 1,
              backgroundColor: theme.palette.primary.light,
            }}>
            <Grid item>
              <Typography variant="body2">
                {reminder.data.lastResponseDaysAgo} days old
              </Typography>
              <Typography
                variant="button"
                style={{ color: Colors.CobaltBlue, fontSize: 12 }}>
                Update Responses
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => {
                  snooze();
                  e.stopPropagation();
                }}>
                <SVG.Close />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </DataStateHandler>
  );
}
