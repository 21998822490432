import { gql, TypedDocumentNode } from '@apollo/client';
import { BaseCompany, Company } from '../../types/company/types';
import { CategoryInterest } from '../../types/dashboard/types';
import {
  EmisisonsSetting,
  ProjectPurchaseHistory,
  ProjectPurchaseHistoryInput,
} from '../../types/offsetProject/types';

export type Pageable<T> = {
  continuationToken: string;
  items: Array<T>;
};

export type OffsetMetricsResult = {
  offsetProjectsByCompany: Pageable<ProjectPurchaseHistory>;
  projectCategoryInterest: Pick<CategoryInterest, 'id' | 'label' | 'percent'>[];
  emissionsSettings: EmisisonsSetting[];
  companySubsidiaries: BaseCompany[];
};

const projectPurchaseHistoryFields = `
id
projectId
projectCategoryId
uom
companyId
price
quantity
allocated
remaining
status
requestedDate
invoicedAmount
certificateUrl
allocations {
  requestId
  requestedDate
  status
  lastModifiedDate
  quantity
  periodStart
  periodEnd
  allocType
  allocSubType
  isOffPlatform
  notes
}
project {
  projectId
  title
  source
  indicator
  uom
}
company {
  id
  name
}`;

export const GET_OFFSET_METRICS = gql`
  query ($companyId: String!, $language: String!, $filter: OffsetProjectSearchFilterProps) {
    offsetProjectsByCompany(companyId: $companyId, filter: $filter) {
      items {
        ${projectPurchaseHistoryFields}
      }
    }
    projectCategoryInterest(companyId: $companyId) {
      id
      label
      percent
    }
    emissionsSettings(language: $language) {
      type
      subType
      allocType
      allocSubType
      allocTypeLabel
      allocSubTypeLabel
    }
    companySubsidiaries(parentCompanyId: $companyId) {
      name
      id
      parentCompanyId
      parentCompanyName
      createdDate
    }
  }
` as TypedDocumentNode<
  OffsetMetricsResult,
  {
    companyId: Company['id'];
    language: 'en' | 'es';
    filter: {
      purchaseType: string[];
    };
  }
>;

export type EmissionsCalcResult = {
  emissionsTotals: {
    allocType: string;
    allocSubType: string;
    emissions: number;
  }[];
  offsetTotals: {
    allocType: string;
    allocSubType: string;
    emissions: number;
    offsetType: string;
  }[];
};

export const GET_EMISSIONS_CALCULATIONS = gql`
  query ($companyId: String!, $startYYYYMM: Int!, $endYYYYMM: Int!) {
    emissionsCalculations(
      companyId: $companyId
      startYYYYMM: $startYYYYMM
      endYYYYMM: $endYYYYMM
    ) {
      emissionsTotals {
        allocType
        allocSubType
        emissions
      }
      offsetTotals {
        allocType
        allocSubType
        emissions
        offsetType
      }
    }
  }
` as TypedDocumentNode<
  {
    emissionsCalculations: EmissionsCalcResult;
  },
  { companyId: BaseCompany['id']; startYYYYMM: number; endYYYYMM: number }
>;

export type CreatePurchaseAllocationVariable = {
  companyId: BaseCompany['id'];
  purchaseAllocations: ProjectPurchaseHistoryInput[];
};

export const CREATE_PURCHASE_ALLOCATIONS = gql`
  mutation createPurchaseAllocations(
    $companyId: String!
    $purchaseAllocations: [ProjectPurchaseHistoryInput]
  ) {
    createPurchaseAllocations(
      companyId: $companyId
      purchaseAllocations: $purchaseAllocations
    ) {
      ${projectPurchaseHistoryFields}
    }
  }
` as TypedDocumentNode<
  { createPurchaseAllocations: ProjectPurchaseHistory[] },
  CreatePurchaseAllocationVariable
>;

export const CREATE_OFF_PLATFORM_ALLOCATION = gql`
  mutation createOffPlatformAllocation(
    $companyId: String!
    $purchaseHistoryId: String!
    $quantity: Int!
    $notes: String
  ) {
    createOffPlatformAllocation(
      companyId: $companyId
      purchaseHistoryId: $purchaseHistoryId
      quantity: $quantity
      notes: $notes
    ) {
      id
      remaining
    }
  }
`;

export const UPDATE_OFF_PLATFORM_ALLOCATION = gql`
  mutation updateOffPlatformAllocation(
    $companyId: String!
    $purchaseHistoryId: String!
    $allocationRequestId: String!
    $notes: String
  ) {
    updateOffPlatformAllocation(
      companyId: $companyId
      purchaseHistoryId: $purchaseHistoryId
      allocationRequestId: $allocationRequestId
      notes: $notes
    ) {
      id
      remaining
      allocations {
        requestId
        notes
      }
    }
  }
`;
