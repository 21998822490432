import React from 'react';
import { useQuery } from '@apollo/client';
import { CartoonSvgAvatarComponent } from './components/CartoonSVGAvatarComponent';
import { GET_AVATAR_METADATA } from '../../../graphql/shared';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import { Avatar } from '../../../types/common';

export type CartoonSvgAvatarProps = {
  avatar: Avatar;
  size?: 'lg' | 'md';
};

export function CartoonSvgAvatar({
  avatar,
  size = 'md',
}: CartoonSvgAvatarProps): JSX.Element {
  const {
    data: { avatarMetadata } = {},
    loading,
    error,
  } = useQuery(GET_AVATAR_METADATA);

  const px = size === 'md' ? 48 : 100;

  return (
    <DataStateHandler loading={loading} error={error}>
      <div style={{ position: 'relative', width: px, height: px }}>
        {(
          (avatarMetadata || []).find((x) => x.id === avatar.metadataId)
            ?.components || []
        )
          .flatMap((c) => {
            const config = avatar.components.find(
              (x) => x.componentId === c.id,
            );
            const option = c.options.find((x) => x.id === config?.optionId);

            let color = config?.color;

            if (c.sameColorAs) {
              color = avatar.components.find(
                (x) => x.componentId === c.sameColorAs,
              )?.color;
            }

            return config && option
              ? [
                  {
                    component: c,
                    option,
                    config,
                    color,
                  },
                ]
              : [];
          })
          .map((c) => (
            <div key={c.component.id} style={c.component.style}>
              <CartoonSvgAvatarComponent option={c.option} color={c.color} />
            </div>
          ))}
      </div>
    </DataStateHandler>
  );
}
