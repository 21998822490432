import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import theme from '../../../theme/memberTheme';
import { WATER_COMPARISON } from '../../../graphql/dashboard/emissions/emissions';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import SVG from '../../../assets/svg';
import WaterComparisonChart from './WaterComparisonChart';
import MemberWaterFootprintCard from './MemberWaterFootprintCard';
import { UserWaterFootprintItem } from '../../../types/dashboard/types.js';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 32,
    backgroundColor: theme.palette.backgrounds.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 24,
  },
  rootWide: {
    position: 'relative',
    left: 200,
    marginLeft: -200,
    paddingRight: 200,
  },
  faucetBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 16,
    position: 'relative',
  },
  waterValue: {
    fontWeight: 700,
  },
  white: {
    color: '#fff',
  },
  legend: {
    width: 12,
    height: 12,
    borderRadius: 6,
    display: 'inline-block',
    marginRight: 6,
  },
}));

const MyWaterUse = () => {
  const classes = useStyles();
  const history = useHistory();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const { user, company } = useContext(AuthorizationContext);
  const endMoment = moment.utc().endOf('isoWeek').subtract(1, 'week');
  const skip = !user?.id || !company.id;

  const [chartState, setChartState] = useState<
    'FAUCET' | 'COMPANY' | 'USER' | 'COUNTRY'
  >('FAUCET');

  const {
    loading,
    error,
    data: {
      userEmissionsWeekly,
      waterFootprint,
      averageWaterFootprintForCountry,
    } = {},
  } = useQuery(WATER_COMPARISON, {
    variables: {
      userId: user?.id,
      companyId: company.id,
      date: endMoment.toISOString(),
      startYYYYWW: parseInt(endMoment.startOf('isoWeek').format('YYYYWW'), 10),
      endYYYYWW: parseInt(endMoment.format('YYYYWW'), 10),
      country: user?.country,
    },
    skip,
  });

  const waterTotals = userEmissionsWeekly?.details.map(
    (map) => map.waterTotals,
  );

  const breakdown: UserWaterFootprintItem[] = [];

  waterTotals?.flat().forEach((each) => {
    const breakdownIndex = breakdown.findIndex(
      (find) => find.type === each.type,
    );

    if (breakdownIndex === -1) {
      breakdown.push({ ...each });
    } else {
      breakdown[breakdownIndex].liters += each.liters;
    }
  });

  const userItems = breakdown.map((b) => ({
    id: b.typeLabel,
    color: b.graphColor,
    value: b.liters,
  }));

  const userTotal = userEmissionsWeekly?.totals[0]?.totalWaterEmissions || 0;

  const organizationTotal =
    waterFootprint?.map((x) => x.value).reduce((acc, cur) => acc + cur, 0) || 0;

  const countryTotal =
    averageWaterFootprintForCountry
      ?.map((x) => x.value)
      .reduce((acc, cur) => acc + cur, 0) || 0;

  const formatWaterValue = (value: number) =>
    new Intl.NumberFormat('en-us', {
      maximumFractionDigits: 0,
      notation: 'compact',
    }).format(value);

  const hasData = userTotal > 0;

  return (
    <Grid container flexDirection="row">
      <DataStateHandler loading={loading || skip} error={error}>
        <>
          <Grid item xs={12} md={chartState === 'FAUCET' ? 7 : 6}>
            <Box className={`${classes.root} ${isSm ? '' : classes.rootWide}`}>
              <Grid container flexDirection="column">
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pb={3}>
                  <Grid item>
                    <Typography variant="h3">My Water Use</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() =>
                        history.push(
                          '/member/questionnaire/residential-water-consumption',
                        )
                      }>
                      Update Water Use Profile
                    </Button>
                  </Grid>
                </Grid>
                <Grid item pb={5}>
                  <Typography variant="body1">
                    This portion of ZeroMe reflects the amount of water you
                    actually use. We determine that amount through your
                    responses to the water related questions we ask.
                  </Typography>
                </Grid>
                {hasData ? (
                  <>
                    <Grid
                      item
                      container
                      flexDirection="row"
                      justifyContent="space-between">
                      <Typography variant="body1">
                        <div
                          className={classes.legend}
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            backgroundColor: Colors.LightGrey,
                          }}
                        />
                        My Weekly Water Footprint
                      </Typography>
                      <Grid item>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography
                            variant="body2"
                            className={classes.waterValue}>
                            {formatWaterValue(userTotal)} Liters
                          </Typography>
                          <IconButton onClick={() => setChartState('USER')}>
                            <SVG.CaretRightSmall />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      flexDirection="row"
                      justifyContent="space-between">
                      <Typography variant="body1">
                        <div
                          className={classes.legend}
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            backgroundColor: '#CAD8FD',
                          }}
                        />
                        My Organization Avg. Water Footprint
                      </Typography>
                      <Grid item>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography
                            variant="body2"
                            className={classes.waterValue}>
                            {formatWaterValue(organizationTotal)} Liters
                          </Typography>
                          <IconButton onClick={() => setChartState('COMPANY')}>
                            <SVG.CaretRightSmall />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      flexDirection="row"
                      justifyContent="space-between">
                      <Typography variant="body1">
                        <div
                          className={classes.legend}
                          style={{
                            border: `1px dashed ${theme.palette.primary.dark}`,
                          }}
                        />
                        US Avg. Water Footprint
                      </Typography>
                      <Grid item>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography
                            variant="body2"
                            className={classes.waterValue}>
                            {formatWaterValue(countryTotal)} Liters
                          </Typography>
                          <IconButton onClick={() => setChartState('COUNTRY')}>
                            <SVG.CaretRightSmall />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Box>
          </Grid>
          {!isSm && <Grid item xs={1} />}
          {chartState === 'FAUCET' && (
            <WaterComparisonChart
              organizationTotal={organizationTotal}
              countryTotal={countryTotal}
              userTotal={userTotal}
            />
          )}
          {chartState === 'COMPANY' && (
            <MemberWaterFootprintCard
              onClose={() => setChartState('FAUCET')}
              title="MY ORGANIZATIONAL AVG. WATER FOOTPRINT"
              data={waterFootprint}
            />
          )}
          {chartState === 'COUNTRY' && (
            <MemberWaterFootprintCard
              onClose={() => setChartState('FAUCET')}
              title="US AVG. WATER FOOTPRINT"
              data={averageWaterFootprintForCountry}
            />
          )}
          {chartState === 'USER' && (
            <MemberWaterFootprintCard
              onClose={() => setChartState('FAUCET')}
              title="MY WEEKLY WATER FOOTPRINT"
              data={userItems}
            />
          )}
        </>
      </DataStateHandler>
    </Grid>
  );
};

export default MyWaterUse;
