import React from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import { Grid, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Project, ProjectCategory } from '../../../types/project/types';
import FeaturedProject from './FeaturedProject';

type Props = {
  categories: ProjectCategory[];
  clickHandler: (arg: string) => void;
  projects: Project[];
};

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1340,
  },
  featured: {
    // allow space for child box shadow
    height: 534,
    borderRadius: 8,
  },
  navButton: {
    border: '1px solid #9E9E9E !important',
    marginLeft: 8,
    borderRadius: 8,
    backgroundColor: 'transparent',
    fontSize: 16,
    width: 40,
    minWidth: 40,
    color: '#9E9E9E',
  },
  header: { marginBottom: 40 },
}));

const FeaturedProjects: React.FC<Props> = ({
  categories,
  clickHandler,
  projects,
}) => {
  const classes = useStyles();

  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      withThumbs: false,
      withLoop: true,
      items: projects.map((project) => ({
        id: project.id,
        renderItem: (
          <FeaturedProject
            categories={categories}
            clickHandler={clickHandler}
            project={project}
          />
        ),
      })),
    });

  return (
    <div className={classes.container}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="h3" className={classes.header}>
            Featured Projects
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.navButton}
            onClick={slideToPrevItem}>
            <ArrowBackIosIcon style={{ fontSize: 16 }} />
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.navButton}
            onClick={slideToNextItem}>
            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
          </Button>
        </Grid>
      </Grid>

      <Grid item container className={classes.featured}>
        {carouselFragment}
      </Grid>
    </div>
  );
};

export default FeaturedProjects;
