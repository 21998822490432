import React, { forwardRef, useContext } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import {
  CompanySettingsContext,
  CompanySettingsFieldMetadata,
  CompanySettingsFields,
  EditableCompany,
} from '../../contexts/CompanySettingsContext';
import SettingsModalInput, {
  SettingsModalInputRef,
} from './SettingsModalInput';

type Props = {
  fieldName: CompanySettingsFields;
  children?: React.ReactNode;
  disabled?: boolean;
};

const CompanySettingsModalInput = forwardRef<SettingsModalInputRef, Props>(
  ({ fieldName, children, disabled }, ref) => {
    const { userHasAccess } = useContext(AuthorizationContext);
    const { company, saveCompany } = useContext(CompanySettingsContext);
    const {
      createInitialValues,
      displayUnderneath,
      formComponent,
      fullWidthSave,
      label,
      modalOnly,
      noLabelInModal,
      textFieldProps,
      validationSchema,
      valueRenderer,
      valueField,
    } = CompanySettingsFieldMetadata[fieldName];

    const initialValues =
      (valueField && {
        [valueField as string]: company[valueField],
      }) ||
      (createInitialValues && createInitialValues(company)) ||
      {};

    const displayValue =
      (valueRenderer && valueRenderer(company)) ||
      (valueField && company[valueField]) ||
      '';

    return (
      <SettingsModalInput<EditableCompany>
        ref={ref}
        label={label}
        displayValue={displayValue}
        initialValues={initialValues}
        textFieldProps={textFieldProps}
        displayUnderneath={displayUnderneath}
        validationSchema={validationSchema}
        fullWidthSave={fullWidthSave}
        modalOnly={modalOnly}
        disabled={disabled || !userHasAccess('Configuration.Company', 'EDIT')}
        formComponent={formComponent}
        noLabelInModal={noLabelInModal}
        onSave={(data) => saveCompany(data)}>
        {children}
      </SettingsModalInput>
    );
  },
);

export default CompanySettingsModalInput;
