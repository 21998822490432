import React from 'react';

import MemberLayout from '../../../components/layouts/MemberLayout';
import ContactUsForm from '../../../components/member/profile/ContactUsForm';

const MemberContact: React.FC = () => (
  <MemberLayout>
    <ContactUsForm />
  </MemberLayout>
);

export default MemberContact;
