import { ApolloError, useQuery } from '@apollo/client';
import React, { createContext, FC, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import DiscoverIcon from '../components/communications/icons/DiscoverIcon';
import {
  COMMUNICATION_CATEGORIES,
  generateCommunicationsQuery,
} from '../graphql/communications/communications';
import { abilityType } from '../types/user/types';
// import PushIcon from '../components/communications/icons/PushIcon';
import EmailIcon from '../components/communications/icons/EmailIcon';
import {
  CommunicationCategory,
  CommunicationListItem,
  EmailCommunicationListItem,
  Notification,
  Quotation,
} from '../components/communications/types';
import { AuthorizationContext } from './AuthorizationContext';
import { CommunicationsParams } from '../components/communications/CommunicationsContainer';
import NotificationIcon from '../components/communications/icons/NotificationsIcon';
import GearIcon from '../components/communications/icons/GearIcon';

export const navItems = [
  {
    id: 'discover',
    label: 'Discover',
    ability: abilityType('ZeroMe.Communication.Discover'),
    isGlobal: true,
    icon: (active: boolean) => <DiscoverIcon active={active} />,
  },
  {
    id: 'emails',
    label: 'Emails',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Emails'),
    icon: (active: boolean) => <EmailIcon active={active} />,
  },
  // {
  //   id: 'quotations',
  //   label: 'Quotes',
  //   isGlobal: true,
  //   ability: abilityType('ZeroMe.Communication.Quotes'),
  //   icon: (active: boolean) => <QuotesIcon active={active} />,
  // },
  {
    id: 'notifications',
    label: 'Push Notifications',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.PushNotifications'),
    icon: (active: boolean) => <NotificationIcon active={active} />,
  },
  {
    id: 'settings',
    label: 'Settings',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Settings'),
    icon: (active: boolean) => <GearIcon active={active} />,
  },
  {
    id: 'discover',
    label: 'Discover',
    ability: abilityType('Client.Communication.Discover'),
    icon: (active: boolean) => <DiscoverIcon active={active} />,
  },
  {
    id: 'emails',
    label: 'Emails',
    ability: abilityType('Client.Communication.Emails'),
    icon: (active: boolean) => <EmailIcon active={active} />,
  },
  {
    id: 'notifications',
    label: 'Push Notifications',
    ability: abilityType('Client.Communication.PushNotifications'),
    icon: (active: boolean) => <NotificationIcon active={active} />,
  },
  // {
  //   id: 'settings',
  //   label: 'Settings',
  //   ability: abilityType('Client.Communication.Settings'),
  //   icon: (active: boolean) => <GearIcon active={active} />,
  // },
];

type CommunicationsContext = {
  communications: CommunicationListItem[];
  emails: EmailCommunicationListItem[];
  quotations: Quotation[];
  communicationCategories: CommunicationCategory[];
  notifications: Notification[];
  communicationsLoading: boolean;
  communicationsError: ApolloError | undefined;
  companyId?: string;
  refetchCommunicationsQuery: () => void;
};
const initialContext: CommunicationsContext = {
  communications: [],
  emails: [],
  quotations: [],
  notifications: [],
  communicationCategories: [],
  communicationsError: undefined,
  communicationsLoading: false,
  companyId: '',
  refetchCommunicationsQuery: () => null,
};
export const CommunicationsContext = createContext(initialContext);

const CommunicationsProvider: FC = ({ children }) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const { companyId } = useParams<CommunicationsParams>();

  const variables = {
    includeGlobalCommunications: !companyId,
    companyId,
    ignoreCache: false,
  };

  const {
    data: {
      communications: { items: communications = [] } = {},
      quotations = [],
      emailCommunications: { items: emails = [] } = {},
      notifications: { items: notifications = [] } = {},
    } = {},
    loading: communicationsLoading,
    error: communicationsError,
    refetch,
  } = useQuery(generateCommunicationsQuery(userHasAccess, !!companyId), {
    variables,
  });
  const { data: { communicationCategories = [] } = {} } = useQuery(
    COMMUNICATION_CATEGORIES,
  );

  const refetchCommunicationsQuery = () =>
    refetch({
      ...variables,
      ignoreCache: true,
    });

  const value = useMemo(
    () => ({
      communications,
      emails,
      quotations,
      notifications,
      communicationCategories,
      communicationsError,
      communicationsLoading,
      companyId,
      refetchCommunicationsQuery,
    }),
    [
      communications,
      emails,
      quotations,
      notifications,
      communicationCategories,
      communicationsError,
      communicationsLoading,
      companyId,
      refetchCommunicationsQuery,
    ],
  );

  return (
    <CommunicationsContext.Provider value={value}>
      {children}
    </CommunicationsContext.Provider>
  );
};

export default CommunicationsProvider;
