import { gql, TypedDocumentNode } from '@apollo/client';
import { MemberCommunicationListItem } from '../../components/communications/types.js';

export const GET_DISCOVER_COMMUNICATIONS = gql`
  query GET_DISCOVER_COMMUNICATIONS($companyId: String) {
    communications(options: { companyId: $companyId, isPublished: true }) {
      continuationToken
      items {
        id
        title
        author
        communicationType
        isFeatured
        contentUploadUrl
        headerImageUrls {
          large
          medium
          thumb
        }
        headerImageBlurHash
        publishDate
        createdDate
        updatedDate
      }
    }
  }
` as TypedDocumentNode<{
  communications: {
    items: MemberCommunicationListItem[];
    continuationToken: string;
  };
}>;

export const GET_DISCOVER_COMMUNICATION_DETAILS = gql`
  query communication($id: String!) {
    communication(id: $id) {
      id
      title
      author
      content
      readingTime
      communicationType
      isFeatured
      contentUploadUrl
      headerImageUrls {
        large
        medium
        thumb
      }
      headerImageBlurHash
      publishDate
      createdDate
      updatedDate
    }
  }
` as TypedDocumentNode<{
  communication: MemberCommunicationListItem & {
    content: string;
    readingTime: number;
  };
}>;

export const GET_WELCOME_VIDEO = gql`
  query getWelcomeVideo {
    getWelcomeVideo {
      id
      contentUploadUrl
    }
  }
` as TypedDocumentNode<{
  getWelcomeVideo: {
    id: string;
    contentUploadUrl: string;
  } | null;
}>;

export default {
  GET_DISCOVER_COMMUNICATIONS,
};
