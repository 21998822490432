import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Waypoint } from 'react-waypoint';
import {
  GET_UNREAD_USER_ACTIVITY_IDS,
  GET_USER_ACTIVITIES,
} from '../../../graphql/activities/activities';
import { UPDATE_USER_MARK_ACTIVITIES_READ } from '../../../graphql/activities/activitiesMutations';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import UserActivityListItem from './UserActivityListItem';
import { Colors } from '../../../theme/shared';

export default function UserActivityList({
  unreadOnly,
}: {
  unreadOnly: boolean;
}) {
  const [unreadDate] = useState(new Date().toISOString());

  const { loading, error, data, fetchMore } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      ...(unreadOnly ? { unreadDate } : {}),
      continuationToken: '',
    },
  });

  const [updateUserMarkActivitiesRead] = useMutation(
    UPDATE_USER_MARK_ACTIVITIES_READ,
  );

  useEffect(() => {
    const unread = data?.me.activities.items
      .filter((x) => !x.readAt)
      .map((x) => x.id);

    if (unread?.length) {
      updateUserMarkActivitiesRead({
        variables: {
          activityItemIds: unread,
          readAt: unreadDate,
        },
        refetchQueries: [GET_UNREAD_USER_ACTIVITY_IDS],
      });
    }
  }, [data, updateUserMarkActivitiesRead]);

  const handleWaypointEnter = async () => {
    if (data?.me.activities.continuationToken) {
      await fetchMore({
        variables: {
          ...(unreadOnly ? { unreadDate } : {}),
          continuationToken: data?.me.activities.continuationToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            ...previousResult,
            me: {
              ...previousResult.me,
              activities: {
                ...fetchMoreResult.me.activities,
                items: [
                  ...previousResult.me.activities.items,
                  ...fetchMoreResult.me.activities.items,
                ],
              },
            },
          };
        },
      });
    }
  };

  const listEmptyText = useMemo(() => {
    if (unreadOnly) {
      return 'You currently don’t have any unread notifications';
    }

    return 'You currently don’t have any notifications';
  }, [unreadOnly]);

  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        {!data?.me.activities.items.length && (
          <Typography
            variant="h2"
            style={{
              color: Colors.TertiaryText,
              fontWeight: 500,
              textAlign: 'center',
            }}>
            {listEmptyText}
          </Typography>
        )}
        {!!data?.me.activities.items.length && (
          <Grid container flexDirection="column">
            {data?.me.activities.items.map((x) => (
              <Box key={x.id}>
                <Grid item>
                  <UserActivityListItem userActivity={x} />
                  <Box pt={2} pb={2}>
                    <Divider />
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        )}
        <Grid item>
          <Waypoint onEnter={handleWaypointEnter} />
        </Grid>
        {data?.me.activities.continuationToken && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </>
    </DataStateHandler>
  );
}
