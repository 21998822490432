import { Typography } from '@mui/material';
import React from 'react';
import { HabitWithUsageData } from '../../../../types/habit/types';
import NumberUtils from '../../../../utils/numberUtils';
import HabitCategoryIcon from '../../../common/Habit/HabitCategoryIcon';
import AlphaColumnFilter from '../../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../../common/Table/types';
import RoundedFlexBox from '../../../trader/RoundedFlexBox';
import NoDataAvailable from '../../../common/NoDataAvailable';

const tableStructure: GroupableTableStructure<HabitWithUsageData>[] = [
  {
    key: 'main',
    columns: [
      {
        key: 'category',
        display: 'Cat.',
        render: ({ habit }) =>
          habit.category ? (
            <HabitCategoryIcon category={habit.category} />
          ) : null,
      },
      {
        key: 'habit.name',
        display: 'Name',
        searchable: true,
        type: AlphaColumnFilter,
        searchPlaceholder: 'Search Title',
        render: ({ habit }) => (
          <Typography
            variant="body2"
            noWrap
            style={{ width: '25ch', display: 'inline-block', fontWeight: 600 }}>
            {habit.name}
          </Typography>
        ),
      },
      {
        key: 'totalParticipants',
        type: AlphaColumnFilter,
        display: 'Total Participants',
        resolveFilterLabel: (value) =>
          NumberUtils.format(value as number, 'integer'),
        render: (c) => (
          <Typography variant="body2">
            {NumberUtils.format(c.totalParticipants, 'integer')}
          </Typography>
        ),
      },
    ],
  },
];

type Props = {
  habits: HabitWithUsageData[];
};

const CompanyHabitsList: React.FC<Props> = ({ habits }) => {
  const hasData = habits?.some((h) => h.totalParticipants > 0);
  return (
    <div style={{ height: 500 }}>
      <RoundedFlexBox>
        {hasData ? (
          <GroupableSelectTable<HabitWithUsageData>
            tableData={habits.map((h) => ({ ...h, id: h.habit.id }))}
            tableStructure={tableStructure}
            hideStickyCol
          />
        ) : (
          <NoDataAvailable />
        )}
      </RoundedFlexBox>
    </div>
  );
};

export default CompanyHabitsList;
