import { useMutation } from '@apollo/client';
import { Button, Checkbox, Chip, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import SVG from '../../assets/svg';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { GET_COMPANY_GOALS } from '../../graphql/company/company';
import {
  DELETE_COMPANY_GOAL_MUTATION,
  UPSERT_COMPANY_GOAL_MUTATION,
} from '../../graphql/company/companyMutations';
import { Disableable } from '../../types/common';
import {
  Company,
  CompanyGoal,
  MutationCompanyGoal,
} from '../../types/company/types';
import { HabitListItem } from '../../types/habit/types';
import AppDatePicker from '../common/AppDatePicker';
import AppMultiSelectCheckbox from '../common/AppMultiSelectCheckbox';
import AppTextField from '../common/AppTextField';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import CompanyNameWithLogo from '../common/Company/CompanyNameWithLogo';
import HabitCategoryChip from '../common/Habit/HabitCategoryChip';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SettingsPanel from './SettingsPanel';

const useStyles = makeStyles(() => ({
  actionButton: {
    marginTop: 8,
  },
  inputRow: {
    marginTop: 8,
    '& p.MuiTypography-root': {
      marginTop: 20,
    },
  },
  actions: {
    padding: '24px 0 16px',
    borderBottom: '1px solid #DADADA',
    marginBottom: 16,
  },
  habitIcon: {
    '& svg': {
      height: 40,
      width: 40,
      fill: '#0A3751',
    },
  },
  chip: {
    marginBottom: 4,
  },
}));

const validationSchema = yup.object().shape({
  title: yup.string().required('Required'),
  companyIds: yup.array().of(yup.string()).min(1, 'Required'),
  categoryIds: yup.array().of(yup.string()).min(1, 'Required'),
  quarter: yup.number().required('Required'),
  completeByDate: yup.string().required('Required'),
  description: yup.string().required('Required'),
  actions: yup.array().of(
    yup
      .object()
      .shape({
        complete: yup.boolean(),
        title: yup.string().required('Required'),
      })
      .noUnknown(),
  ),
});

type GoalsForm = Omit<MutationCompanyGoal, 'createdByCompanyId' | 'quarter'> & {
  quarter: string;
};

type Props = Disableable & {
  habits: HabitListItem[];
  goalCategories: { id: string; name: string }[];
  goal?: CompanyGoal;
  onClose?: () => void;
  onCopy?: (goal: CompanyGoal) => void;
};

const ReductionGoalsPanel: React.FC<Props> = ({
  habits,
  goalCategories,
  disabled,
  goal,
  onClose,
  onCopy,
}) => {
  const classes = useStyles();
  const { company, divisions, allCompanies } = useContext(
    CompanySettingsContext,
  );

  const [upsertGoalMutation, { loading: upserting }] = useMutation(
    UPSERT_COMPANY_GOAL_MUTATION,
  );

  const [deleteGoalMutation, { loading: deleting }] = useMutation(
    DELETE_COMPANY_GOAL_MUTATION,
  );

  const companyTree = [company, ...divisions];

  return (
    <>
      <LoadingBackdrop open={upserting || deleting} />
      <SettingsPanel<GoalsForm>
        title="Reduction Plan"
        forceOpen={!!goal}
        onClose={onClose}
        disabled={disabled}
        formikProps={{
          initialValues: {
            title: goal?.title || '',
            categoryIds: goal?.categoryIds || [],
            companyIds: goal?.companyIds || [],
            quarter: goal?.quarter?.toString() || '',
            completeByDate: goal?.completeByDate || '',
            description: goal?.description || '',
            actions: goal?.actions || [],
            habitIds: goal?.habitIds || [],
          },
          validationSchema,
          onSubmit: async (values) => {
            await upsertGoalMutation({
              variables: {
                goalId: goal?.id,
                goal: {
                  ...values,
                  createdByCompanyId: company.id,
                  quarter: parseInt(values.quarter, 10),
                  actions: values.actions.map((a) => ({
                    complete: a.complete,
                    title: a.title,
                  })),
                },
              },
              awaitRefetchQueries: true,
              refetchQueries: companyTree.map(({ id }) => ({
                query: GET_COMPANY_GOALS,
                variables: { id, ignoreCache: true },
              })),
            });

            if (onClose) {
              onClose();
            }
          },
        }}
        onCopy={goal?.id && onCopy ? () => onCopy(goal) : undefined}
        onDelete={
          goal?.id
            ? async () => {
                await deleteGoalMutation({
                  variables: {
                    goalId: goal.id,
                    companyId: goal.createdByCompanyId,
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: companyTree.map(({ id }) => ({
                    query: GET_COMPANY_GOALS,
                    variables: { id, ignoreCache: true },
                  })),
                });

                if (onClose) {
                  onClose();
                }
              }
            : undefined
        }
        confirmDeleteLabel={`Are you sure you want to delete the goal "${goal?.title}"?`}
        launchButton={disabled ? undefined : { label: '+ Create New Goal' }}>
        {({ values, setFieldValue }) => (
          <>
            <Grid item>
              <Field
                name="title"
                component={AppTextField}
                disabled={disabled}
                placeholder="Fill in goal title here..."
                titleEditor
              />
            </Grid>
            <Grid item container direction="row" className={classes.inputRow}>
              <Grid item xs={3}>
                <Typography variant="body2">Categories:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Field
                  name="categoryIds"
                  data={goalCategories.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                  component={AppMultiSelectCheckbox}
                  displayEmpty
                  disabled={
                    disabled || (goal && goal.createdByCompanyId !== company.id)
                  }
                  renderValue={(selected: string[]) =>
                    selected && selected.length ? (
                      <>
                        {selected.map((id) => {
                          const cat = goalCategories.find((c) => c.id === id);
                          return (
                            <div key={cat?.id} className={classes.chip}>
                              <Chip label={cat?.name} color="primary" />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      'Select a Category'
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" className={classes.inputRow}>
              <Grid item xs={3}>
                <Typography variant="body2">Organization(s):</Typography>
              </Grid>
              <Grid item xs={9}>
                <Field
                  name="companyIds"
                  data={companyTree.map((x) => ({
                    value: x.id,
                    label: (
                      <CompanyNameWithLogo textVariant="body1" company={x} />
                    ),
                  }))}
                  component={AppMultiSelectCheckbox}
                  displayEmpty
                  disabled={
                    disabled || (goal && goal.createdByCompanyId !== company.id)
                  }
                  renderValue={(selected: string[]) =>
                    selected && selected.length ? (
                      <>
                        {selected.map((id) => (
                          <div key={id}>
                            <CompanyNameWithLogo
                              textVariant="body1"
                              company={
                                allCompanies.find((x) => x.id === id) as Company
                              }
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      'Not assigned'
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" className={classes.inputRow}>
              <Grid item xs={3}>
                <Typography variant="body2">Quarter:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Field
                  name="quarter"
                  select
                  disabled={disabled}
                  options={[
                    { value: 1, label: 'Q1' },
                    { value: 2, label: 'Q2' },
                    { value: 3, label: 'Q3' },
                    { value: 4, label: 'Q4' },
                  ]}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (x: string) => x || 'Not assigned',
                  }}
                  component={AppTextField}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" className={classes.inputRow}>
              <Grid item xs={3}>
                <Typography variant="body2">Complete By:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Field
                  name="completeByDate"
                  disabled={disabled}
                  component={AppDatePicker}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.inputRow}>
              <Typography variant="body2">Description:</Typography>
              <Field
                name="description"
                multiline
                disabled={disabled}
                rows={4}
                component={AppTextField}
              />
            </Grid>
            <Grid item className={classes.actions}>
              <Typography variant="h3">Actions</Typography>
              {values.actions.map((action, i) => (
                <Grid
                  item
                  container
                  direction="row"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classes.inputRow}>
                  <Grid item xs={1}>
                    <Checkbox
                      className={classes.actionButton}
                      size="small"
                      color="primary"
                      disabled={disabled}
                      checked={values.actions[i].complete}
                      onChange={() =>
                        setFieldValue(
                          `actions[${i}].complete`,
                          !values.actions[i].complete,
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      component={AppTextField}
                      placeholder="Add Action"
                      disabled={disabled}
                      name={`actions[${i}].title`}
                    />
                  </Grid>
                  {!disabled && (
                    <Grid item xs={1}>
                      <Button
                        className={classes.actionButton}
                        onClick={() =>
                          setFieldValue(
                            'actions',
                            values.actions.filter((x) => x !== action),
                          )
                        }>
                        <SVG.Delete />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
            {!disabled && (
              <Grid item container direction="row" justifyContent="flex-start">
                <SmallPaddedButton
                  variant="outlined"
                  onClick={() =>
                    setFieldValue('actions', [
                      ...values.actions,
                      { complete: false, title: '' },
                    ])
                  }>
                  + Add Action
                </SmallPaddedButton>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h3" style={{ marginTop: 24 }}>
                Supporting Habits
              </Typography>
              <Paper style={{ marginTop: 24 }}>
                {habits.map((habit) => (
                  <Grid
                    item
                    container
                    direction="row"
                    key={habit.id}
                    style={{ padding: 16 }}>
                    <Grid item container xs={1} alignContent="center">
                      <Checkbox
                        size="small"
                        color="primary"
                        disabled={disabled}
                        checked={values.habitIds.some((x) => x === habit.id)}
                        onChange={() =>
                          setFieldValue(
                            `habitIds`,
                            values.habitIds.some((x) => x === habit.id)
                              ? values.habitIds.filter((x) => x !== habit.id)
                              : [...values.habitIds, habit.id],
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={7}
                      alignContent="center"
                      style={{ paddingLeft: 8 }}>
                      <Typography variant="h3">{habit.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={4}
                      justifyContent="flex-end"
                      alignContent="center">
                      <HabitCategoryChip category={habit.category} />
                    </Grid>
                  </Grid>
                ))}
              </Paper>
            </Grid>
          </>
        )}
      </SettingsPanel>
    </>
  );
};

export default ReductionGoalsPanel;
