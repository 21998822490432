import { List, Grid, ListItemButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

const useStyles = makeStyles(() => ({
  item: {
    height: 80,
    borderBottom: `1px solid #DADADA`,
    paddingRight: 0,
  },
  link: {
    textDecoration: 'none',
    color: '#0A3751',
  },
}));

const items = [
  {
    label: 'FAQs',
    link: '/member/faqs',
  },
  {
    label: 'Contact Us',
    link: '/member/contact',
  },
  {
    label: 'Terms & Conditions',
    link: '/member/terms',
  },
  {
    label: 'Privacy',
    link: '/member/privacy',
  },
  {
    label: 'Manage My Data',
    link: '/member/manage-data',
  },
  {
    isDev: true,
    label: 'Development',
    link: '/member/development',
  },
];

const MemberSettingsList: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(AuthorizationContext);

  const showDev = !!user?.roles.includes('platform_admin');

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3">Settings</Typography>
      </Grid>
      <Grid item>
        <List>
          {items
            .filter((x) => !x.isDev || showDev)
            .map((item) => (
              <Link to={item.link} key={item.label} className={classes.link}>
                <ListItemButton className={classes.item}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography variant="h3">{item.label}</Typography>
                    <SVG.CaretRightSmall />
                  </Grid>
                </ListItemButton>
              </Link>
            ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default MemberSettingsList;
