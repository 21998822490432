import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import ReductionData from '../../../components/member/dashboard/ReductionData';
import NetEmissionsChart from '../../../components/member/dashboard/NetEmissionsChart';
import BenchmarksChart from '../../../components/member/dashboard/BenchmarksChart';
import theme, {
  FullPageWidth,
  HalfPageWidth,
} from '../../../theme/memberTheme';
import MyWaterUse from '../../../components/member/dashboard/MyWaterUse';
import CarbonFootprintChart from '../../../components/member/dashboard/CarbonFootprintChart';
import RecentlyAddedHabits from '../../../components/member/dashboard/RecentlyAddedHabits';
import SustainabilityInterests from '../../../components/member/dashboard/SustainabilityInterests';
import ReminderCards from '../../../components/member/dashboard/ReminderCards';

const MemberDashboard: React.FC = () => {
  const [chartTab, setChartTab] = useState('net');
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MemberLayout>
      <Grid container flexDirection="column" alignItems="center">
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
          }}>
          <ReminderCards />
        </Grid>
        <Grid item pb={2} container alignItems="flex-start" direction="column">
          <Typography align="left" variant="h1">
            Journey to ZeroMe
          </Typography>
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          justifyContent={isSm ? 'center' : 'space-between'}
          flexWrap={isSm ? 'wrap' : 'nowrap'}>
          <Grid item style={{ width: HalfPageWidth, position: 'relative' }}>
            <Tabs
              value={chartTab}
              onChange={(e, v) => setChartTab(v)}
              variant={isSm ? 'scrollable' : 'standard'}>
              <Tab label="Net Emissions" value="net" />
              <Tab label="Carbon Footprint" value="carbon" />
              <Tab label="Benchmarks" value="benchmarks" />
            </Tabs>
            {chartTab === 'net' && <NetEmissionsChart />}
            {chartTab === 'carbon' && <CarbonFootprintChart />}
            {chartTab === 'benchmarks' && <BenchmarksChart />}
          </Grid>
          <Grid item style={{ width: HalfPageWidth }}>
            <ReductionData />
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
            marginTop: isSm ? undefined : 32,
          }}>
          <MyWaterUse />
        </Grid>
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
            marginTop: 32,
          }}>
          <RecentlyAddedHabits />
        </Grid>
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
          }}>
          <Box pt={5} pb={5}>
            <Divider />
          </Box>
          <SustainabilityInterests />
        </Grid>
      </Grid>
    </MemberLayout>
  );
};

export default MemberDashboard;
