import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CompanyLogo from './CompanyLogo';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
}));

type Props = {
  companyIds: string[];
};

const CompanyLogos: React.FC<Props> = ({ companyIds }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      style={{ width: 40 + (companyIds.length - 1) * 20 }}>
      {companyIds.map((companyId, i) => (
        <CompanyLogo
          key={companyId}
          companyId={companyId}
          style={{ left: 0 + i * 20, position: 'absolute', top: -20 }}
        />
      ))}
    </div>
  );
};

export default CompanyLogos;
