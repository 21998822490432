import { gql, TypedDocumentNode } from '@apollo/client';
import { BudgetSettings } from '../../types/company/types';
import { PurchaseHistorySpendByCategory } from '../../types/dashboard/types';
import { ProjectPurchaseStatus } from '../../types/offsetProject/types';
import { ProjectPurchaseType } from '../../types/project/types';
import { COMPANY_BUDGET_SETTINGS_FIELDS } from '../company/company';

export const GET_PLATFORM_STATS = gql`
  query {
    platformStats {
      totalCompanies
    }
  }
` as TypedDocumentNode<{
  platformStats: {
    totalCompanies: number;
  };
}>;

export const GET_CLIENT_BUDGET_STATS = gql`
  query ($budgetDate: String) {
    clientBudgetStats(budgetDate: $budgetDate) {
      totalBudget
      totalSpend
    }
  }
` as TypedDocumentNode<{
  clientBudgetStats: {
    totalBudget: number;
    totalSpend: number;
  };
}>;

export const GET_TRADER_BUDGET_SETTINGS = gql`
  query traderSettings($budgetDate: String) {
    traderSettings {
      budget(budgetDate: $budgetDate) {
        total
        remaining
        projectCount
        spent
        spentMt
        spentMWh
      }
      budgetSettings {
        ${COMPANY_BUDGET_SETTINGS_FIELDS}
      }
    }
  }
` as TypedDocumentNode<{
  traderSettings: {
    budget: {
      remaining: number;
      total: number;
      projectCount: number;
      spent: number;
      spentMt: number;
      spentMWh: number;
    };
    budgetSettings: BudgetSettings;
  };
}>;

export const UPSERT_TRADER_BUDGET_SETTINGS_MUTATION = gql`
  mutation upsertTraderBudgetSettings($settings: BudgetSettingsInput!) {
    upsertTraderBudgetSettings(settings: $settings) {
      ${COMPANY_BUDGET_SETTINGS_FIELDS}
    }
  }
`;

export type TotalSpendResponse = {
  total: number;
  mt: number;
  companyCount: number;
  participantCount: number;
  projectCount: number;
  allocatedMt: number;
  allocatedValue: number;
};

export const GET_TOTAL_PURCHASE_DATA = gql`
  query totalSpend(
    $startDate: String!
    $endDate: String!
    $companyId: String
    $purchaseTypes: [ProjectPurchaseType]
    $statuses: [ProjectPurchaseHistoryStatus]
    $includeChildren: Boolean
    $includeDemo: Boolean
  ) {
    totalSpend(
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      purchaseTypes: $purchaseTypes
      statuses: $statuses
      includeChildren: $includeChildren
      includeDemo: $includeDemo
    ) {
      total
      mt
      companyCount
      participantCount
      projectCount
      allocatedMt
      allocatedValue
    }
  }
` as TypedDocumentNode<
  {
    totalSpend: TotalSpendResponse;
  },
  {
    startDate: string;
    endDate: string;
    companyId?: string;
    includeChildren?: boolean;
    purchaseTypes?: ProjectPurchaseType[];
    statuses?: ProjectPurchaseStatus[];
  }
>;

export type TotalSpendByPeriodResponse = {
  date: string;
  total: number;
  mt: number;
  totalMt: number;
  mwh: number;
  totalMWh: number;
  status: ProjectPurchaseStatus;
  purchaseType: ProjectPurchaseType;
};

export const GET_CLIENT_PURCHASE_DATA = gql`
  query totalSpendByPeriod(
    $startDate: String!
    $endDate: String!
    $dateGrain: DateGrain!
    $companyId: String
    $includeChildren: Boolean
    $includeDemo: Boolean
    $purchaseTypes: [ProjectPurchaseType]
    $statuses: [ProjectPurchaseHistoryStatus]
  ) {
    totalSpendByPeriod(
      startDate: $startDate
      endDate: $endDate
      dateGrain: $dateGrain
      companyId: $companyId
      includeChildren: $includeChildren
      includeDemo: $includeDemo
      purchaseTypes: $purchaseTypes
      statuses: $statuses
    ) {
      date
      total
      mt
      totalMt
      mwh
      totalMWh
      status
      purchaseType
    }
  }
` as TypedDocumentNode<
  {
    totalSpendByPeriod: TotalSpendByPeriodResponse[];
  },
  {
    startDate: string;
    endDate: string;
    dateGrain: 'MONTHLY' | 'YEARLY';
    companyId?: string;
    includeChildren?: boolean;
    includeDemo?: boolean;
    purchaseTypes?: ProjectPurchaseType[];
    statuses?: ProjectPurchaseStatus[];
  }
>;

export const GET_SPEND_BY_CATEGORY = gql`
  query spendByCategory(
    $startDate: String!
    $endDate: String!
    $dateGrain: DateGrain!
  ) {
    spendByCategory(
      startDate: $startDate
      endDate: $endDate
      dateGrain: $dateGrain
    ) {
      date
      total
      mt
      mwh
      category {
        id
        label
        color
      }
    }
  }
` as TypedDocumentNode<
  {
    spendByCategory: PurchaseHistorySpendByCategory[];
  },
  {
    startDate: string;
    endDate: string;
    dateGrain: 'MONTHLY' | 'YEARLY';
  }
>;

export default {
  GET_CLIENT_PURCHASE_DATA,
  GET_PLATFORM_STATS,
  GET_TRADER_BUDGET_SETTINGS,
  UPSERT_TRADER_BUDGET_SETTINGS_MUTATION,
  GET_SPEND_BY_CATEGORY,
};
