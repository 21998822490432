import React, { useEffect, useMemo, useState } from 'react';

import { Typography, Tabs, Tab, Grid, Chip } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';

import MemberLayout from '../../../components/layouts/MemberLayout';
import {
  ADD_HABIT,
  MEMBER_HABITS,
  MEMBER_LIFESTYLE_HABITS,
  REMOVE_HABIT,
} from '../../../graphql/habit/memberHabits';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import MemberHabitCard from '../../../components/member/myhabits/MemberHabitCard';
import { HABIT_CATEGORIES } from '../../../graphql/habit/habits';
import AppCarousel from '../../../components/common/AppCarousel';
import MemberSnackbar from '../../../components/member/myhabits/MemberSnackbar';
import useRemoveQuestionRelatedData from '../../../hooks/cacheModifiers/useRemoveQuestionRelatedData';

export type MemberHabitType = 'HABIT' | 'LIFESTYLE';

const chip = {
  borderRadius: 28,
  padding: 4,
  marginRight: 4,
};

const useStyles = makeStyles((theme) => ({
  selectedChip: {
    ...chip,
  },
  unselectedChip: {
    ...chip,
    backgroundColor: theme.palette.primary.light,
  },
  chipContainer: {
    marginTop: 16,
  },
}));

const MemberHabits: React.FC = () => {
  const classes = useStyles();
  const [listTab, setListTab] = useState<MemberHabitType>('HABIT');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [messages, setMessages] = useState<
    { title: string; description: string; iconUrl: string }[]
  >([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  useEffect(() => {
    if (tab === 'myhabits') {
      setListTab('LIFESTYLE');
    }
  }, [tab]);

  const history = useHistory();

  const {
    data: { habitCategories = [] } = {},
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(HABIT_CATEGORIES);

  const { remove: removeQuestionRelatedData } = useRemoveQuestionRelatedData();

  const [addHabit, { loading: addLoading, error: addError }] = useMutation(
    ADD_HABIT,
    {
      onCompleted: removeQuestionRelatedData,
    },
  );
  const [removeHabit, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_HABIT, {
      onCompleted: removeQuestionRelatedData,
    });

  const actionLoading = addLoading || removeLoading;
  const actionError = addError || removeError;

  const publishDate = useMemo(() => new Date().toISOString(), []);

  const {
    data: { habits = [] } = {},
    loading,
    error,
  } = useQuery(MEMBER_HABITS, {
    variables: {
      filter: {
        orderBy: 'RECENTLY_ADDED',
        publishDate,
      },
    },
  });

  const {
    data: { me: { lifestyleHabits = [] } = {} } = {},
    loading: lifestyleLoading,
    error: lifestyleError,
  } = useQuery(MEMBER_LIFESTYLE_HABITS);

  const handleAction = (id: string) => {
    const mutation = listTab === 'HABIT' ? addHabit : removeHabit;
    mutation({
      variables: { habitId: id },
      refetchQueries: [MEMBER_HABITS, MEMBER_LIFESTYLE_HABITS],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data.convertHabit?.messages?.length) {
          setMessages(data.convertHabit.messages);
          setShowSnackbar(true);
        }
      },
    });
  };
  const list = listTab === 'HABIT' ? habits : lifestyleHabits;

  // featured habits are the 3 most recently added habits that are not
  // a lifestyle habit of the user
  const featured = [...habits]
    .filter((o) => lifestyleHabits.findIndex((find) => find.id === o.id) === -1)
    .slice(0, 3);

  let filtered =
    categoryFilter === 'all'
      ? list
      : list.filter((o) => o.category.id === categoryFilter);

  if (listTab === 'HABIT') {
    filtered = filtered.filter(
      (o) => lifestyleHabits.findIndex((find) => find.id === o.id) === -1,
    );
  }

  const finalList =
    listTab === 'HABIT'
      ? filtered.filter(
          (o) => featured.findIndex((find) => find.id === o.id) === -1,
        )
      : filtered;
  return (
    <MemberLayout>
      <DataStateHandler
        loading={loading || categoriesLoading || lifestyleLoading}
        error={error || categoriesError || lifestyleError}>
        <Typography variant="h3">Recently Added</Typography>
        <AppCarousel
          items={featured.map((f) => (
            <MemberHabitCard
              key={f.id}
              cardType="FEATURED"
              habit={f}
              imageHeight={400}
              onClick={(id) => history.push(`/member/myhabits/${id}`)}
            />
          ))}
        />

        <Tabs
          value={listTab}
          onChange={(e, v) => setListTab(v)}
          variant="scrollable">
          <Tab label="Browse Habits" value="HABIT" />
          <Tab label="My Habits" value="LIFESTYLE" />
        </Tabs>
        <DataStateHandler loading={actionLoading} error={actionError}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.chipContainer}>
              <Chip
                className={
                  categoryFilter === 'all'
                    ? classes.selectedChip
                    : classes.unselectedChip
                }
                color={categoryFilter === 'all' ? 'primary' : 'default'}
                key="all"
                label="All"
                onClick={() => setCategoryFilter('all')}
              />
              {habitCategories
                .filter((o) => o.id !== 'transportation')
                .map((hc) => (
                  <Chip
                    className={
                      categoryFilter === hc.id
                        ? classes.selectedChip
                        : classes.unselectedChip
                    }
                    key={hc.id}
                    color={categoryFilter === hc.id ? 'primary' : 'default'}
                    label={hc.label}
                    onClick={() => setCategoryFilter(hc.id)}
                  />
                ))}
            </Grid>
            {finalList.length === 0 ? (
              <Grid item xs={12}>
                <Typography align="center" variant="h3">
                  {listTab === 'HABIT'
                    ? `There doesn't seem to be anything related to what you're looking for`
                    : `You don't currently have any habits.`}
                </Typography>
              </Grid>
            ) : null}
            {finalList.map((h) => (
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                key={h.id}
                data-testid="member-habits-list-card">
                <MemberHabitCard
                  cardType={listTab}
                  habit={h}
                  imageHeight={169}
                  onAction={handleAction}
                  onClick={(id) =>
                    history.push(
                      `/member/myhabits/${id}${
                        listTab === 'LIFESTYLE' ? '?returnTo=myhabits' : ''
                      }`,
                    )
                  }
                />
              </Grid>
            ))}
            {messages.map((message) => (
              <MemberSnackbar
                key={message.title}
                open={showSnackbar}
                snackbarContent={message}
              />
            ))}
          </Grid>
        </DataStateHandler>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberHabits;
