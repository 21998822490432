import { useMutation, useQuery } from '@apollo/client';
import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';

import {
  COMMUNICATION_QUERY,
  UPSERT_COMMUNICATION,
  DELETE_COMMUNICATION,
} from '../../graphql/communications/communications';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsFooter from './CommunicationDetailsFooter';
import CommunicationDetailsHeader from './CommunicationDetailsHeader';
import CommunicationForm from './forms/CommunicationForm';
import IncludeChildrenSwitch from './forms/IncludeChildrenSwitch';
import { getSchemaByContentType } from './forms/schema';
import { CommunicationType } from './types';

type Props = {
  communicationId: string;
  communicationType: CommunicationType;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
  },
  paper: {
    padding: 24,
  },
}));

const CommunicationDetails: React.FC<Props> = ({
  communicationId,
  communicationType,
  onClose,
}) => {
  const {
    loading,
    error,
    data: { communication = {} } = {},
  } = useQuery(COMMUNICATION_QUERY, {
    variables: { id: communicationId },
    skip: communicationId === 'new',
  });

  const { companyId, refetchCommunicationsQuery } = useContext(
    CommunicationsContext,
  );

  const [upsert, { loading: upsertLoading, error: upsertError }] =
    useMutation(UPSERT_COMMUNICATION);

  const [deleteComm, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_COMMUNICATION);

  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const initialValues = {
    title: '',
    isFeatured: false,
    author: '',
    content: '',
    communicationType,
    categoryIds: [],
    publishDate: '',
    includeChildren: false,
    ...communication,
    hasBeenUnpublished: false,
  };

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Discover', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Discover', 'EDIT');

  const showIncludeChildren =
    companyId && userHasAccess('Api.AllowParentQueries', 'VIEW');

  const onDelete = async () => {
    await deleteComm({
      variables: { communicationId },
      onCompleted: () => onClose(),
    });

    refetchCommunicationsQuery();
  };

  return (
    <DataStateHandler
      loading={loading || upsertLoading || deleteLoading}
      error={error || upsertError || deleteError}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getSchemaByContentType(communicationType)}
        onSubmit={async (values) => {
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            createdDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updatedDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            contentUploadUrl,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            headerImageUrl,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            __typename,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            hasBeenUnpublished,
            ...rest
          } = values;
          const input = {
            ...rest,
            ...(companyId ? { companyIds: [companyId] } : {}),
          };

          await upsert({
            variables: { input },
            onCompleted: () => onClose(),
          });

          refetchCommunicationsQuery();
        }}
        validateOnMount>
        {(props) => (
          <Grid
            container
            direction="column"
            className={classes.root}
            spacing={2}>
            <Grid item>
              <CommunicationDetailsHeader
                title={
                  communicationId
                    ? `${canEdit ? 'Edit' : 'View'} Details`
                    : 'Create New'
                }
                communicationType={communicationType}
                canEdit={canEdit}
                onClose={onClose}
                onSave={props.handleSubmit}
                saveDisabled={!props.isValid || props.isSubmitting}
              />
            </Grid>

            <Grid item className={classes.form}>
              <Paper className={classes.paper}>
                {showIncludeChildren ? (
                  <IncludeChildrenSwitch disabled={!canEdit} />
                ) : null}
                <CommunicationForm
                  communicationType={communicationType}
                  disabled={!canEdit}
                />
                {canEdit && (
                  <CommunicationDetailsFooter
                    onDelete={communicationId === 'new' ? undefined : onDelete}
                    onSave={props.handleSubmit}
                    saveDisabled={!props.isValid || props.isSubmitting}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
    </DataStateHandler>
  );
};

export default CommunicationDetails;
