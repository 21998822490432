import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { isNumber, pick } from 'lodash';
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import CreateProjectAssign from '../components/trader/CreateProject/CreateProjectAssign';
import CreateProjectDetails from '../components/trader/CreateProject/CreateProjectDetails';
import CreateProjectReview from '../components/trader/CreateProject/CreateProjectReview';
import createProjectSchema from '../components/trader/CreateProject/createProjectSchema';
import CreateProjectAdditionalDetails from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectAdditionalDetails';
import CreateProjectAdditionalImages from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectAdditionalImages';
import CreateProjectAuditInformation from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectAuditInformation';
import CreateProjectDescription from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectDescription';
import CreateProjectDocuments from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectDocuments';
import CreateProjectLocation from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectLocation';
import CreateProjectTitle from '../components/trader/CreateProject/CreateProjectSteps/CreateProjectTitle';
import ProjectSavingModal from '../components/trader/CreateProject/ProjectSavingModal';
import { GET_ALL_CATEGORIES } from '../graphql/projects/projectCategories';
import { GET_ALL_PROJECT_ENUMS } from '../graphql/projects/projectEnums';
import {
  CREATE_PROJECT_MUTATION,
  UPDATE_PROJECT_MUTATION,
} from '../graphql/projects/projectMutations';
import { generateProjectByIdQuery } from '../graphql/projects/projects';
import { GET_ALL_COUNTRIES, GET_ALL_REGIONS } from '../graphql/settings';
import {
  ActionMap,
  FileAttachment,
  ISOCountry,
  ISORegion,
} from '../types/common';
import {
  Project,
  ProjectAuditor,
  ProjectCategory,
  ProjectCcbSdgAuditor,
  ProjectIndicator,
  ProjectSource,
  ProjectSponsor,
  ProjectUom,
} from '../types/project/types';
import EnumUtils from '../utils/enumUtils';
import { AuthorizationContext } from './AuthorizationContext';
import { DEFAULT_CODE_MESSAGES } from '../components/common/DataStateHandler/DataStateHandler';

export type EditableProject = Project & {
  headerImage?: FileAttachment;
  additionalImages?: FileAttachment[];
  additionalImageIndexesToRemove?: number[];
  documents?: FileAttachment[];
  documentIndexesToRemove?: number[];
  // Needed to clean up object
  __typename?: string;
};

export enum ProjectDetailsStep {
  title = 1,
  description,
  location,
  details,
  images,
  audit,
  documents,
}

// #region Project Steps
export enum ProjectStep {
  details = 1,
  assign,
  review,
}

const allProjectSteps = EnumUtils.values(ProjectStep);

export type ProjectStepData = {
  title: string;
  stepComponent: React.FC<Record<string, never>>;
  valid: (project: EditableProject) => boolean;
  forceValidation: boolean;
};

const initialProjectSteps: Record<ProjectStep, ProjectStepData> = {
  [ProjectStep.details]: {
    title: 'Project Details',
    stepComponent: CreateProjectDetails,
    valid: (project) =>
      Object.values(ProjectDetailsStep)
        .filter((x) => isNumber(x))
        .every((x) =>
          (project.completedSteps || []).includes(x as ProjectDetailsStep),
        ),
    forceValidation: false,
  },
  [ProjectStep.assign]: {
    title: 'Assign',
    stepComponent: CreateProjectAssign,
    valid: (project) =>
      project.individualScope ||
      !project.companies ||
      !!project.companies.length,
    forceValidation: false,
  },
  [ProjectStep.review]: {
    title: 'Review',
    stepComponent: CreateProjectReview,
    valid: () => true,
    forceValidation: false,
  },
};

type ProjectStepState = {
  steps: Record<ProjectStep, ProjectStepData>;
  currentStep: ProjectStep;
};

const initialProjectStepState: ProjectStepState = {
  steps: initialProjectSteps,
  currentStep: ProjectStep.details,
};

const enum ProjectStepStateActionType {
  goToStep = 'GO_TO_STEP',
  forceValidation = 'FORCE_VALIDATION',
  reset = 'RESET',
}

type ProjectStepStatePayload = {
  [ProjectStepStateActionType.forceValidation]: never;
  [ProjectStepStateActionType.reset]: never;
  [ProjectStepStateActionType.goToStep]: {
    step: ProjectStep;
    project: Project;
  };
};

type ProjectStepStateActions =
  ActionMap<ProjectStepStatePayload>[keyof ActionMap<ProjectStepStatePayload>];

const projectStepStateReducer = (
  state: ProjectStepState,
  action: ProjectStepStateActions,
) => {
  switch (action.type) {
    case ProjectStepStateActionType.forceValidation: {
      return {
        ...state,
        steps: {
          ...state.steps,
          [state.currentStep]: {
            ...state.steps[state.currentStep],
            forceValidation: true,
          },
        },
      };
    }
    case ProjectStepStateActionType.goToStep: {
      const { step, project } = action.payload;
      if (allProjectSteps.includes(step)) {
        const currentStep = state.steps[state.currentStep];

        if (currentStep.valid(project)) {
          return {
            ...state,
            currentStep: step,
          };
        }
      }
      return state;
    }

    case ProjectStepStateActionType.reset:
      return initialProjectStepState;

    default:
      return state;
  }
};

// #endregion

// #region Project Details Steps
export type ProjectDetailsStepData = {
  step: ProjectDetailsStep;
  title: string;
  stepComponent: React.FC;
  validationSchema?: typeof createProjectSchema[keyof typeof createProjectSchema];
  valid: boolean;
};

const initialProjectDetailsSteps: ProjectDetailsStepData[] = [
  {
    step: ProjectDetailsStep.title,
    title: 'Add project image, category, and title',
    stepComponent: CreateProjectTitle,
    validationSchema: createProjectSchema.titleSchema,
    valid: true,
  },
  {
    step: ProjectDetailsStep.description,
    title: 'Project Description',
    stepComponent: CreateProjectDescription,
    validationSchema: createProjectSchema.descriptionSchema,
    valid: true,
  },
  {
    step: ProjectDetailsStep.location,
    title: 'Set project location',
    stepComponent: CreateProjectLocation,
    validationSchema: createProjectSchema.locationSchema,
    valid: true,
  },
  {
    step: ProjectDetailsStep.details,
    title: 'Additional project details',
    stepComponent: CreateProjectAdditionalDetails,
    validationSchema: createProjectSchema.additionalDetailsSchema,
    valid: true,
  },
  {
    step: ProjectDetailsStep.images,
    title: 'Add additional images',
    stepComponent: CreateProjectAdditionalImages,
    valid: true,
  },
  {
    step: ProjectDetailsStep.audit,
    title: 'Audit information',
    stepComponent: CreateProjectAuditInformation,
    validationSchema: createProjectSchema.auditInformationSchema,
    valid: true,
  },
  {
    step: ProjectDetailsStep.documents,
    title: 'Documents',
    stepComponent: CreateProjectDocuments,
    valid: true,
  },
];
// #endregion

export type SaveState = {
  state: 'PENDING' | 'SUCCESS' | 'ERROR' | 'COST_PER_MT_WARNING';
  costPerUnitWarningPrice?: number;
  error?: string;
};

export type SaveProjectProps = {
  forceNotDraft?: boolean;
  ignoreCostPerUnitCheck?: boolean;
  projectOverride?: EditableProject;
};

type ProjectContext = {
  project: EditableProject;
  editable: boolean;
  hideTraderFields: boolean;
  loading: boolean;
  // so we don't hide the whole UI if we need to reload regions
  regionsLoading: boolean;
  currentStep: ProjectStepState['currentStep'];
  steps: ProjectStepState['steps'];
  goToStep: (step: ProjectStep) => void;
  forceStepValidation: () => void;
  isStepDisabled: (step: ProjectStep) => boolean;
  currentDetailsStep: ProjectDetailsStep;
  setCurrentDetailsStep: (
    step: ProjectDetailsStep,
    isComingFromModal?: boolean,
  ) => void;
  incrementCurrentDetailsStep: () => void;
  setProjectDetailsStepIsValid: (
    step: ProjectDetailsStep,
    isValid: boolean,
  ) => void;
  projectDetailsSteps: ProjectDetailsStepData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setProjectField: (key: string, value: any) => void;
  setTypedProjectField: <ProjectKey extends keyof EditableProject>(
    key: ProjectKey,
    value: EditableProject[ProjectKey],
  ) => void;
  shouldShowLocationModalNextStep: ProjectDetailsStep | false;
  setShouldShowLocationModalNextStep: Dispatch<
    SetStateAction<ProjectContext['shouldShowLocationModalNextStep']>
  >;
  projectAuditors: ProjectAuditor[];
  projectCategories: ProjectCategory[];
  projectCcbSdgAuditors: ProjectCcbSdgAuditor[];
  projectIndicators: ProjectIndicator[];
  projectSources: ProjectSource[];
  projectSponsors: ProjectSponsor[];
  projectUoms: ProjectUom[];
  allCountries: ISOCountry[];
  allRegions: ISORegion[];
  saveProject: (props?: SaveProjectProps) => void;
  updateProject: (project: EditableProject, forceSave?: boolean) => void;
  resetContext: () => void;
};

const initialContext: ProjectContext = {
  project: {
    isDraft: true,
  } as unknown as EditableProject,
  editable: false,
  hideTraderFields: false,
  loading: true,
  regionsLoading: false,
  currentStep: ProjectStep.details,
  steps: initialProjectStepState.steps,
  isStepDisabled: () => true,
  goToStep: () => null,
  forceStepValidation: () => null,
  currentDetailsStep: ProjectDetailsStep.title,
  setCurrentDetailsStep: () => null,
  incrementCurrentDetailsStep: () => null,
  setProjectDetailsStepIsValid: () => null,
  projectDetailsSteps: initialProjectDetailsSteps,
  setProjectField: () => null,
  setTypedProjectField: () => null,
  shouldShowLocationModalNextStep: false,
  setShouldShowLocationModalNextStep: () => false,
  projectAuditors: [],
  projectCategories: [],
  projectCcbSdgAuditors: [],
  projectIndicators: [],
  projectSources: [],
  projectSponsors: [],
  projectUoms: [],
  allCountries: [],
  allRegions: [],
  saveProject: () => null,
  updateProject: () => null,
  resetContext: () => null,
};

export const ProjectContext = createContext(initialContext);

export enum ProjectContextEvents {
  RELOAD = 'ProjectContext_RELOAD',
}

type Props = {
  projectId?: string;
  initialProject?: Project;
  editable?: boolean;
  hideTraderFields?: boolean;
  saveAfterSectionEdit?: boolean;
  createProjectFlow?: boolean;
};

const ProjectProvider: FC<Props> = ({
  projectId,
  initialProject,
  editable,
  hideTraderFields,
  saveAfterSectionEdit,
  children,
  createProjectFlow,
}) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const [project, _setProject] = useState<EditableProject>(
    initialContext.project,
  );
  const [saveState, setSaveState] = useState<SaveState>();
  const [currentDetailsStep, setCurrentDetailsStepState] = useState(
    ProjectDetailsStep.title,
  );
  const [projectDetailsSteps, setProjectDetailsSteps] = useState(
    initialProjectDetailsSteps,
  );
  const [shouldShowLocationModalNextStep, setShouldShowLocationModalNextStep] =
    useState<ProjectDetailsStep | false>(false);

  const setProject = (newProject: EditableProject, reset: boolean) => {
    const finalProject = {
      ...newProject,
      startDate: newProject.startDate || null,
      endDate: newProject.endDate || null,
      verAuditReportDate: newProject.verAuditReportDate || null,
      ccbSdgReportDate: newProject.ccbSdgReportDate || null,
      sellingPrice: newProject.sellingPrice
        ? parseFloat(newProject.sellingPrice.toString())
        : null,
      costPerUnit: newProject.costPerUnit
        ? parseFloat(newProject.costPerUnit.toString())
        : null,
      availableQuantity: newProject.availableQuantity
        ? parseInt(newProject.availableQuantity.toString(), 10)
        : null,
      sdVista: newProject.sdVista,
      completedSteps: projectDetailsSteps
        .filter((x) => {
          const valid =
            (newProject.visitedSteps || []).includes(x.step) &&
            (!x.validationSchema || x.validationSchema.isValidSync(newProject));

          return valid;
        })
        .map((x) => x.step),
    };

    _setProject(finalProject);

    if (reset) {
      document.dispatchEvent(
        new CustomEvent<Project>(ProjectContextEvents.RELOAD, {
          detail: finalProject,
        }),
      );
    }
  };

  useEffect(
    () => initialProject && setProject(initialProject, true),
    [initialProject],
  );

  // #region Project Steps
  const [projectStepState, projectStepStateDispatch] = useReducer(
    projectStepStateReducer,
    initialProjectStepState,
  );

  const isStepDisabled = (step: ProjectStep) =>
    !allProjectSteps.includes(step) ||
    allProjectSteps.reduce<boolean>((disabled, currStep) => {
      const currentStep = projectStepState.steps[currStep];
      return currStep < step
        ? disabled || !currentStep.valid(project)
        : disabled;
    }, false);

  const setProjectDetailsStepIsValid = (
    step: ProjectDetailsStep,
    isValid: boolean,
  ) => {
    setProjectDetailsSteps((steps) =>
      steps.map((detailsStep) =>
        detailsStep.step !== step
          ? detailsStep
          : {
              ...detailsStep,
              valid: isValid,
            },
      ),
    );
  };

  const setCurrentDetailsStep = (
    step: ProjectDetailsStep,
    isComingFromModal?: boolean,
  ): void => {
    if (
      currentDetailsStep === ProjectDetailsStep.location &&
      !isComingFromModal
    ) {
      // step is location, need to handle this a little differently
      setShouldShowLocationModalNextStep(step);
    } else {
      setProject(
        {
          ...project,
          visitedSteps: [...new Set([...(project.visitedSteps || []), step])],
        },
        false,
      );

      setShouldShowLocationModalNextStep(false);
      const stepData = projectDetailsSteps.find((s) => s.step === step);

      if (stepData) {
        const el = document.getElementById(`create-project-${stepData.step}`);

        if (el) {
          el.scrollIntoView({ block: 'center' });
        }
        setCurrentDetailsStepState(step);
      }
    }
  };

  const goToStep = (step: ProjectStep) =>
    projectStepStateDispatch({
      type: ProjectStepStateActionType.goToStep,
      payload: { step, project },
    });

  const forceStepValidation = () => {
    projectStepStateDispatch({
      type: ProjectStepStateActionType.forceValidation,
    });

    const firstIncompleteStep = initialProjectDetailsSteps
      .map((x) => x.step)
      .find((x) => !(project.completedSteps || []).includes(x));

    if (firstIncompleteStep) {
      setCurrentDetailsStep(firstIncompleteStep);
    }
  };

  const incrementCurrentDetailsStep = (): void => {
    const nextStep = currentDetailsStep + 1;
    if (nextStep <= initialProjectDetailsSteps.length) {
      setCurrentDetailsStep(nextStep);
    }
  };

  // #endregion

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setProjectField = (key: string, value: any): void => {
    const newProject = {
      ...project,
      [key]: value,
      ...(key === 'country' && value !== project.country ? { region: '' } : {}),
    };
    setProject(newProject, false);
  };

  const { loading: projectLoading } = useQuery<{ project: Project }>(
    generateProjectByIdQuery(userHasAccess),
    {
      skip: !projectId || projectId === 'new',
      variables: {
        projectId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
      onCompleted: (data) => {
        setProject(
          {
            ...data.project,
            visitedSteps: [
              ...new Set([
                ...(data.project.visitedSteps || []),
                ProjectDetailsStep.title,
              ]),
            ],
          },
          true,
        );
      },
    },
  );

  const {
    loading: enumsLoading,
    data: {
      projectAuditors = [],
      projectCcbSdgAuditors = [],
      projectIndicators = [],
      projectSources = [],
      projectSponsors = [],
      projectUoms = [],
    } = {},
  } = useQuery(GET_ALL_PROJECT_ENUMS);

  const { loading: categoriesLoading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const { loading: countriesLoading, data: { allCountries = [] } = {} } =
    useQuery<{ allCountries: ISOCountry[] }>(GET_ALL_COUNTRIES);

  const { loading: regionsLoading, data: { allRegions = [] } = {} } = useQuery<{
    allRegions: ISORegion[];
  }>(GET_ALL_REGIONS, {
    skip: !project.country,
    variables: {
      countryCode: project.country,
    },
  });

  const loading =
    projectLoading || enumsLoading || categoriesLoading || countriesLoading;

  const [createProjectMutation] = useMutation<
    { createProject: Project },
    { project: Omit<EditableProject, 'id' | 'costPerUnit' | 'uom'> }
  >(CREATE_PROJECT_MUTATION, {
    variables: { project },
  });

  const [updateProjectMutation] = useMutation<
    { updateProject: Project },
    {
      project: Omit<EditableProject, 'id' | 'costPerUnit' | 'uom'>;
      projectId: string;
    }
  >(UPDATE_PROJECT_MUTATION);

  const saveProject = useCallback(
    async ({
      forceNotDraft,
      ignoreCostPerUnitCheck,
      projectOverride,
    }: SaveProjectProps = {}) => {
      setSaveState({ state: 'PENDING' });
      const projectInputFields: (keyof EditableProject)[] = [
        'isDraft',
        'isIndicative',
        'isActive',
        'title',
        'category',
        'headerImage',
        'headerImagePosition',

        'description',
        'smallSummary',

        'country',
        'region',
        'postalCode',
        'address',
        'place',

        'isFeatured',
        'isDemoProject',
        'sponsor',
        'startDate',
        'endDate',
        'availableQuantity',
        'source',
        'projectId',
        'vintage',
        'indicator',
        'sellingPrice',
        'auditor',
        'sdVista',
        'projectHQ',
        'costPerUnit',
        'additionalImages',
        'additionalImageIndexesToRemove',
        'uom',
        'verAuditReportDate',
        'ccbSdgAuditor',
        'ccbSdgReportDate',

        'documents',
        'documentIndexesToRemove',

        'companies',
        'individualScope',
        'visitedSteps',
        'completedSteps',
      ];

      const finalProject = {
        ...pick(projectOverride || project, projectInputFields),
        ...(forceNotDraft ? { isDraft: false } : {}),
      };

      if (!finalProject.isDraft) {
        if (!ignoreCostPerUnitCheck) {
          // Validate that selling price isn't below cost per MT
          const costPerUnit =
            (finalProject.purchasePrice as number) /
            (finalProject.availableQuantity as number);

          if ((finalProject.sellingPrice || 0) < costPerUnit) {
            setSaveState({
              state: 'COST_PER_MT_WARNING',
              costPerUnitWarningPrice: costPerUnit,
            });

            return;
          }
        }
      }

      let result: Project;

      // This SHOULD NOT be finalProject, id gets stripped from that!
      const projectIdToSave = project.id;

      try {
        result = (
          projectIdToSave
            ? (
                await updateProjectMutation({
                  variables: {
                    project: finalProject,
                    projectId: projectIdToSave,
                  },
                })
              ).data?.updateProject
            : (
                await createProjectMutation({
                  variables: {
                    project: finalProject,
                  },
                })
              ).data?.createProject
        ) as Project;

        setProject(result, true);
        setSaveState({ state: 'SUCCESS' });
      } catch (error) {
        let code = '';

        if (error instanceof ApolloError) {
          code = error.graphQLErrors[0]?.extensions?.code;
        }

        switch (code) {
          case 'DUPLICATE_PROJECT_ID':
            setCurrentDetailsStep(ProjectDetailsStep.details, true);
            setSaveState({
              state: 'ERROR',
              error:
                'The project ID specified is not unique.  Please choose a different project ID.',
            });
            break;

          case 'QUANTITY_ALREADY_PURCHASED':
            setCurrentDetailsStep(ProjectDetailsStep.details, true);
            setSaveState({
              state: 'ERROR',
              error:
                'The quantity available cannot be adjusted to the amount you specified because more than that amount has already been purchased.',
            });
            break;

          case 'INDIVIDUAL_SCOPE_ALREADY_EXISTS':
            setSaveState({
              state: 'ERROR',
              error:
                'There currently can only be one non-draft individual scope project.',
            });
            break;

          default:
            setSaveState({
              state: 'ERROR',
              error:
                DEFAULT_CODE_MESSAGES[code] ||
                'There was an error saving your project.  Please try again.',
            });
            break;
        }
      }
    },
    [project],
  );

  const updateProject = (newProject: EditableProject, forceSave?: boolean) => {
    setProject(newProject, true);

    if (saveAfterSectionEdit || forceSave) {
      saveProject({
        projectOverride: newProject,
      });
    }
  };

  const filteredSources = projectSources.filter((p) => {
    if (project && project.uom) {
      return p.uoms.includes(project.uom);
    }

    return false;
  });

  const value = useMemo(
    () => ({
      project,
      editable: !!editable,
      hideTraderFields: !!hideTraderFields,
      loading,
      regionsLoading,
      currentStep: projectStepState.currentStep,
      steps: projectStepState.steps,
      isStepDisabled,
      goToStep,
      forceStepValidation,
      setCurrentDetailsStep,
      incrementCurrentDetailsStep,
      currentDetailsStep,
      projectDetailsSteps,
      setProjectDetailsStepIsValid,
      setProjectField,
      setTypedProjectField: setProjectField,
      shouldShowLocationModalNextStep,
      setShouldShowLocationModalNextStep,
      projectAuditors,
      projectCategories,
      projectCcbSdgAuditors,
      projectIndicators,
      projectSources: filteredSources,
      projectSponsors,
      projectUoms,
      allCountries,
      allRegions,
      saveProject,
      updateProject,
      resetContext: () => {
        setSaveState(undefined);
        projectStepStateDispatch({
          type: ProjectStepStateActionType.reset,
        });
        setProject(initialContext.project, true);
        setCurrentDetailsStepState(ProjectDetailsStep.title);
      },
    }),
    [
      project,
      loading,
      projectStepState,
      currentDetailsStep,
      projectDetailsSteps,
      shouldShowLocationModalNextStep,
      saveState,
    ],
  );

  return (
    <ProjectContext.Provider value={value}>
      <>
        {children}
        <ProjectSavingModal
          saveState={saveState}
          setSaveState={setSaveState}
          createProjectFlow={createProjectFlow}
          {...value}
        />
      </>
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
