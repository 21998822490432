import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { GET_USER_ACTIVITIES } from '../../../graphql/activities/activities';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import UserActivityListItem from '../activities/UserActivityListItem';
import { UserActivityType } from '../../../types/user/activityTypes';

const MemberProjectHistory: React.FC = () => {
  const { loading, error, data } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      pageSize: 3,
      activityTypes: [
        UserActivityType.ALLOTMENT_CREDIT_DISBURSEMENT,
        UserActivityType.ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION,
      ],
    },
  });
  return (
    <Grid item sx={{ pb: 6 }}>
      <Grid container direction="column">
        <Typography variant="h3" sx={{ mb: 6, pt: 1 }}>
          My Project History
        </Typography>
        <DataStateHandler loading={loading} error={error}>
          <Grid item container direction="column">
            {!data?.me.activities.items.length && (
              <Typography align="center" variant="body1">
                You don&apos;t have any projects to display.
              </Typography>
            )}
            {data?.me.activities.items.map((x) => (
              <Box key={x.id}>
                <Grid item>
                  <UserActivityListItem userActivity={x} />
                  <Box pt={2} pb={2}>
                    <Divider />
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        </DataStateHandler>
      </Grid>
    </Grid>
  );
};

export default MemberProjectHistory;
