import React from 'react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  TooltipProps,
} from 'recharts';
import { Divider, Grid } from '@mui/material';
import { DateGraphCoordinate } from '../../../../../types/dashboard/types';
import { formatDateStringUtc } from '../../../../../utils/dateUtils';
import TooltipContainer from '../../../../common/ChartTooltips/TooltipContainer';
import ChartLabel from '../../../../common/ChartLabel/ChartLabel';
import { formatTooltip } from '../../../../common/ChartTooltips/FormattedTooltip';
import ChartLegend from '../../../../common/ChartLegend/ChartLegend';
import CardFooter from '../../../../common/CardFooter/CardFooter';
import NumberUtils, { NumberFormat } from '../../../../../utils/numberUtils';

type Props = {
  footprints: Array<
    DateGraphCoordinate & {
      offsetValue: number;
      emissions: number;
      behaviorCredits: number;
    }
  >;
  goal?: number;
  includeBehaviorCredits: boolean; // Display the behavior credits in the tooltips?
};

const EmptyDot = () => <svg />;

const COLORS = {
  emissions: '#E96B52',
  offsets: '#6140A5',
  behaviorCredits: '#0099ff',
};

const TotalFootprintChart: React.FC<Props> = ({
  footprints,
  goal,
  includeBehaviorCredits,
}) => {
  const data = footprints.map((fp) => ({
    month: formatDateStringUtc(fp.date, 'MMM YYYY'),
    footprint: fp.value / 1000,
    offset: fp.offsetValue / 1000,
    emissions: fp.emissions / 1000,
    behaviorCredits: fp.behaviorCredits / 1000,
    goal,
    zero: 0,
  }));

  const total = footprints.reduce((t, f) => t + f.value / 1000, 0);

  const renderLeftTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: number };
  }) => {
    if (goal !== undefined && payload.value === 0) {
      return (
        <svg
          orientation="left"
          fontSize="10"
          stroke="none"
          width="60"
          height="215"
          type="number"
          x={x - 20}
          y={y - 16}
          fill="#FFFFFF"
          className="recharts-text recharts-cartesian-axis-tick-value"
          textAnchor="end">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5896 20.4383C22.368 18.7626 22.8237 16.9218 22.9259 15.0346C23.035 13.0192 22.738 11.002 22.0568 9.13179C21.3757 7.26154 20.3277 5.58578 18.99 4.22791C17.6523 2.87003 16.059 1.86462 14.3275 1.28571C12.5959 0.7068 10.7702 0.569128 8.98469 0.882834C7.1992 1.19654 5.49943 1.95363 4.01054 3.09837C2.52165 4.24311 1.28157 5.74633 0.381592 7.49735L4.14389 9.91053C4.7208 8.78807 5.51573 7.82446 6.47015 7.09065C7.42457 6.35685 8.51418 5.87153 9.65873 5.67043C10.8033 5.46934 11.9736 5.55759 13.0836 5.92869C14.1936 6.29979 15.2149 6.94428 16.0724 7.81472C16.9299 8.68516 17.6017 9.75937 18.0383 10.9583C18.475 12.1571 18.6654 13.4502 18.5954 14.7421C18.5255 16.0341 18.1971 17.2921 17.6343 18.4234C17.2632 19.1695 16.7965 19.848 16.2506 20.4383H21.5896Z"
            fill="url(#paint0_linear_4556_12258)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8894 21.3405C14.4048 21.3405 17.2547 18.1569 17.2547 14.2298C17.2547 10.3026 14.4048 7.11903 10.8894 7.11903C7.37399 7.11903 4.52417 10.3026 4.52417 14.2298C4.52417 18.1569 7.37399 21.3405 10.8894 21.3405ZM7.56654 18.9986C7.56654 18.9986 7.55433 15.6078 8.89269 14.3262C9.69538 13.5576 10.6096 13.3467 11.5417 13.1318C12.5167 12.9069 13.5112 12.6776 14.4183 11.8006C14.8259 11.4065 15.1406 10.8321 15.2623 10.6099C15.2797 10.5781 15.2932 10.5536 15.3024 10.5378C16.2453 12.2072 16.5327 16.4972 14.4183 18.6068C12.4161 20.6045 9.42626 20.1371 7.56654 18.9986Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4556_12258"
              x1="18.6295"
              y1="5.17801"
              x2="3.24136"
              y2="6.38967"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    }
    return (
      <text
        orientation="left"
        fontSize="10"
        stroke="none"
        width="60"
        height="215"
        type="number"
        x={x}
        y={y}
        fill="#FFFFFF"
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="end">
        <tspan x="39" dy="0.455em">
          {payload.value}
        </tspan>
      </text>
    );
  };

  const renderTooltipContents = ({
    active,
    payload,
  }: TooltipProps<number, string>) => {
    if (active) {
      if (payload && payload.length > 0) {
        const payloadData = payload[0].payload;
        if (payloadData) {
          const monthName = (payloadData.month || '') as string;

          let formatPrecision: NumberFormat;
          let emissions = 0;
          if (includeBehaviorCredits) {
            // User detail mode, only
            emissions = payloadData.emissions || 0;
            formatPrecision = 'precisionNumber';
          } else {
            // Company
            emissions = payloadData.footprint || 0;
            formatPrecision = 'number';
          }
          const emissionsDisplay = formatTooltip(emissions, formatPrecision);

          const offsets = (payloadData.offset || 0) as number;
          const offsetValue = formatTooltip(offsets, formatPrecision);

          // User detail mode, only
          let behaviorCredits = 0;
          let behaviorCreditsDisplay = '';
          if (includeBehaviorCredits) {
            // Get user's behavior credits
            behaviorCredits = (payloadData.behaviorCredits || 0) as number;
            behaviorCreditsDisplay = formatTooltip(
              behaviorCredits,
              formatPrecision,
            );
          }

          let adjusted = emissions - offsets;
          if (includeBehaviorCredits) {
            // User detail mode, only - also subtract behavior credits
            adjusted -= behaviorCredits;
          }
          const adjustedValue = formatTooltip(adjusted, formatPrecision);
          return (
            <TooltipContainer>
              <>
                <ChartLabel
                  labelColor="#6B6B6B"
                  color="#FFFFFF"
                  value=""
                  label={monthName}
                />
                <Divider />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.emissions}
                  label="Emissions"
                  value={emissionsDisplay}
                />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.offsets}
                  label="Offsets"
                  value={offsetValue}
                />
                {includeBehaviorCredits && (
                  <ChartLabel
                    key="behaviorCredits"
                    labelColor="#6B6B6B"
                    color={COLORS.behaviorCredits}
                    label="Behavior Credits"
                    value={behaviorCreditsDisplay}
                  />
                )}
                <Divider />
                <ChartLabel
                  key="adjusted"
                  labelColor="#6B6B6B"
                  label="Adjusted Footprint"
                  value={adjustedValue}
                />
              </>
            </TooltipContainer>
          );
        }
      }
    }
    return <svg />;
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <div style={{ height: 215 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 10,
              }}>
              <CartesianGrid vertical={false} color="#D1FFBB" opacity={0.2} />
              <XAxis
                dataKey="month"
                orientation="bottom"
                stroke="#FFFFFF"
                fontSize={10}
                tickMargin={24}
                tickSize={0}
                interval={0}
                padding={{ right: 25 }}
              />
              <YAxis
                orientation="left"
                tickMargin={16}
                tickSize={0}
                fontSize={10}
                tick={renderLeftTick}
                stroke="#FFFFFF"
              />
              <Tooltip
                content={renderTooltipContents}
                cursor={{ stroke: '#D1FFBB' }}
              />
              <defs>
                <linearGradient id="emissions" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.emissions}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS.emissions}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="offsets" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.offsets}
                    stopOpacity={0.75}
                  />
                  <stop offset="100%" stopColor="#C9BDD9" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="footprint"
                strokeWidth={2}
                stroke={COLORS.emissions}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.emissions, fill: COLORS.emissions }}
                fill="url(#emissions)"
              />
              <Area
                type="monotone"
                dataKey="offset"
                strokeWidth={2}
                stroke={COLORS.offsets}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.offsets, fill: COLORS.offsets }}
                fill="url(#offsets)"
              />
              <Line
                dot={<EmptyDot />}
                type="monotone"
                dataKey="zero"
                activeDot={false}
                stroke="#FFFFFF"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between">
          <Grid item>
            <ChartLegend
              keys={[
                {
                  color: COLORS.emissions,
                  label: 'Footprint',
                },
                {
                  color: COLORS.offsets,
                  label: 'Offset',
                },
              ]}
              labelColor="#FFFFFF"
            />
          </Grid>
          <Grid item>
            <CardFooter
              dataStyle={{ color: '#FFFFFF' }}
              labelStyle={{
                color: '#FFFFFF',
                opacity: 0.5,
                whiteSpace: 'pre-line',
                textAlign: 'right',
                lineHeight: 1.5,
              }}
              data={NumberUtils.format(total, 'integer')}
              label={`MT C0₂E
              Footprint YTD`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalFootprintChart;
