/* eslint-disable import/prefer-default-export */
import { gql, TypedDocumentNode } from '@apollo/client';
import {
  HabitListItem,
  Habit,
  HabitCategory,
  HabitUnitOfMeasure,
} from '../../types/habit/types';
import { QuestionProfile, Tip } from '../../types/question/types';

export const ALL_HABITS = gql`
  query allHabits($includeDraft: Boolean) {
    habits(
      filter: { orderBy: NAME, noUserFilter: true, includeDraft: $includeDraft }
    ) {
      id
      name
      isDraft
      publishDate
      category {
        id
        label
        primaryColor
        secondaryColor
      }
    }
  }
` as TypedDocumentNode<{ habits: HabitListItem[] }, unknown>;

export const HABIT_EXPORT = gql`
  query habitExport {
    habits(filter: { orderBy: NAME, noUserFilter: true, includeDraft: true }) {
      id
      name
      categoryId
      publishDate
      description
      bannerType
      bannerDescription
      bannerValue
      tips {
        id
        text
      }
    }
  }
` as TypedDocumentNode<{
  habits: {
    tips: Tip[];
  }[];
}>;

const HABIT_DETAILS_FIELDS = `
  id
  name
  isDraft
  categoryId
  scoreFactorIds
  description
  questionText
  impact
  recurrenceSchedule
  imageUrl
  pointAmount
  answeredNoPointAmount
  streakAmounts {
    pointAmount
    streakAmount
  }
  condition
  impactValue
  bannerType
  bannerDescription
  bannerValue
  addHabitActions
  addLifestyleHabitActions
  conversionActions
  conversionType
  conversionReanswerQuestionTreeCategory
  conversionReanswerQuestionId
  conversionNagCount
  conversionConfirmationQuestion
  conversionSuccessMessage
  tips {
    id
    text
  }
  publishDate
`;

export const HABIT_DETAILS = gql`
  query ($id: String!) {
    habit(id: $id) {
      ${HABIT_DETAILS_FIELDS}
    }
  }
` as TypedDocumentNode<{ habit: Habit }>;

export const UPSERT_HABIT = gql`
  mutation upsertHabit($habit: HabitInput!) {
    upsertHabit(habit: $habit) {
      ${HABIT_DETAILS_FIELDS}
    }
  }
`;

export const VALIDATE_HABIT = gql`
  mutation validateHabit($habit: HabitInput!) {
    upsertHabit(habit: $habit, validateOnly: true) {
      id
    }
  }
`;

export const HABIT_CATEGORIES = gql`
  query habitCategories {
    habitCategories {
      id
      label
      primaryColor
      secondaryColor
      iconUrl
      fillIconUrl
    }
    scoreFactors {
      id
      label
    }
  }
` as TypedDocumentNode<{
  habitCategories: HabitCategory[];
  scoreFactors: { id: string; label: string }[];
}>;

/**
 * Habit units of measure.
 */
export const HABIT_UOM = gql`
  query habitUoms {
    habitUoms {
      id
      label
    }
  }
` as TypedDocumentNode<{
  habitUoms: HabitUnitOfMeasure[];
}>;

export const ALL_QUESTIONS = gql`
  query allQuestions {
    questions {
      title
      id
      questionTreeCategory
      questions {
        questionId
        questionText
        isOnboarding
        tags
        options {
          answerId
          answerText
        }
      }
    }
  }
` as TypedDocumentNode<{
  questions: QuestionProfile[];
}>;

export const EMISSION_CATEGORY_OPTIONS = gql`
  query emissionCategoryOptions {
    emissionCategoryOptions
  }
` as TypedDocumentNode<{
  emissionCategoryOptions: Record<string, Record<string, string[]>>;
}>;
