import { gql } from '@apollo/client';

export const companySubsidiariesQuery = gql`
  query companySubsidiaries($parentCompanyId: String!) {
    companySubsidiaries(parentCompanyId: $parentCompanyId) {
      name
      id
      parentCompanyId
      parentCompanyName
      logoUri
      address
      place
      region
      postalCode
      country
      phoneNumber
    }
  }
`;

export default {
  companySubsidiariesQuery,
};
