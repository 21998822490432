import { Box, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, isBefore, isValid, parse, parseISO } from 'date-fns';
import { Field } from 'formik';
import React from 'react';
import SVG from '../../../assets/svg';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';
import AppDatePicker from '../../common/AppDatePicker';
import { CommunicationInput } from '../types';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #DADADA',
    padding: '16px 0px',
    marginTop: 16,
  },
  published: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 0,
    paddingBottom: 8,
  },
  input: {
    padding: '18.5px 24px 18.5px 14px',
  },
}));

const PublishFields: React.FC<
  Disableable & {
    maxDate?: Date;
    minDate?: Date;
    alwaysAllowEditing?: boolean;
  }
> = ({ disabled, maxDate, alwaysAllowEditing, minDate }) => {
  const { setTypedFieldValue, values, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  const classes = useStyles();

  const { publishDate: initialPublishDate } = initialValues;

  const hasBeenPublished = isBefore(
    parseISO(initialPublishDate),
    parseISO(new Date().toISOString()),
  );

  let time = '';
  let partialPublishDate = '';
  if (values.publishDate && isValid(parseISO(values.publishDate))) {
    const iso = parseISO(values.publishDate);
    time = format(iso, 'HH:mm');
    partialPublishDate = format(iso, 'MM/dd/yyyy');
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasBeenPublished && !values.hasBeenUnpublished && !alwaysAllowEditing ? (
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h3">Published on</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={classes.published}
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start">
              <Grid item>
                <Typography variant="body1">
                  <Box component="span" mr="8px">
                    <SVG.Calendar />
                  </Box>
                  {partialPublishDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Box component="span" mr="8px">
                    <SVG.Clock />
                  </Box>
                  {format(new Date(values.publishDate), 'h:mm a')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        !disabled && (
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
              <Typography variant="h3">
                When would you like to publish this?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Select a Day</Typography>
              <Field
                name="publishDate"
                maxDate={maxDate}
                minDate={minDate}
                component={AppDatePicker}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Enter a Time</Typography>
              <TextField
                variant="outlined"
                value={time}
                InputProps={{ classes: { input: classes.input } }}
                onChange={(e) => {
                  setTypedFieldValue(
                    'publishDate',
                    parse(
                      `${partialPublishDate} ${e.target.value}`,
                      'MM/dd/yyyy HH:mm',
                      new Date(),
                    ).toISOString(),
                  );
                }}
                fullWidth
                label=""
                type="time"
              />
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default PublishFields;
