import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid, Paper, Typography, Snackbar, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { addHours, isBefore, parseISO } from 'date-fns';
import { Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';

import {
  EMAIL_COMMUNICATION_QUERY,
  PREVIEW_EMAIL_COMMUNICATION,
  UPSERT_EMAIL_COMMUNICATION,
} from '../../../graphql/communications/communications';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsFooter from '../CommunicationDetailsFooter';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import HeadingInput from './HeadingInput';
import PublishFields from './PublishFields';
import AppRichTextField from '../../common/AppRichTextField';
import EmailRecipientsInput from './EmailRecipientsInput';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import IncludeChildrenSwitch from './IncludeChildrenSwitch';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';

type Props = {
  id: string;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
  },
  paper: {
    padding: 24,
  },
}));

const maxDate = addHours(new Date(), 72);

const schema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string(),
  publishDate: yup
    .date()
    .max(maxDate, 'Publish date and time must be within the next 72 hours')
    .nullable(),
  companyIds: yup.array().of(yup.string()).min(1),
});

const EmailCommunicationDetails: React.FC<Props> = ({ id, onClose }) => {
  const {
    loading,
    error,
    data: { emailCommunication = {} } = {},
  } = useQuery(EMAIL_COMMUNICATION_QUERY, {
    variables: { id },
    skip: id === 'new',
  });

  const [previewComplete, setPreviewComplete] = useState(false);

  const { companyId, refetchCommunicationsQuery } = useContext(
    CommunicationsContext,
  );

  const [upsert, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_EMAIL_COMMUNICATION,
  );

  const [preview, { loading: previewLoading, error: previewError }] =
    useMutation(PREVIEW_EMAIL_COMMUNICATION);

  const onPreview = () => {
    preview({
      variables: { id: emailCommunication.id },
      onCompleted: () => setPreviewComplete(true),
    });
  };

  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const initialValues = {
    title: '',
    content: '',
    publishDate: '',
    companyIds: companyId ? [companyId] : [],
    includeChildren: false,
    ...emailCommunication,
    hasBeenUnpublished: false,
  };

  const hasBeenPublished =
    initialValues.publishDate &&
    isBefore(
      parseISO(initialValues.publishDate),
      parseISO(new Date().toISOString()),
    );

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Emails', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Emails', 'EDIT');

  const showIncludeChildren =
    companyId && userHasAccess('Api.AllowParentQueries', 'VIEW');

  return (
    <DataStateHandler
      loading={loading || upsertLoading || previewLoading}
      error={error || upsertError || previewError}>
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values) => {
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              createdDate,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              updatedDate,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              __typename,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              hasBeenUnpublished,
              ...rest
            } = values;
            const input = {
              ...rest,
              ...(companyId
                ? { companyIds: [companyId], isGlobalCommunication: false }
                : { isGlobalCommunication: true }),
            };
            await upsert({
              variables: { input },
              onCompleted: () => onClose(),
            });
            refetchCommunicationsQuery();
          }}
          validateOnMount>
          {(props) => (
            <Grid
              container
              direction="column"
              className={classes.root}
              spacing={2}>
              <Grid item>
                <CommunicationDetailsHeader
                  title={
                    id === 'new'
                      ? 'Create New'
                      : `${canEdit ? 'Edit' : 'View'} Details`
                  }
                  canEdit={canEdit}
                  onClose={onClose}
                  onSave={props.handleSubmit}
                  saveDisabled={
                    !props.isValid || props.isSubmitting || hasBeenPublished
                  }
                  additionalActions={
                    <Grid item>
                      <SmallPaddedButton
                        onClick={onPreview}
                        disabled={!emailCommunication.id || !canEdit}
                        variant="contained"
                        endIcon={<SendIcon />}
                        color="primary">
                        Preview
                      </SmallPaddedButton>
                    </Grid>
                  }
                />
              </Grid>

              <Grid item className={classes.form}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    {companyId ? null : (
                      <Grid item xs={12}>
                        <EmailRecipientsInput
                          disabled={!canEdit}
                          initialCompanyIds={
                            emailCommunication.companyIds || []
                          }
                          onChange={(v) => props.setFieldValue('companyIds', v)}
                        />
                      </Grid>
                    )}
                    {showIncludeChildren ? (
                      <IncludeChildrenSwitch
                        disabled={!canEdit}
                        style={{ marginLeft: 8 }}
                      />
                    ) : null}

                    <Grid item xs={12}>
                      {hasBeenPublished ? (
                        <Typography variant="h1">
                          {props.values.title}
                        </Typography>
                      ) : (
                        <HeadingInput
                          label="Subject"
                          disabled={!canEdit}
                          placeholder="Type your subject heading here"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {!canEdit || hasBeenPublished ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: props.values.content,
                          }}
                        />
                      ) : (
                        <AppRichTextField
                          labelText="Body"
                          initialValue={initialValues.content}
                          onChange={(v) => props.setFieldValue('content', v)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <PublishFields maxDate={maxDate} disabled={!canEdit} />
                    </Grid>
                  </Grid>
                  {!canEdit || hasBeenPublished ? null : (
                    <CommunicationDetailsFooter
                      onSave={props.handleSubmit}
                      saveDisabled={
                        !props.isValid || props.isSubmitting || hasBeenPublished
                      }
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Formik>
        <Snackbar
          open={previewComplete}
          sx={{ width: 300 }}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => setPreviewComplete(false)}>
          <Alert
            onClose={() => setPreviewComplete(false)}
            severity="success"
            sx={{ width: '100%' }}>
            Preview Sent!
          </Alert>
        </Snackbar>
      </>
    </DataStateHandler>
  );
};

export default EmailCommunicationDetails;
